import { SyntheticEvent, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "../../order-status-page-styles.module.css";
import OrderDetailsHeader from "components/OrderDetailsHeader/OrderDetailsHeader";
import OrderDetailsSummary from "components/OrderDetailsSummary/OrderDetailsSummary";
import OrderDetailsLineItem from "components/OrderDetailsLineItem/OrderDetailsLineItem";
import CTAProblem from "components/CTAProblem/CTAProblem";
import OrderDetailsSearchLineItems from "components/OrderDetailsLineItem/OrderDetailsSearchLineItems";
import { ORDER_DETAIL_CTA_PROBLEMS } from "constants/orderDetailsCTAProblems";
import OrderDetailsOnBackorderAccordion from "components/OrderDetailsLineItem/OrderDetailsOnBackorderAccordion";
import OrderDetailsShipmentAccordion from "components/OrderDetailsLineItem/OrderDetailsShipmentAccordion";
import {
	BackorderedCabinetCount,
	GetBackorderCount,
	GetNormalLineItems,
	GroupAllTrackingNumbers,
	GroupAllTruckNumbers,
	GroupTrackingAndTruckingNumbers,
	PartsQuantityBackorderedLength
} from "../utils/OrderDetailUtil";
import { useInitOrderDetail } from "hooks/useInitOrderDetail";
import OrderDetailsHeaderSkeleton from "components/Skeletons/OrderDetailsHeaderSkeleton";
import OrderDetailsSummarySkeleton from "components/Skeletons/OrderDetailsSummarySkeleton";
import OrderDetailsLineItemSkeleton from "components/Skeletons/OrderDetailsLineItemSkeleton";
import { Grid } from "@mui/material";
import NoOrderDetailsErrorBanner from "components/NoOrdersErrorBanner/NoOrderDetailsErrorBanner";
import OrderSummaryAPIFailure from "components/OrderSummaryAPIFailure/OrderSummaryAPIFailure";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import LeavePageModal from "components/LeavePageModal/LeavePageModal";
import { cartActions } from "features/reducers/replacementOrder/cart.ts";
import { submittingReplacementOrderActions } from "features/reducers/replacementOrder/submittingReplacementOrder.ts";
import DefaultMessage from "components/Common/DefaultMessage/DefaultMessage";
import { HangTightSubtitle, HangTightTitle, NoLineItemsAvailableText } from "constants/text";
import { formatDateToYYYYMMDD } from "utils/date";

const OrderDetailPage = () => {
	const params = useParams();
	const { isLoading, orderDetail, orderId, error } = useInitOrderDetail(params.orderId);
	const [searchValue, setSearchValue] = useState<string | null>(null);

	const handleOrderSearch = (_event: SyntheticEvent, values: string | null) => {
		setSearchValue(values);
	};

	const replacementCart = useSelector((state: RootState) => state.cart.replacements);
	const isReplacementCartFull = replacementCart && replacementCart.length > 0;
	const [isLeavingReplacementWarningOpen, setIsLeavingReplacementWarningOpen] = useState(isReplacementCartFull);
	const dispatch = useDispatch();
	const closeLeavingReplacementWarning = () => {
		dispatch(cartActions.resetCart());
		dispatch(submittingReplacementOrderActions.resetOrder());
		setIsLeavingReplacementWarningOpen(false);
	};

	if (!!(error as any)?.status) {
		return (
			<div
				className={styles.orderListContainer}
				data-testid="order-detail-page"
			>
				<OrderSummaryAPIFailure statusCode={(error as any)?.status} />
			</div>
		);
	}

	if (isLoading) {
		return (
			<div data-testid="order-detail-page-skeleton">
				<OrderDetailsHeaderSkeleton />
				<OrderDetailsSummarySkeleton />
				{Array(6)
					.fill(1)
					.map((index: any) => (
						<OrderDetailsLineItemSkeleton key={index} />
					))}
			</div>
		);
	}

	if (orderDetail === undefined) {
		return null;
	}

	if (orderId !== undefined) {
		sessionStorage.setItem("orderDetailsId", orderId);
	}

	if (orderId && orderId !== orderDetail?.orderId && error) {
		return (
			<Grid
				data-testid="order-detail-page-error-banner"
				container
				sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
			>
				<Grid
					item
					xs={11.5}
					sm={11.75}
				>
					<NoOrderDetailsErrorBanner />
				</Grid>
			</Grid>
		);
	}

	const hasCTADescription = ORDER_DETAIL_CTA_PROBLEMS.find(
		(problem) => problem.statusReason === orderDetail?.ctaReason
	);
	const totalCount = orderDetail?.lineItems?.length ?? 0;
	const todaysDate = new Date();
	const todayFormattedDate = formatDateToYYYYMMDD(todaysDate);
	const replacementOrderDate = orderDetail?.orderDate?.split("T")[0];
	const hasPartBackorder = PartsQuantityBackorderedLength(orderDetail);
	const hasCabinetBackorder = BackorderedCabinetCount(orderDetail);
	const multipleTrackingNumberArrays = GroupAllTrackingNumbers(orderDetail);
	const truckNumberArrays = GroupAllTruckNumbers(orderDetail);
	const trackingAndTruckingNumbers = GroupTrackingAndTruckingNumbers(orderDetail);
	const lineItems = GetNormalLineItems(orderDetail);
	const displayBackorderAccordion =
		(hasPartBackorder || hasCabinetBackorder) && orderDetail?.orderType !== "BACKORDER";
	let shipmentCount = 1;

	return (
		<div
			className={styles.orderListContainer}
			data-testid="order-detail-page"
		>
			{isReplacementCartFull && (
				<LeavePageModal
					url="/"
					open={isLeavingReplacementWarningOpen}
					continueOrdering={() => {}}
					closeModal={closeLeavingReplacementWarning}
					isOnOrderDetailsPage={true}
					orderDetailId={orderId}
				/>
			)}
			<OrderDetailsHeader orderDetail={orderDetail} />
			{orderDetail?.ctaReason && hasCTADescription && <CTAProblem orderDetail={orderDetail} />}

			<OrderDetailsSummary orderDetail={orderDetail} />

			{displayBackorderAccordion ? (
				<OrderDetailsOnBackorderAccordion
					orderDetail={orderDetail}
					searchValue={searchValue}
					hasCabinetBackorder={hasCabinetBackorder}
					hasPartBackorder={hasPartBackorder}
					lineItemsOnBackorderCount={GetBackorderCount(orderDetail)}
				/>
			) : undefined}

			{totalCount > 0 && (
				<OrderDetailsSearchLineItems
					groupAllByNoneTrackingNumber={orderDetail?.lineItems}
					handleOrderSearch={handleOrderSearch}
					searchValue={searchValue}
					lineItemAndShipmentCount={totalCount}
				/>
			)}

			{totalCount === 0 && todayFormattedDate === replacementOrderDate && (
				<DefaultMessage
					dataTestId="default-message-for-no-line-items"
					asset="/assets/HangTight.svg"
					altMessage={NoLineItemsAvailableText}
					title={HangTightTitle}
					subtitle={HangTightSubtitle}
				/>
			)}

			{trackingAndTruckingNumbers.map(
				(eachArray: any, index: number) =>
					eachArray.length >= 1 &&
					eachArray[0].length >= 1 && (
						<OrderDetailsShipmentAccordion
							key={`${orderDetail?.orderId}-tracking-truck`}
							orderDetail={orderDetail}
							searchValue={searchValue}
							equalTrackingNumberCount={eachArray[1]}
							trackingNumber={eachArray[1].map((e: any) => e.trackingNumber)}
							truckNumber={eachArray[0]}
							shipmentCount={shipmentCount++}
						/>
					)
			)}

			{multipleTrackingNumberArrays.map(
				(eachArray: any) =>
					eachArray.length > 1 && (
						<OrderDetailsShipmentAccordion
							key={`${orderDetail?.orderId}-tracking`}
							orderDetail={orderDetail}
							searchValue={searchValue}
							equalTrackingNumberCount={eachArray[1]}
							trackingNumber={[eachArray[0]]}
							truckNumber={[]}
							shipmentCount={shipmentCount++}
						/>
					)
			)}

			{truckNumberArrays.map(
				(eachArray: any) =>
					eachArray.length >= 1 &&
					eachArray[0].length >= 1 && (
						<OrderDetailsShipmentAccordion
							key={`${orderDetail?.orderId}-truck`}
							orderDetail={orderDetail}
							searchValue={searchValue}
							equalTrackingNumberCount={eachArray[1]}
							trackingNumber={[]}
							truckNumber={eachArray[0]}
							shipmentCount={shipmentCount++}
						/>
					)
			)}

			{lineItems.get(true) && (
				<OrderDetailsLineItem
					orderDetail={orderDetail}
					groupAllByNoneTrackingNumber={lineItems.get(true)}
					searchValue={searchValue}
				/>
			)}
		</div>
	);
};

export default OrderDetailPage;
