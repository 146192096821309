import styled from "@emotion/styled";
import MuiDialogTitle from "@mui/material/DialogTitle";
import { DialogActions } from "@mui/material";

export const StyledDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
	alignItems: "center",
	color: theme.project.colors.fontColors.secondary,
	display: "flex",
	fontFamily: '"Gibson Medium", sans-serif',
	justifyContent: "space-between",
	button: {
		padding: 0
	}
}));

export const ActionButtonContainer = styled(DialogActions)(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "repeat(2, 1fr)",
	padding: "0 24px",
	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "1fr",
		gap: "16px",
		"button:nth-of-type(2)": {
			margin: 0
		}
	}
}));
