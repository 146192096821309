import { useMemo, useState } from "react";
import {
	ShippingDetails,
	submittingReplacementOrderActions
} from "features/reducers/replacementOrder/submittingReplacementOrder.ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { FormSchema as ShipToFormFields } from "components/Replacements/ShipToForm/schema";
import { AddressFormFields } from "pages/Replacements/forms/AddressForm/schema";
import ShipToForm from "components/ShipToForm/ShipToForm";
import { formatPhoneNumber } from "components/Replacements/ReplacementsUtil";
import { models } from "types/api/viewModels.ts";

interface ReplacementsShipToFormProps {
	accounts: models["CustomerAccountViewModel"] | undefined;
	handleEditShippingInformation: () => void;
}
const ReplacementsShipToForm = ({ accounts, handleEditShippingInformation }: ReplacementsShipToFormProps) => {
	const dispatch = useDispatch();
	const replacementOrderDetails = useSelector((state: RootState) => state.submittingReplacementOrder.order);

	const newOrderAddresses: models["ShipToViewModel"][] = useSelector(
		(state: RootState) => state.submittingReplacementOrder.newAddresses ?? []
	);
	const shippingInfo: models["ShipToViewModel"][] = useMemo(
		() =>
			accounts?.billTos
				?.filter((billTo: models["BillToViewModel"]) => billTo.isSelected)
				?.map((billTo: models["BillToViewModel"]) => billTo.shipTos)
				?.flat()
				?.filter((shipTo) => typeof shipTo !== "undefined" && shipTo !== null)
				?.concat(newOrderAddresses) ?? [...newOrderAddresses],
		[accounts?.billTos, newOrderAddresses]
	);

	const isCustomerPickup = replacementOrderDetails?.isCustomerPickup ?? false;

	const defaultShipToAddress = useMemo(
		() => replacementOrderDetails?.billingDetails?.shipTos?.filter((address) => address?.isSelected)?.[0],
		[replacementOrderDetails?.billingDetails?.shipTos]
	);

	const [shippingInformation, setShippingInformation] = useState<models["ShipToViewModel"] | undefined>(
		replacementOrderDetails?.shippingDetails ?? defaultShipToAddress
	);

	const addressFields: models["ShipToViewModel"] | ShippingDetails | undefined =
		shippingInformation ?? replacementOrderDetails?.shippingDetails;

	const phoneNumber = formatPhoneNumber(
		addressFields?.address && "phoneNumber" in addressFields.address ? addressFields?.address?.phoneNumber : ""
	);

	const handleOnCancel = () => {
		handleEditShippingInformation();
	};

	const handleAddNewAddressModalSubmit = (address: AddressFormFields) => {
		handleOnSave(address, isCustomerPickup);
	};

	const handleSetShippingInformation = (address: models["ShipToViewModel"] | undefined) => {
		setShippingInformation(address);
	};

	const handleOnSave = (fields: ShipToFormFields | AddressFormFields, formIsCustomerPickup?: boolean) => {
		const addressFormFields: AddressFormFields | undefined = "address2" in fields ? fields : undefined;
		const phoneNumber = "contactPhoneNumber" in fields ? fields.contactPhoneNumber : fields.phoneNumber;
		const deliveryInstructions = "deliveryInstructions" in fields ? fields.deliveryInstructions : "";

		handleEditShippingInformation();
		const editShippingDetails = {
			shippingDetails: {
				shipToId: addressFormFields || formIsCustomerPickup ? undefined : shippingInformation?.shipToId,
				address: {
					name:
						addressFormFields?.fullName ??
						shippingInformation?.address?.name ??
						defaultShipToAddress?.address.name,
					email: fields?.email ?? defaultShipToAddress?.address.email,
					phoneNumber: phoneNumber ?? defaultShipToAddress?.address.phoneNumber,
					line1:
						addressFormFields?.address ??
						shippingInformation?.address?.line1 ??
						defaultShipToAddress?.address.line1,
					line2:
						addressFormFields?.address2 ??
						shippingInformation?.address?.line2 ??
						defaultShipToAddress?.address.line2,
					line3: addressFormFields
						? ""
						: (shippingInformation?.address?.line3 ?? defaultShipToAddress?.address.line3 ?? ""),
					city:
						addressFormFields?.city ??
						shippingInformation?.address?.city ??
						defaultShipToAddress?.address.city,
					state:
						addressFormFields?.state ??
						shippingInformation?.address?.state ??
						defaultShipToAddress?.address.state,
					zip:
						addressFormFields?.zip ??
						shippingInformation?.address?.zip ??
						defaultShipToAddress?.address.zip,
					county: addressFormFields?.county ?? shippingInformation?.address?.county
				}
			},
			labelComments: formIsCustomerPickup ? "" : deliveryInstructions,
			isCustomerPickup: formIsCustomerPickup
		};
		dispatch(submittingReplacementOrderActions.updateShippingOrderDetails(editShippingDetails));
		if (addressFormFields) {
			dispatch(submittingReplacementOrderActions.addNewAddress(editShippingDetails.shippingDetails));
		}
	};

	return (
		<div>
			<ShipToForm
				allowLabelComments={accounts?.allowLabelComments ?? false}
				analyticsDataId="replacements-review-new-address-link"
				combinedAddresses={shippingInfo}
				defaultFormValues={{
					email:
						shippingInformation?.address?.email ??
						replacementOrderDetails?.shippingDetails?.address.email ??
						"",
					phoneNumber,
					deliveryInstructions: replacementOrderDetails?.labelComments ?? "",
					address: addressFields
				}}
				customerPickupAllowed={accounts?.customerPickupAllowed ?? false}
				handleAddNewAddressModalSubmit={handleAddNewAddressModalSubmit}
				handleOnCancel={handleOnCancel}
				handleOnSave={handleOnSave}
				handleSetShippingInformation={handleSetShippingInformation}
				isCustomerPickupDefault={isCustomerPickup}
				showCancelSaveButtons
			/>
		</div>
	);
};

export default ReplacementsShipToForm;
