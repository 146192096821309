import styled from "@emotion/styled";
import { Accordion, accordionClasses, Card, Typography } from "@mui/material";
import LineItemGrid from "components/Common/LineItemGrid/LineItemGrid";
export const EstimatedSubtotalContainer = styled.div({
	display: "flex",
	flexDirection: "column",
	gap: 8
});

export const EstimatedSubtotalInnerContainer = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	gap: 20,
	[theme.breakpoints.down("md")]: {
		gap: "unset",
		justifyContent: "space-between"
	}
}));

export const EstimatedSubtotalCard = styled(Card)(({ theme }) => ({
	alignItems: "flex-end",
	backgroundColor: theme.project.colors.allColors.hex.blue.light.lightBlue50,
	borderRadius: "unset",
	color: theme.project.colors.primaryColors.main,
	display: "flex",
	flexDirection: "column",
	fontSize: 20,
	fontWeight: 400,
	padding: "16px 20px",
	[theme.breakpoints.down("md")]: {
		alignItems: "unset"
	}
}));

export const EstimatedSubtotalTextBold = styled.div({
	fontWeight: 500
});

export const EstimatedSubtotalTextSmall = styled.div({
	fontSize: 14
});

export const AccountBillToCardContainer = styled.div(({ theme }) => ({
	display: "grid",
	columnGap: "16px",
	padding: "16px",
	gridTemplateColumns: "1fr 1fr",
	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "1fr",
		rowGap: "16px"
	}
}));

export const AccountBillToCard = styled.div({
	border: "solid",
	borderWidth: "1px",
	borderColor: "#0000001f",
	padding: "16px",
	p: {
		margin: 0
	}
});

export const AccountBillToCardHeader = styled.h1({
	fontSize: "16px",
	fontWeight: "400",
	textTransform: "uppercase"
});

export const AccountBillToCardDetailsContainer = styled(Typography)({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center"
});

export const AccountBillToCardDetails = styled(Typography)({
	flexDirection: "column"
});

export const PriceEstimatesAccordion = styled(Accordion)({
	paddingBottom: "1rem",
	[`&.${accordionClasses.expanded}`]: {
		margin: 0
	}
});

export const TotalPriceContainer = styled.div(({ theme }) => ({
	display: "flex",
	justifyContent: "flex-end",
	gap: 16,
	marginTop: 8,
	paddingRight: 16,
	alignItems: "center",
	p: {
		"&:first-child": {
			...theme.project.typography.subtitle.subtitle2,
			margin: 0,
			fontSize: 16,
			color: theme.project.colors.fontColors.main
		},
		"&:nth-child(2)": {
			...theme.project.typography.body.body1,
			color: theme.project.colors.fontColors.main,
			margin: 0
		}
	}
}));

export const EstimatesLineItemGrid = styled(LineItemGrid)({
	"& > div:first-of-type > div:first-of-type": {
		gridColumn: "span 3"
	}
});

export const PriceEstimateGroupedContent = styled.div({
	padding: "0 16px 16px 16px"
});

export const PriceEstimateConfigurations = styled.div({
	marginBottom: 16,
	"& > div": {
		margin: "8px 0"
	}
});

export const LineItemCount = styled.div(({ theme }) => ({
	...theme.project.typography.overline,
	color: theme.project.colors.fontColors.main,
	display: "flex",
	justifyContent: "flex-end",
	[theme.breakpoints.down("md")]: {
		justifyContent: "flex-start",
		marginBottom: 8
	}
}));

export const PriceEstimateCard = styled(Card)({
	marginBottom: 24
});

export const PriceEstimateCardContent = styled.div({
	paddingBottom: 16
});
