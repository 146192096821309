import styled from "@emotion/styled";
import PageHeaderLoadingSkeleton from "pages/OrderPages/components/LoadingSkeleton/PageHeaderLoadingSkeleton.tsx";

export const BuildOrderHeaderSkeleton = styled(PageHeaderLoadingSkeleton)(({ theme }) => ({
	backgroundColor: theme.project.colors.backgroundColors.main,
	display: "flex",
	flexDirection: "column",
	gap: 16,
	padding: "20px 20px 16px 20px",
	h1: {
		flex: 1,
		margin: 0
	},
	span: {
		flex: 1
	},
	"& > div:nth-of-type(1)": {
		width: 100
	},
	"& > div:nth-of-type(2)": {
		alignItems: "center",
		display: "flex",
		marginBottom: 8,
		span: {
			height: "42px !important"
		}
	},
	"& > div:nth-of-type(3) > span": {
		height: "100px !important"
	},
	[theme.breakpoints.down("md")]: {
		padding: "20px 40px 16px 40px",
		span: {
			width: "100%"
		},
		"& > div:nth-of-type(2)": {
			alignItems: "flex-start",
			gap: 16,
			flexDirection: "column",
			marginBottom: 0,
			width: "100%",
			span: {
				height: "24px !important",
				flex: "unset"
			}
		},
		"& > div:nth-of-type(3) > span": {
			height: "280px !important"
		}
	}
}));
