import {
	NavigationItemButton,
	MainNavigationIcon,
	NavigationContainer,
	CloseSubMenuButton,
	MainNavigationLinks,
	SubNavigationLinks,
	NavigationPlaceNewOrderButton,
	SubNavigationButton
} from "./appNavigation.styles";
import { UserNavigationItemViewModel } from "data/api/v1/model/user-navigation-item-view-model";
import LeavePageModal from "../LeavePageModal/LeavePageModal";
import { Dispatch, SetStateAction } from "react";
import { MVPNewOrderText, PlaceANewOrderText } from "constants/text";
import { Button } from "@mui/material";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { flags } from "constants/featureFlags.ts";

interface Props {
	currentPath: string;
	handleCloseSubMenu: () => void;
	handleClickNavigationOption: (navItem: UserNavigationItemViewModel, index: number) => void;
	handleMVPButtonClick: () => void;
	leavePageModalOpen: boolean;
	mainNavigationItems: Array<UserNavigationItemViewModel>;
	selectedIndex: number;
	setLeavePageModalOpen: Dispatch<SetStateAction<boolean>>;
	subMenuOpen: boolean;
	subNavigation: UserNavigationItemViewModel | null;
}

const AppNavigation = ({
	currentPath,
	handleClickNavigationOption,
	handleCloseSubMenu,
	handleMVPButtonClick,
	leavePageModalOpen,
	mainNavigationItems,
	selectedIndex,
	setLeavePageModalOpen,
	subMenuOpen,
	subNavigation
}: Props) => {
	const allowNewOrders = `${flags.env}` !== "prod";

	return (
		<NavigationContainer subMenuOpen={subMenuOpen}>
			<LeavePageModal
				open={leavePageModalOpen}
				url={currentPath}
				continueOrdering={() => setLeavePageModalOpen(false)}
				closeModal={() => setLeavePageModalOpen(false)}
			/>
			<MainNavigationLinks data-testid="main-navigation-link-container">
				{mainNavigationItems.map((mainNavigationItem, index) => (
					<NavigationItemButton
						data-id={mainNavigationItem.id}
						data-testid={mainNavigationItem.id}
						key={mainNavigationItem.title}
						selected={index === selectedIndex}
						onClick={() => handleClickNavigationOption(mainNavigationItem, index)}
					>
						<MainNavigationIcon
							data-testid={`${mainNavigationItem.id}-logo`}
							src={"/assets/navigation_logos/" + mainNavigationItem.icon}
						/>
						<div>
							{mainNavigationItem.title}
							{index === selectedIndex && (
								<img
									alt={mainNavigationItem.title}
									src="/assets/navigation_logos/SelectedNavItemBlueDot.svg"
								/>
							)}
						</div>
					</NavigationItemButton>
				))}
			</MainNavigationLinks>
			<SubNavigationLinks data-testid="sub-navigation-link-container">
				<CloseSubMenuButton
					data-testid="navigation-close-sub-menu-button"
					onClick={handleCloseSubMenu}
				>
					<ChevronLeft />
					{subNavigation?.title}
				</CloseSubMenuButton>
				{subNavigation?.navigationItems.map((subNavigationItem, index) =>
					subNavigationItem?.title === PlaceANewOrderText ? (
						<NavigationPlaceNewOrderButton
							key={subNavigationItem.title}
							variant="contained"
							data-id="navigation-place-new-order-button"
							data-testid="navigation-place-new-order-button"
							fullWidth
							onClick={() => handleClickNavigationOption(subNavigationItem, index)}
						>
							{subNavigationItem.title}
						</NavigationPlaceNewOrderButton>
					) : (
						<SubNavigationButton
							data-id={subNavigationItem.id}
							data-testid={`navigation-subMenu-${subNavigationItem.id}`}
							key={subNavigationItem.title}
							onClick={() => handleClickNavigationOption(subNavigationItem, index)}
						>
							{subNavigationItem.title}
						</SubNavigationButton>
					)
				)}
				{allowNewOrders && subNavigation?.title === "Orders" && (
					<Button
						variant="contained"
						fullWidth
						onClick={handleMVPButtonClick}
					>
						{MVPNewOrderText}
					</Button>
				)}
			</SubNavigationLinks>
		</NavigationContainer>
	);
};

export default AppNavigation;
