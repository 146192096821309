import styled from "@emotion/styled";

export const PlaceOrderOriginalOrderDetailsWrapper = styled.div<{ isAddOnLandingPage?: boolean }>(
	({ theme, isAddOnLandingPage }) => ({
		width: "calc(100% - 32px)",
		backgroundColor: isAddOnLandingPage ? theme.palette.grey[100] : theme.project.colors.backgroundColors.main,
		display: "flex",
		flexDirection: "column",
		padding: 16
	})
);

export const PlaceOrderOriginalOrderDetailsDetails = styled.div(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "repeat(8, 1fr)",
	columnGap: 16,
	margin: "8px 48px 0 0",
	div: {
		display: "flex",
		flexDirection: "column",
		margin: "4px 0",
		"span:first-of-type": {
			...theme.project.typography.subtitle.subtitle2,
			color: theme.project.colors.fontColors.main
		},
		"span:last-of-type": {
			...theme.project.typography.body.body2,
			color: theme.project.colors.fontColors.secondary
		}
	},
	[theme.breakpoints.down("lg")]: {
		gridTemplateColumns: "repeat(6, 1fr)",
		marginRight: "28px"
	},
	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "repeat(1, 1fr)",
		rowGap: "8px",
		marginRight: 0,
		div: {
			flexDirection: "row",
			alignItems: "center",
			gap: "8px",
			margin: 0
		}
	}
}));
