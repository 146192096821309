import { OrderStatus, OrderSummaryViewModel } from "data/api/v1";
import { OrderSummaryResponse } from "data/api/v1/requests/getOrderSummaryRequest";
import { convertToTitleCase } from "utils/string";
import { DateRange, FilterOpts } from "../OrderListPage/OrderListPage";

const getOrderValue = (item: any, key: string) => {
	switch (key) {
		case "account":
			return item["accountNumber"];
		case "designerNumber":
			return item?.designer?.designerNumber ?? "Designer not identified";
		default:
			return item[key]?.replace(/_/g, " ");
	}
};

const getCleanFilterValues = (key: string, selectedFilterValues: string[]): string[] => {
	return selectedFilterValues.map((fv: string) => {
		if (key === "account") {
			fv = fv.split(" - ")[0]?.trim() ?? "";
		} else if (key === "designerNumber") {
			if (fv.includes("-")) {
				fv = fv.split("-")[1] ?? "";
			}
			fv = fv.trim();
		}

		return fv.toUpperCase();
	});
};

export const getFilteredOrders = (
	filterOptions: FilterOpts,
	orders: OrderSummaryViewModel[]
): OrderSummaryViewModel[] => {
	const { brands, accounts, designers, status, types } = filterOptions;

	const compare = (key: string, selectedFilterValues: string[], item: OrderSummaryViewModel) => {
		const cleanFilterValues = getCleanFilterValues(key, selectedFilterValues);
		return cleanFilterValues.includes(getOrderValue(item, key).toUpperCase());
	};

	return (
		Object.entries({
			parentBrand: brands,
			account: accounts,
			designerNumber: designers,
			status: status,
			orderType: types
		})
			?.filter((x) => x[1].length > 0)
			?.reduce((a: any[], c: any) => a.filter((x) => compare(c[0], c[1], x)), orders) || []
	);
};

export const getPreSelectedOpenStatuses = (orders: OrderSummaryViewModel[], filterOptions: FilterOpts): string[] => {
	if (filterOptions.status.length > 0 || filterOptions.hasStatusTriggered || orders.length === 0) return [];

	const closedStatuses = [OrderStatus.DELIVERED, OrderStatus.CANCELLED, OrderStatus.UNKNOWN];

	const hasClosed = orders.some((o) => o.status !== undefined && closedStatuses.includes(o.status));
	if (!hasClosed) return [];

	return orders.reduce((acc: string[], o: OrderSummaryViewModel) => {
		const status = o.status;
		// Check if status is defined, not in closedStatuses, and not already included
		if (
			status !== undefined &&
			!closedStatuses.includes(status) &&
			!acc.some((s) => s.toUpperCase() === convertToTitleCase(status).toUpperCase())
		) {
			acc.push(convertToTitleCase(status).toUpperCase());
		}
		return acc;
	}, []);
};

type DatetimeObject = {
	fromDate: undefined | Date;
	toDate: undefined | Date;
};

export const objectToDateRange = (t: DatetimeObject): DateRange =>
	t?.fromDate === undefined || t?.toDate === undefined
		? t
		: {
				fromDate: new Date(t?.fromDate),
				toDate: new Date(t?.toDate)
			};

export type StandardOrderResponse = {
	isPartialDataset: boolean;
	orders: Array<OrderSummaryViewModel>;
};

export const coerceOrderResponse = (orderResponse: OrderSummaryResponse | undefined): StandardOrderResponse => {
	if (orderResponse?.data === undefined) {
		return { orders: [], isPartialDataset: false };
	}
	const { isPartialDataset, items } = orderResponse?.data ?? {};
	return { isPartialDataset, orders: items };
};
