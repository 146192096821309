import { ReviewOrderAccountBillToCardContainer } from "../Review/reviewOrder.styles";
import {
	AccountBillToCard,
	AccountBillToCardDetails,
	AccountBillToCardDetailsContainer,
	AccountBillToCardHeader
} from "../ViewPriceEstimates/viewPriceEstimates.styles";
import { NewOrderAccountNumber } from "../constants";
import StyledSummaryCardLogo from "styles/StyledComponents/StyledSummaryCardLogo";
import { brandCheck } from "utils/order";
import BillToBox from "components/Common/BillToShipToBox/BillToBox/BillToBox";
import { OrderType } from "data/api/v1";
import { useLocation } from "react-router-dom";
import { models } from "types/api/viewModels.ts";
import { getConfigurationAccountInfo } from "components/NewOrders/utils/NewOrderUtils.tsx";
import { RootState } from "stores/application.store.tsx";
import { useSelector } from "react-redux";
import { BrandLogo } from "components/NewOrders/ReviewOrderAccountBillTo/reviewOrderAccountBillTo.styles.ts";

interface Props {
	newOrderConfig?: models["PendingOrderConfigurationViewModel"];
	addOnConfig?: models["PendingOrderConfigurationViewModel"];
}

const ReviewOrderAccountBillTo = ({ newOrderConfig, addOnConfig }: Props) => {
	const productLineAccountInfo = useSelector((state: RootState) => state.newOrder.productLineAccounts);
	const config = newOrderConfig ?? addOnConfig;
	const configurationBillToInfo = getConfigurationAccountInfo(config, productLineAccountInfo)?.billTos?.find(
		(billTo) => billTo.billToId === config?.billToId
	);
	const location = useLocation();
	const showProductLine =
		location.pathname == "/new-order/review-order" ||
		location.pathname == "/new-order/confirmation" ||
		location.pathname.includes("add-ons/");
	return (
		<ReviewOrderAccountBillToCardContainer isAddOn={!!addOnConfig}>
			<AccountBillToCard>
				<AccountBillToCardHeader>{NewOrderAccountNumber}</AccountBillToCardHeader>
				<AccountBillToCardDetailsContainer>
					<AccountBillToCardDetails>
						<div data-testid="new-order-review-order-list-account-id">{config?.accountNumber}</div>
						<div data-testid="new-order-review-order-list-account-name">{config?.accountName}</div>
					</AccountBillToCardDetails>
					<BrandLogo>
						<StyledSummaryCardLogo
							data-testid="new-order-review-order-list-brand-logo"
							parentBrand={config?.globals?.brand?.code}
							src={
								brandCheck(config?.globals?.brand?.code)
									? `/assets/manufacture_logos/${config?.globals?.brand?.code}.png`
									: "/assets/tandem_logos/cwg_logo.png"
							}
							alt={
								config?.globals?.brand?.code && brandCheck(config?.globals?.brand?.code)
									? (config?.globals?.brand?.description ?? "")
									: "CabinetworksGroup Logo"
							}
						/>
						{showProductLine && <div>{config?.globals?.productLine?.description}</div>}
					</BrandLogo>
				</AccountBillToCardDetailsContainer>
			</AccountBillToCard>
			<BillToBox
				billTo={configurationBillToInfo}
				orderType={OrderType.UNKNOWN}
				hasBorder
				containsSubtotal={false}
				dataTestIdPrefix="new-order-review-order-list"
			/>
		</ReviewOrderAccountBillToCardContainer>
	);
};

export default ReviewOrderAccountBillTo;
