import { LinkButton } from "components/Common/Link";
import PlaceOrderOriginalOrderDetails from "components/Common/PlaceOrderOriginalOrderDetails/PlaceOrderOriginalOrderDetails";
import AddGlobalAttributes from "components/NewOrders/AddGlobalAttributes/AddGlobalAttributes";
import GlobalFormProvider from "components/NewOrders/AddGlobalAttributes/forms/GlobalFormProvider";
import ImportCSV from "components/NewOrders/ImportCSV/ImportCSV";
import {
	AddOnEnterOrder,
	AddOnsHeaderContainer,
	AddOnsLandingPageEnterOrderContainer,
	DraftNewOrderHeader,
	ManualOrderEntryAddOn,
	NewOrdersLayoutContainer,
	OrderOrAddOnText,
	UnsetSecondaryCopy
} from "components/NewOrders/NewOrders.styles";
import NewOrdersDialog from "components/NewOrders/NewOrdersDialog/NewOrdersDialog";
import {
	BuildYourOrderText,
	CreateYourItemSubtitleText,
	ManualOrderEntryText,
	OrderAddOns,
	ReturnToOriginalOrderText
} from "constants/text";
import useInitOrderDetail from "hooks/useInitOrderDetail";
import { useNavigate, useParams } from "react-router-dom";

const AddOnLandingPage = () => {
	const params = useParams();
	const navigate = useNavigate();
	const { orderDetail, orderId } = useInitOrderDetail(params.orderId);
	const handleReturnToOriginalOrder = () => {
		navigate(`/details/${orderId}`);
	};
	return (
		<NewOrdersLayoutContainer>
			<AddOnsHeaderContainer>
				<div data-testid="add-ons-header-return-to-original-order">
					<LinkButton
						hasBackIcon
						onClick={handleReturnToOriginalOrder}
						data-testid="add-ons-return-to-original-order-link"
					>
						{ReturnToOriginalOrderText}
					</LinkButton>
				</div>
				<h1>{OrderAddOns}</h1>
				<PlaceOrderOriginalOrderDetails
					orderDetail={orderDetail}
					isAddOnLandingPage
				/>
			</AddOnsHeaderContainer>
			<AddOnsLandingPageEnterOrderContainer>
				<DraftNewOrderHeader>{BuildYourOrderText}</DraftNewOrderHeader>
				<AddOnEnterOrder>
					<ImportCSV isAddOn />
					<ManualOrderEntryAddOn>
						<OrderOrAddOnText>{ManualOrderEntryText}</OrderOrAddOnText>
						<UnsetSecondaryCopy>{CreateYourItemSubtitleText}</UnsetSecondaryCopy>
						<GlobalFormProvider>
							<AddGlobalAttributes
								showAddItem
								isAddOn
							/>
						</GlobalFormProvider>
					</ManualOrderEntryAddOn>
				</AddOnEnterOrder>
			</AddOnsLandingPageEnterOrderContainer>
			<NewOrdersDialog isAddOn />
		</NewOrdersLayoutContainer>
	);
};

export default AddOnLandingPage;
