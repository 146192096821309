import { FC } from "react";
import {
	CommonSkeletonStyles,
	HeaderTitle
} from "pages/OrderPages/components/LoadingSkeleton/loadingSkeleton.styles.ts";
import { Skeleton } from "@mui/material";

interface PageHeaderLoadingSkeletonProps {
	className?: string;
	title: string;
}

const PageHeaderLoadingSkeleton: FC<PageHeaderLoadingSkeletonProps> = ({ className, title }) => {
	return (
		<CommonSkeletonStyles className={className}>
			<div>
				<Skeleton height={24} />
			</div>
			<div>
				<HeaderTitle>{title}</HeaderTitle>
				<Skeleton height={24} />
			</div>
			<div>
				<Skeleton height={24} />
			</div>
		</CommonSkeletonStyles>
	);
};

export default PageHeaderLoadingSkeleton;
