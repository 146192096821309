import { number, object, ObjectSchema } from "yup";

export interface FormSchema {
	productSelectQuantity: number | undefined;
	globalAttributeGroup: number | undefined;
}
export const editineItemSchemaObject = {
	productSelectQuantity: number(),
	globalAttributeGroup: number()
} as const;

export const schema: ObjectSchema<FormSchema> = object(editineItemSchemaObject);
