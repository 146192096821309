import { boolean, mixed, object, ObjectSchema, string } from "yup";
import {
	CharacterLimitExceeded,
	EmailFormError,
	PhoneNumberFormError,
	RequestDeliveryDateFormError
} from "constants/text";
import { ShipToViewModel } from "data/api/v1/model/ship-to-view-model";
import { isDateWithinBusinessDayRange } from "utils/date";
import { parseISO } from "date-fns";
import { models } from "types/api/viewModels.ts";

export const DELIVERY_INSTRUCTIONS_MAX_LENGTH = 800;
export const START_BUSINESS_DAY_RANGE = 5;
export const END_BUSINESS_DAY_RANGE = 90;

export interface FormSchema {
	phoneNumber?: string | null;
	email?: string | null;
	deliveryInstructions?: string | null;
	address?: models["ShipToViewModel"];
	isCustomerPickup?: boolean;
	requestedDeliveryDate?: string;
}

export const shipToFormSchemaObject = {
	phoneNumber: string()
		.when(["isCustomerPickup", "address", "address.shipToId"], {
			is: (isCustomerPickup: boolean, address: ShipToViewModel) =>
				isCustomerPickup || (address && !address.shipToId),
			then: (schema) => schema.required(PhoneNumberFormError),
			otherwise: (schema) => schema.nullable()
		})
		.test("isValidPhone", PhoneNumberFormError, (value) => (value ? value.length >= 16 : true)),
	email: string()
		.matches(/^.+@.+\..+$/, { message: EmailFormError, excludeEmptyString: true })
		.nullable(),
	deliveryInstructions: string().max(DELIVERY_INSTRUCTIONS_MAX_LENGTH, CharacterLimitExceeded).nullable(),
	address: mixed<models["ShipToViewModel"]>().required(),
	isCustomerPickup: boolean(),
	requestedDeliveryDate: string().test("isDateWithinBusinessDayRange", RequestDeliveryDateFormError, (value) =>
		value ? isDateWithinBusinessDayRange(parseISO(value), START_BUSINESS_DAY_RANGE, END_BUSINESS_DAY_RANGE) : true
	)
} as const;

export const schema: ObjectSchema<FormSchema> = object(shipToFormSchemaObject);
