import { FC, Fragment, useRef, useState } from "react";
import { Button, Card, Divider } from "@mui/material";
import { BuildNewOrderLineItemsHeader, BuildNewOrderLineItemsHeaderWrapper } from "../NewOrders.styles.ts";
import { LineItemsInOrderText } from "constants/text.ts";
import EstimateHeaderLoadingSkeleton from "../EstimatePageSkeletons/EstimateHeaderLoadingSkeleton.tsx";
import AccountBillToContent from "../AccountBillToContent.tsx";
import { models } from "types/api/viewModels.ts";
import ReviewOrderAccountBillTo from "../ReviewOrderAccountBillTo/ReviewOrderAccountBillTo.tsx";
import { LineItemAccordionDetails } from "../../Common/LineItemGrid/LineItemGridStyles.ts";
import {
	AddItemButtonWrapper,
	BuildLineItemGrid,
	ConfigAttributeTools,
	LineItemCount
} from "./buildNewOrderLineItems.styles.ts";
import Header from "../../Common/LineItemGrid/Header/Header.tsx";
import {
	NewOrderBuildGridDoorStyleColumnHeader,
	NewOrderBuildGridFinishColumnHeader,
	NewOrderBuildGridQuantityColumnHeader,
	NewOrderEdit,
	NewOrderRemoveButton,
	NewOrderRemoveGroup
} from "../constants.ts";
import { LinkButton } from "../../Common/Link";
import Modification from "../../Common/LineItemGrid/Modification/Modification.tsx";
import { AddItemButton } from "../AddLineItem/AddLineItemStyles.ts";
import AddIcon from "@mui/icons-material/Add";
import { AddLineAddLineButtonText } from "../AddLineItem/constants.ts";
import AddNewLineItem from "../AddLineItem/AddLineItem.tsx";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store.tsx";
import { ConfigurationGroup } from "../utils/NewOrderUtils.ts";
import GlobalFormProvider from "../AddGlobalAttributes/forms/GlobalFormProvider.tsx";
import AddGlobalAttributes from "../AddGlobalAttributes/AddGlobalAttributes.tsx";
import DeleteIcon from "@mui/icons-material/Delete";
import { getAttributes, getConfigDisplay, getSubheaderAttributes } from "../utils/NewOrderUtils.tsx";
import EditLineItem from "../EditLineItem/EditLineItem.tsx";

interface FileContentProps {
	group: ConfigurationGroup;
	isLoading: boolean;
	isAddOn?: boolean;
	handleUpdateAccount: (
		account: models["CustomerAccountViewModel"] | null,
		configurationId: string | null | undefined
	) => void;
	handleUpdateBillTo: (
		billTo: models["BillToViewModel"] | null | undefined,
		configurationId: string | null | undefined
	) => void;
	handleMoveLineItem: (
		item: models["PendingLineItemViewModel"],
		deleteItemIndex: number,
		deleteConfigIndex: number,
		destinationConfigIndex: number
	) => void;
	groupIndex: number;
	handleRemoveGroup: (configuration: models["PendingOrderConfigurationViewModel"], configIndex: number) => void;
	handleShowEstimateButton: () => void;
	handleConfigurationUpdate: (
		configuration: models["PendingOrderConfigurationViewModel"],
		configIndex: number
	) => void;
	searchValue: string | null;
	lineItemErrors: (string | undefined | null)[][];
	handleOpenRemoveModal: (item: models["PendingLineItemViewModel"], itemIndex: number, configIndex: number) => void;
	handleLineItem: (configIndex: string, newLineItem: models["PendingLineItemViewModel"]) => void;
	updatedItems: models["PendingOrderConfigurationViewModel"][];
	groupedDisplayConfigs: ConfigurationGroup[];
}

const FileContent: FC<FileContentProps> = ({
	group,
	isLoading,
	isAddOn,
	handleUpdateAccount,
	handleUpdateBillTo,
	handleMoveLineItem,
	groupIndex,
	handleRemoveGroup,
	handleShowEstimateButton,
	handleConfigurationUpdate,
	searchValue,
	lineItemErrors,
	handleOpenRemoveModal,
	handleLineItem,
	updatedItems,
	groupedDisplayConfigs
}) => {
	const dispatch = useDispatch();
	const parsedCSV = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const selectedEditItem = useRef<models["PendingLineItemViewModel"] | undefined>();
	const [showEditLineItem, setShowEditLineItem] = useState<number | undefined>(undefined);
	const [editLineItemIndex, setEditLineItemIndex] = useState<number>();
	const [showAddItemButton, setShowAddItemButton] = useState<[number, number] | undefined>(undefined);
	const setConfigurationToUpdate = (configuration: models["PendingOrderConfigurationViewModel"]) => {
		dispatch(newOrderActions.updateGlobalAttributes(configuration));
	};

	const toggleAddItem = (groupIndex?: number, configIndex?: number) => {
		const isUnset = typeof groupIndex === "undefined" || typeof configIndex === "undefined";
		setShowAddItemButton(isUnset ? undefined : [groupIndex, configIndex]);
		handleShowEstimateButton();
	};

	const toggleEditItem = (index?: number) => {
		setShowEditLineItem(index);
		handleShowEstimateButton();
		selectedEditItem.current = undefined;
		toggleAddItem();
	};

	const handleEditLineItem = (item: models["PendingLineItemViewModel"], itemIndex: number, index: number) => {
		setShowEditLineItem(index);
		selectedEditItem.current = item;
		setEditLineItemIndex(itemIndex);
		handleShowEstimateButton();
	};

	return (
		<Card>
			<EstimateHeaderLoadingSkeleton isLoading={isLoading} />
			<BuildNewOrderLineItemsHeaderWrapper isLoading={isLoading}>
				{!isLoading && !isAddOn && (
					<AccountBillToContent
						handleUpdateAccount={handleUpdateAccount}
						handleUpdateBillTo={handleUpdateBillTo}
						productLine={group.globals?.productLine?.code ?? ""}
						configGroup={group}
						csvContents={[
							{
								configurations: group.configurations
							}
						]}
						isBuildOrderPage
						handleRemoveGroup={() => handleRemoveGroup(group, groupIndex)}
						handleConfigurationUpdate={(configuration: models["PendingOrderConfigurationViewModel"]) =>
							handleConfigurationUpdate(configuration, groupIndex)
						}
						setConfigurationToUpdate={() => setConfigurationToUpdate(group)}
					>
						<LineItemCount data-testid="new-order-build-table-count">
							{group.configurations.flatMap((config) => config.lineItems).length} {LineItemsInOrderText}
						</LineItemCount>
					</AccountBillToContent>
				)}
			</BuildNewOrderLineItemsHeaderWrapper>
			{group.configurations.map((configuration, configIndex) => (
				<Fragment key={configuration.configurationId}>
					{isAddOn && !isLoading && <ReviewOrderAccountBillTo addOnConfig={configuration} />}
					<LineItemAccordionDetails>
						<BuildNewOrderLineItemsHeader>
							<div data-testid="new-order-build-label">
								<div>{getAttributes(configuration)}</div>
								<div>{getSubheaderAttributes(configuration)}</div>
							</div>
							<ConfigAttributeTools>
								<GlobalFormProvider isUpdate>
									<AddGlobalAttributes
										setConfigurationToUpdate={() => setConfigurationToUpdate(configuration)}
										handleConfigurationUpdate={(config) =>
											handleConfigurationUpdate(config, configIndex)
										}
										isUpdate
									/>
								</GlobalFormProvider>
								<Button
									onClick={() => handleRemoveGroup(configuration, configIndex)}
									variant="text"
									data-testid="remove-group"
									sx={{ gap: "8px" }}
								>
									<DeleteIcon />
									{NewOrderRemoveGroup}
								</Button>
							</ConfigAttributeTools>
						</BuildNewOrderLineItemsHeader>
						{configuration?.lineItems?.map((item, itemIndex) => {
							const foundSearch = `${item.sku} - ${item.description}`
								.toUpperCase()
								.includes((searchValue as string).toUpperCase());
							// Check if this is the item being edited
							const isEditing = configIndex === showEditLineItem && itemIndex === editLineItemIndex;
							if (foundSearch) {
								if (isEditing) {
									// Render EditLineItem instead of labels
									return (
										<EditLineItem
											key={`${item.sku}-${item.lineItemNumber}-edit`}
											group={group}
											addEditVisibility={toggleEditItem}
											selectedEditItem={selectedEditItem}
											description={configuration?.globals?.productLine?.description}
											editLineItemIndex={editLineItemIndex}
											handleMoveLineItem={handleMoveLineItem}
											configIndex={configIndex}
											isAddLineItem={false}
										/>
									);
								} else {
									// Render the regular view with labels
									return (
										<BuildLineItemGrid
											isLoading={isLoading}
											key={`${item.sku}-${item.lineItemNumber}`}
											index={itemIndex}
											error={lineItemErrors[configIndex]?.[itemIndex]}
											mainRowContent={[
												<Header
													description={item?.description ?? ""}
													key={`${item.sku}-${item.lineItemNumber}-header`}
													header={item?.sku ?? ""}
													lineNumber={item?.lineItemNumber ?? ""}
												/>,
												<div key={`${item.sku}-${item.lineItemNumber}-empty`} />,
												<div key={`${item.sku}-${item.lineItemNumber}-door-style`}>
													<div>{NewOrderBuildGridDoorStyleColumnHeader}</div>
													<div data-testid="line-item-grid-data-door-style">
														{configuration.globals?.style?.description}
													</div>
												</div>,
												<div key={`${item.sku}-${item.lineItemNumber}-finish`}>
													<div>{NewOrderBuildGridFinishColumnHeader}</div>
													<div data-testid="line-item-grid-data-finish">
														{configuration.globals?.finish?.description}
													</div>
												</div>,
												<div key={`${item.sku}-${item.lineItemNumber}-qty`}>
													<div>{NewOrderBuildGridQuantityColumnHeader}</div>
													<div data-testid="line-item-grid-data-quantity">
														{item.quantityOrdered}
													</div>
												</div>,
												<div key={`${item.sku}-${item.lineItemNumber}-remove`}>
													<LinkButton
														onClick={() => {
															handleEditLineItem(item, itemIndex, configIndex);
														}}
														data-testid="line-item-edit-link"
													>
														{NewOrderEdit}
													</LinkButton>

													<LinkButton
														variant="remove"
														onClick={() =>
															handleOpenRemoveModal(item, itemIndex, configIndex)
														}
														data-testid="line-item-remove-link"
													>
														{NewOrderRemoveButton}
													</LinkButton>
												</div>
											]}
											modificationRows={item.modifications?.map((modification) => (
												<Modification
													description={modification.description}
													key={modification.sku}
													lineNumber={modification.lineItemNumber}
													title={modification.sku}
													values={modification.values}
												/>
											))}
											dimensionRows={item.requiredDimensions?.map((dimension, index: number) => (
												<Modification
													dimensionDescription={dimension.description}
													key={dimension.description}
													dimensionValue={dimension.value}
													lineNumber={`${item.lineItemNumber}.${index + 1}`}
												/>
											))}
										/>
									);
								}
							} else {
								return <></>;
							}
						})}
					</LineItemAccordionDetails>
					{(groupIndex !== showAddItemButton?.[0] || configIndex !== showAddItemButton?.[1]) &&
					showEditLineItem === undefined ? (
						<>
							<AddItemButton
								variant="text"
								onClick={() => toggleAddItem(groupIndex, configIndex)}
								data-testid="add-item-button"
							>
								<AddIcon />
								{AddLineAddLineButtonText}
							</AddItemButton>
							<AddItemButtonWrapper>{getConfigDisplay(configuration, parsedCSV)}</AddItemButtonWrapper>
						</>
					) : (
						(groupIndex === showAddItemButton?.[0] || configIndex === showAddItemButton?.[1]) &&
						showEditLineItem === undefined && (
							<>
								<AddNewLineItem
									selectedEditItem={selectedEditItem}
									handleLineItem={handleLineItem}
									index={configIndex}
									configuration={configuration}
									addItemVisibility={toggleAddItem}
									isAddLineItem
								/>
								<AddItemButtonWrapper>
									{getConfigDisplay(configuration, parsedCSV)}
								</AddItemButtonWrapper>
							</>
						)
					)}
					{configIndex !== (groupedDisplayConfigs?.[groupIndex]?.configurations?.length ?? 0) - 1 && (
						<Divider sx={{ margin: "16px", padding: 0 }} />
					)}
				</Fragment>
			))}
		</Card>
	);
};

export default FileContent;
