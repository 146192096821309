import { useState, PropsWithChildren, useMemo, useCallback } from "react";
import SidebarContext from "./SidebarContext";

const SidebarProvider = ({ children }: PropsWithChildren) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	const openNav = useCallback(() => setIsSidebarOpen(true), []);
	const closeNav = useCallback(() => setIsSidebarOpen(false), []);

	const contextValue = useMemo(
		() => ({
			isSidebarOpen,
			openNav,
			closeNav
		}),
		[isSidebarOpen, openNav, closeNav]
	);

	return <SidebarContext.Provider value={contextValue}>{children}</SidebarContext.Provider>;
};

export default SidebarProvider;
