import { Button, Modal } from "@mui/material";
import React from "react";
import { CloseModalButton, LeavePageModalWrapper } from "./LeavePageModal.styles";
import { AreYouSureText, ItemsWillClearText, NoContinueOrdering, YesExitOrder } from "constants/text";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import useWindowSettings from "hooks/useWindowSettings";
import useB2CAccount from "auth/useB2CAccount";
import { cartActions } from "features/reducers/replacementOrder/cart.ts";
import { submittingReplacementOrderActions } from "features/reducers/replacementOrder/submittingReplacementOrder.ts";

interface Props {
	open: boolean;
	url: string;
	continueOrdering: () => void;
	closeModal?: () => void;
	isOnOrderDetailsPage?: boolean;
	orderDetailId?: string;
}

const LeavePageModal = ({
	open,
	url,
	continueOrdering,
	closeModal,
	isOnOrderDetailsPage = false,
	orderDetailId
}: Props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { logout } = useB2CAccount();
	const homeUrl = import.meta.env.VITE_APP_SALESFORCE_DASHBOARD_URL ?? "https://tandem.cabinetworksgroup.com";
	const handleExitOrder = async () => {
		if (isOnOrderDetailsPage && closeModal) {
			closeModal();
			return;
		}
		if (closeModal) closeModal();
		dispatch(cartActions.resetCart());
		dispatch(submittingReplacementOrderActions.resetOrder());
		if (url === homeUrl) {
			window.location.href = homeUrl;
		} else if (url === "logout") {
			await logout();
		} else {
			if (url.startsWith(homeUrl)) {
				window.location.href = url;
			} else {
				navigate(url);
			}
		}
	};

	const { isMobile } = useWindowSettings();

	const handleContinueOrderingOnOrderDetailsPage = () => {
		if (isOnOrderDetailsPage) {
			navigate(`/replacements/${orderDetailId}`);
		} else {
			continueOrdering();
		}
	};
	return (
		<Modal
			open={open}
			data-testid="leave-page-modal"
		>
			<LeavePageModalWrapper isMobile={isMobile}>
				<h6>{AreYouSureText}</h6>
				<p>{ItemsWillClearText}</p>
				<CloseModalButton
					isMobile={isMobile}
					onClick={handleContinueOrderingOnOrderDetailsPage}
				/>
				<div>
					<Button
						variant="outlined"
						sx={{
							width: isMobile ? "257px" : "268px",
							margin: 0
						}}
						onClick={handleExitOrder}
						data-testid="leave-page-modal-exit-order-button"
					>
						{YesExitOrder}
					</Button>
					<Button
						variant="contained"
						sx={{
							width: isMobile ? "257px" : "268px",
							margin: 0
						}}
						onClick={handleContinueOrderingOnOrderDetailsPage}
						data-testid="leave-page-modal-continue-ordering-button"
					>
						{NoContinueOrdering}
					</Button>
				</div>
			</LeavePageModalWrapper>
		</Modal>
	);
};

export default LeavePageModal;
