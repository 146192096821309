import { formatDateToMMDDYYYY } from "utils/date";
import { checkForAndReplaceNullEmptyWhitespace, convertToTitleCase } from "utils/string";
import { getFileNameBasedOnCurrentDateTime } from "utils/file";
import { OrderSummaryViewModel, OrderType } from "data/api/v1";
import { UnavailableDataPlaceholderText } from "constants/text";
import * as Excel from "exceljs";
import FileSaver from "file-saver";

export function convertOrderSummaryOrdersToExcelOrders(orders: OrderSummaryViewModel[]) {
	return orders.map((order: OrderSummaryViewModel) => {
		return {
			"Account Number": checkForAndReplaceNullEmptyWhitespace(order.accountNumber),
			"Order Number": checkForAndReplaceNullEmptyWhitespace(order.orderNumber),
			"PO Number": checkForAndReplaceNullEmptyWhitespace(order.poNumber),
			"Order Status":
				order.orderType !== OrderType.BILLING_ONLY
					? checkForAndReplaceNullEmptyWhitespace(convertToTitleCase(order.status).toUpperCase())
					: UnavailableDataPlaceholderText,
			"Order Date": order.orderDate
				? formatDateToMMDDYYYY(new Date(order.orderDate))
				: UnavailableDataPlaceholderText,
			"Estimated Delivery Date":
				order.orderType !== OrderType.BILLING_ONLY
					? checkForAndReplaceNullEmptyWhitespace(order.eta)
					: UnavailableDataPlaceholderText,
			"Cabinet Count": order.counts?.cabinetCount ?? UnavailableDataPlaceholderText,
			"Cube Count": order.counts?.cubeCount ?? UnavailableDataPlaceholderText,
			"Accessory Count": order.counts?.accessoryCount ?? UnavailableDataPlaceholderText,
			Brand: checkForAndReplaceNullEmptyWhitespace(convertToTitleCase(order.brand).toUpperCase()),
			"Designer Number": checkForAndReplaceNullEmptyWhitespace(order.designer?.designerNumber),
			"Door Style": checkForAndReplaceNullEmptyWhitespace(order.doorStyle),
			Finish: checkForAndReplaceNullEmptyWhitespace(order.finish),
			"Custom Built For/Job Name": checkForAndReplaceNullEmptyWhitespace(order.jobName),
			"Truck Number": checkForAndReplaceNullEmptyWhitespace(order.shippingDetails?.truckNumber),
			"Tracking Number": checkForAndReplaceNullEmptyWhitespace(order.shippingDetails?.trackingNumber),
			"Order Type": checkForAndReplaceNullEmptyWhitespace(convertToTitleCase(order.orderType).toUpperCase())
		};
	});
}

export function generateMyOrdersExcel(orders: any) {
	const workbook = new Excel.Workbook();
	const ordersWorksheet = workbook.addWorksheet();

	const csvOrders = convertOrderSummaryOrdersToExcelOrders(orders);

	if (csvOrders.length > 0) {
		ordersWorksheet.columns = Object.keys(csvOrders[0] ?? {}).map((col) => {
			return { key: col, header: col };
		});
		ordersWorksheet.addRows(csvOrders);
	}

	const filePrefix = getFileNameBasedOnCurrentDateTime("MyOrders");

	try {
		workbook.xlsx.writeBuffer().then((data) => {
			const blob = new Blob([data], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
			});
			FileSaver.saveAs(blob, `${getFileNameBasedOnCurrentDateTime(filePrefix)}.xlsx`);
		});
	} catch (e) {
		console.error("Error trying to generate order list xlsx file", e);
	}
}
