import { Button, Divider } from "@mui/material";
import { CloseSubMenuButton, NavigationPlaceNewOrderButton } from "./appNavigation.styles";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { UserNavigationItemViewModel } from "data/api/v1/model/user-navigation-item-view-model";
import { MVPNewOrderText, NavigationOrderPlaceNewOrder } from "constants/text";
import {
	CompressedMenuNavigationContainer,
	CompressedMenuPersonIcon,
	CompressedMenuSection,
	CompressedNavigationContainer,
	CompressedNavigationIcon,
	CompressedNavigationMenuButton,
	NavigationSubMenuItemButtonContainer,
	NavigationSubMenuItemText,
	NavigationSubMenuLinks,
	SubMenuListItemButton
} from "./compressedNavigation.styles";
import { flags } from "constants/featureFlags.ts";

interface Props {
	handleClickNavigationOption: (navItem: UserNavigationItemViewModel, index: number) => void;
	handleCloseSubMenu: () => void;
	handleMVPButtonClick: () => void;
	mainNavigationItems: Array<UserNavigationItemViewModel>;
	subNavigation: UserNavigationItemViewModel | null;
}
const CompressedNavigation = ({
	handleClickNavigationOption,
	handleCloseSubMenu,
	handleMVPButtonClick,
	mainNavigationItems,
	subNavigation
}: Props) => {
	const renderCompressedNavigationMenuIcon = (navItem: UserNavigationItemViewModel, index: number) => {
		const navLink = navItem.link;
		const subNavLink = navItem.navigationItems.map((subNav) => subNav.link);

		return (
			<CompressedNavigationMenuButton
				disableRipple
				data-id={navItem.id}
				id={navItem.id}
				data-testid={`${navItem.id}-link-compressed`}
				key={navItem.id}
				aria-describedby={`${navItem.id}-link-compressed`}
				onClick={() => handleClickNavigationOption(navItem, index)}
			>
				{window.location.pathname === navLink || subNavLink.includes(window.location.pathname) ? (
					<img
						alt={navItem.title}
						data-testid="main-menu-item-selected"
						src={"/assets/navigation_logos/" + navItem.selectedIcon}
					/>
				) : (
					<CompressedNavigationIcon src={"/assets/navigation_logos/" + navItem.icon} />
				)}
			</CompressedNavigationMenuButton>
		);
	};

	const renderCompressedSubNavigation = (navItem: UserNavigationItemViewModel, index: number) => {
		if (navItem.title.toUpperCase() === NavigationOrderPlaceNewOrder) {
			return (
				<NavigationPlaceNewOrderButton
					variant="contained"
					data-id="navigation-place-new-order-button"
					data-testid="navigation-place-new-order-button"
					fullWidth
					onClick={() => handleClickNavigationOption(navItem, index)}
				>
					{navItem.title}
				</NavigationPlaceNewOrderButton>
			);
		} else {
			return (
				<NavigationSubMenuItemButtonContainer>
					<SubMenuListItemButton
						disableRipple
						data-id={navItem.id}
						id={navItem.id}
						key={navItem.id}
						aria-describedby={`${navItem.id}-link-submenu`}
						data-testid={`navigation-subMenu-${navItem.id}`}
						onClick={() => handleClickNavigationOption(navItem, index)}
						selected={window.location.pathname === navItem.link}
					>
						<NavigationSubMenuItemText>
							{navItem.title}
							{window.location.pathname === navItem.link && (
								<img
									alt={navItem.title}
									src="/assets/navigation_logos/SelectedNavItemBlueDot.svg"
								/>
							)}
						</NavigationSubMenuItemText>
					</SubMenuListItemButton>
				</NavigationSubMenuItemButtonContainer>
			);
		}
	};

	const allowNewOrders = `${flags.env}` !== "prod";

	return (
		<CompressedNavigationContainer>
			<div>
				<CompressedMenuSection>
					<img
						src="/assets/tandem_logos/CWGLogoNoText.svg"
						alt="CWG Logo"
						data-testid="tandem-branding"
					/>
				</CompressedMenuSection>
				<Divider />
				<CompressedMenuSection>
					<CompressedMenuPersonIcon />
				</CompressedMenuSection>
				<Divider />
				<CompressedMenuNavigationContainer>
					{mainNavigationItems.map((navItem, index) => renderCompressedNavigationMenuIcon(navItem, index))}
				</CompressedMenuNavigationContainer>
			</div>
			<div>
				<NavigationSubMenuLinks>
					<CloseSubMenuButton
						onClick={handleCloseSubMenu}
						data-id="navigation-close-sub-menu-button"
						data-testid="navigation-close-sub-menu-button"
					>
						<ChevronLeft />
						<span>{subNavigation?.title}</span>
					</CloseSubMenuButton>
					{subNavigation?.navigationItems.map((navItem, index) =>
						renderCompressedSubNavigation(navItem, index)
					)}
					{allowNewOrders && subNavigation?.title === "Orders" && (
						<Button
							variant="contained"
							fullWidth
							sx={{ marginTop: "16px" }}
							onClick={handleMVPButtonClick}
						>
							{MVPNewOrderText}
						</Button>
					)}
				</NavigationSubMenuLinks>
			</div>
		</CompressedNavigationContainer>
	);
};

export default CompressedNavigation;
