import styled from "@emotion/styled";

interface OrderListErrorWarningWrapperProps {
	isMobile: boolean;
}

export const OrderListErrorWarningWrapper = styled.div<OrderListErrorWarningWrapperProps>(({ isMobile }) => ({
	display: "flex",
	flexWrap: "nowrap",
	alignItems: "center",
	padding: ".25rem 0 9px 1.75rem",
	paddingLeft: isMobile ? "1rem" : "0",
	backgroundColor: "var(--white-50)",
	color: "var(--orange-500)",
	p: {
		marginLeft: ".5rem",
		marginRight: ".5rem",
		fontFamily: "Gibson Medium, sans-serif"
	}
}));

interface DefaultMessageWrapperProps {
	removeHeight?: boolean;
}

export const DefaultMessageWrapper = styled.div<DefaultMessageWrapperProps>(({ removeHeight, theme }) => ({
	backgroundColor: "var(--white-50)",
	boxShadow: "0 3px 1px -2px var(--gray-400)",
	padding: "24px",
	margin: "0 16px",
	height: removeHeight ? "unset" : "calc(100vh - 280px)",
	minHeight: "450px",
	textAlign: "center",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	color: theme.project.colors.fontColors.main,
	div: {
		marginTop: "8px"
	},
	h6: {
		margin: "0 0 8px 0"
	},
	p: {
		lineHeight: "var(--line-height-13)",
		letterSpacing: "var(--letter-spacing-4)",
		margin: 0
	}
}));

export const BulletContainer = styled.div({
	textAlign: "left"
});
