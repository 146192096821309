import { type FC } from "react";
import CheckingAddress from "../assets/checking_address.svg";
import {
	ConfirmAddressWrapper,
	DisplayOnlyAddress,
	LoadingWrapper,
	RadioLabel,
	StyledFormControlLabel,
	VerifyAddressErrorBanner
} from "pages/Replacements/components/AddNewAddressModal/components/confirmAddress.styles.ts";
import {
	LoadingLabel,
	VerifyAddressConfirmYourAddress,
	VerifyAddressErrorMessage,
	VerifyAddressWeSuggest,
	VerifyAddressYouEntered,
	VerifyLoadingMessage
} from "constants/text.ts";
import LinearProgress from "@mui/material/LinearProgress";
import { Controller, useFormContext } from "react-hook-form";
import { AddressFormFields } from "pages/Replacements/forms/AddressForm/schema.ts";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { AddressValidateResponse } from "data/api/v1/model/address-validate-view-model";

interface ConfirmAddressProps {
	suggestedAddress: AddressValidateResponse | undefined;
	isLoading: boolean;
}

export const RADIO_BUTTON_VALUES = {
	suggested: "suggested",
	entered: "entered"
} as const;

const getFormattedAddress = (address: AddressValidateResponse | AddressFormFields) => {
	if ("fullName" in address) {
		return [address.address, address.address2, `${address?.city}, ${address?.state} ${address?.zip}`]
			.filter(Boolean)
			.map((field) => <div key={field}>{field}</div>);
	} else {
		return [address.line1, address.line2, `${address?.city}, ${address?.state} ${address?.zip}`]
			.filter(Boolean)
			.map((field) => <div key={field}>{field}</div>);
	}
};

const ConfirmAddress: FC<ConfirmAddressProps> = ({ suggestedAddress, isLoading }) => {
	const { control, getValues } = useFormContext<AddressFormFields>();
	const enteredValues = getValues();

	if (isLoading || suggestedAddress?.isCorrected === false) {
		return (
			<ConfirmAddressWrapper>
				<LoadingWrapper
					aria-label={LoadingLabel}
					data-testid="address-verify-loading"
				>
					<CheckingAddress />
					<div>{VerifyLoadingMessage}</div>
					<LinearProgress />
				</LoadingWrapper>
			</ConfirmAddressWrapper>
		);
	}

	return (
		<ConfirmAddressWrapper>
			<VerifyAddressErrorBanner
				errorMessage={VerifyAddressErrorMessage}
				title={VerifyAddressConfirmYourAddress}
			/>
			<FormControl>
				<Controller
					name="validateChoice"
					control={control}
					defaultValue={suggestedAddress ? RADIO_BUTTON_VALUES.suggested : RADIO_BUTTON_VALUES.entered}
					render={({ field: { onChange, value } }) => (
						<RadioGroup
							defaultValue={RADIO_BUTTON_VALUES.suggested}
							onChange={onChange}
							value={value}
						>
							{suggestedAddress && (
								<>
									<RadioLabel>{VerifyAddressWeSuggest}</RadioLabel>
									<StyledFormControlLabel
										value="suggested"
										control={<Radio size="small" />}
										label={getFormattedAddress(suggestedAddress)}
										data-testid="we-suggest-address"
									/>
								</>
							)}
							<RadioLabel>{VerifyAddressYouEntered}</RadioLabel>
							{suggestedAddress ? (
								<FormControlLabel
									value="entered"
									control={<Radio size="small" />}
									label={getFormattedAddress(enteredValues)}
									data-testid="address-entered"
								/>
							) : (
								<DisplayOnlyAddress>{getFormattedAddress(enteredValues)}</DisplayOnlyAddress>
							)}
						</RadioGroup>
					)}
				/>
			</FormControl>
		</ConfirmAddressWrapper>
	);
};

export default ConfirmAddress;
