import styles from "pages/replacements-page-styles.module.css";
import { Divider, Grid } from "@mui/material";
import {
	FlyoutConstructionLabel,
	FlyoutDoorStyleLabel,
	FlyoutFinishNameLabel,
	FlyoutHingeLabel,
	FlyoutItemNumberLabel,
	FlyoutModificationsLabel,
	FlyoutStandardDimensionsLabel,
	ReplacementsFlyoutOriginalItemText
} from "constants/text";
import { useMemo } from "react";
import { LineItemModificationViewModel } from "data/api/v1";
import { ReplacementCartItem } from "features/reducers/replacementOrder/cart.ts";

interface LineItemDetailProps {
	lineItem: ReplacementCartItem;
}

const FlyoutLineItemDetails = ({ lineItem }: LineItemDetailProps) => {
	const modificationsMap = useMemo(() => {
		return lineItem.modifications?.map((modification: LineItemModificationViewModel) => modification.details);
	}, [lineItem.modifications]);

	return (
		<>
			<Grid container>
				<Grid
					item
					xs={12}
				>
					<p
						className="overline"
						data-testid="replacements-flyout-replace-original-item-title-text"
					>
						{ReplacementsFlyoutOriginalItemText}
					</p>
				</Grid>
				<Grid
					item
					xs={1}
				>
					<p
						className={`${styles["lineItemNumberHeader"]} body1`}
						data-testid="replacements-flyout-replace-original-item-line-item-number"
					>
						{lineItem.lineItemNumber}
					</p>
				</Grid>
				<Grid
					item
					xs={10}
					className={styles.heading}
				>
					<h6 data-testid="replacements-flyout-replace-original-item-sku">{lineItem.sku}</h6>
					<p
						className="body1"
						data-testid="replacements-flyout-replace-original-item-description"
					>
						{lineItem.description}
					</p>
				</Grid>
				<Grid
					item
					xs={12}
				>
					<Divider
						sx={{ margin: "0.5rem 0" }}
						data-testid="replacements-flyout-replace-original-item-divider"
					/>
				</Grid>
			</Grid>
			<Grid
				container
				className={styles.replacementsFlyoutPartDetails}
			>
				{lineItem.itemNumber && (
					<Grid
						item
						xs={12}
					>
						<p
							className="body2"
							data-testid="replacements-flyout-part-details-item-number"
						>
							<span className="subtitle2">{FlyoutItemNumberLabel}</span> {lineItem.itemNumber}
						</p>
					</Grid>
				)}
				{lineItem.construction && (
					<Grid item>
						<p
							className="body2"
							data-testid="replacements-flyout-part-details-construction"
						>
							<span className="subtitle2">{FlyoutConstructionLabel}</span> {lineItem.construction}
						</p>
					</Grid>
				)}
				{lineItem.hinge && (
					<Grid
						item
						xs={12}
					>
						<p
							className="body2"
							data-testid="replacements-flyout-part-details-hinge"
						>
							<span className="subtitle2">{FlyoutHingeLabel}</span> {lineItem.hinge}
						</p>
					</Grid>
				)}
				{lineItem.measurements && (
					<Grid
						item
						xs={12}
					>
						<p
							className="body2"
							data-testid="replacements-flyout-part-details-dimensions"
						>
							<span className="subtitle2">{FlyoutStandardDimensionsLabel}</span>{" "}
							{lineItem.measurements.width}W X {lineItem.measurements.depth}D X{" "}
							{lineItem.measurements.height}H
						</p>
					</Grid>
				)}
				{modificationsMap?.length! > 0 && (
					<Grid
						item
						xs={12}
					>
						<p
							className="body2"
							data-testid="replacements-flyout-part-details-modifications"
						>
							<span className="subtitle2">{FlyoutModificationsLabel}</span> Yes
						</p>
					</Grid>
				)}
				{lineItem.doorStyle && (
					<Grid
						item
						xs={12}
					>
						<p
							className="body2"
							data-testid="replacements-flyout-part-details-door-style"
						>
							<span className="subtitle2">{FlyoutDoorStyleLabel}</span> {lineItem.doorStyle}
						</p>
					</Grid>
				)}
				{lineItem.finish && (
					<Grid
						item
						xs={12}
					>
						<p
							className="body2"
							data-testid="replacements-flyout-part-details-finish"
						>
							<span className="subtitle2">{FlyoutFinishNameLabel}</span> {lineItem.finish}
						</p>
					</Grid>
				)}
			</Grid>
		</>
	);
};

export default FlyoutLineItemDetails;
