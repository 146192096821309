import React, { useMemo } from "react";
import EmptyShoppingCart from "./EmptyShoppingCart";
import ShoppingCartHeader from "./ShoppingCartHeader";
import SelectedShoppingCartItems from "./SelectedShoppingCartItems";
import ShoppingCartFooter from "./ShoppingCartFooter";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import styles from "pages/replacements-page-styles.module.css";
import { ReplacementCartItem } from "features/reducers/replacementOrder/cart.ts";
import { ShoppingCartFlyoutScrollableWrapper } from "./ShoppingCartFlyout.styles";
import useWindowSettings from "hooks/useWindowSettings";
import { useLocation, useParams } from "react-router";

export interface ShoppingCartProps {
	showButtons: boolean;
	edit: () => void;
	closeDrawer: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const ShoppingCartFlyout = ({ showButtons, edit, closeDrawer }: ShoppingCartProps) => {
	const sortedItems: ReplacementCartItem[] = useSelector((state: RootState) =>
		[...state.cart.replacements].sort((a, b) => a.lineItemNumber - b.lineItemNumber)
	);

	const { isMobile } = useWindowSettings();
	const location = useLocation();

	const params = useParams();

	const isReplacementsPage = useMemo(() => {
		return (
			!location.pathname.includes(`/replacements/review/${params?.orderId}`) &&
			!location.pathname.includes(`/replacements/reason/${params?.orderId}`)
		);
	}, [location.pathname, params?.orderId]);

	return (
		<>
			<div
				key="cart"
				data-testid="shopping-cart-flyout-container"
				style={{ width: "375px" }}
			>
				<ShoppingCartHeader closeDrawer={closeDrawer} />
				{sortedItems.length === 0 ? (
					<EmptyShoppingCart closeDrawer={closeDrawer} />
				) : (
					<ShoppingCartFlyoutScrollableWrapper
						isMobile={isMobile}
						isReplacementsPage={isReplacementsPage}
					>
						{sortedItems.map(
							(item) =>
								item && (
									<SelectedShoppingCartItems
										key={item.sku}
										item={item}
										edit={edit}
									/>
								)
						)}
					</ShoppingCartFlyoutScrollableWrapper>
				)}
			</div>
			<span className={styles.footerContainer}>
				{showButtons && sortedItems.length > 0 && <ShoppingCartFooter closeDrawer={closeDrawer} />}
			</span>
		</>
	);
};

export default ShoppingCartFlyout;
