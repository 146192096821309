import { NewOrdersContentContainer, NewOrdersLayoutContainer } from "components/NewOrders/NewOrders.styles";
import BuildNewOrderLineItems from "components/NewOrders/BuildNewOrderLineItems/BuildNewOrderLineItems";
import NewOrdersHeader from "components/NewOrders/NewOrdersHeader/NewOrdersHeader";
import { RefObject, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { BuildOrderHeaderSkeleton } from "pages/OrderPages/BuildOrderPage/buildOrderPage.styles.ts";
import { useParams } from "react-router-dom";

export interface AddOnsProps {
	isAddOn?: boolean;
}

const BuildOrderPage = ({ isAddOn }: AddOnsProps) => {
	const params = useParams();
	const [contentOffset, setContentOffset] = useState(0);
	const [isLoading, setIsLoading] = useState<boolean>(Boolean(params?.draftId));
	const calcHeightOfHeaderForContentTopOffset = (headerContainer: RefObject<HTMLDivElement>) => {
		setContentOffset(headerContainer.current?.clientHeight ?? 0);
	};

	const draftOrder = useSelector((state: RootState) => state.submittingNewOrder.draftOrder);

	const handleSetIsLoading = useCallback((value: boolean) => {
		setIsLoading(value);
	}, []);

	return (
		<NewOrdersLayoutContainer>
			{isLoading ? (
				<BuildOrderHeaderSkeleton title="Place a New Order" />
			) : (
				<NewOrdersHeader
					getContainerElement={calcHeightOfHeaderForContentTopOffset}
					draftOrder={draftOrder}
					isAddOn={isAddOn}
				/>
			)}

			<NewOrdersContentContainer marginTopOffset={isLoading ? 0 : contentOffset}>
				<BuildNewOrderLineItems
					handleSetIsLoading={handleSetIsLoading}
					draftOrder={draftOrder}
					isAddOn={isAddOn}
				/>
			</NewOrdersContentContainer>
		</NewOrdersLayoutContainer>
	);
};

export default BuildOrderPage;
