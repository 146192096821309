import { Sidebar } from "containers";
import { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useGetUserDetailsQuery } from "features/api/userApi.ts";
import { LegalLinks } from "components";
import { SidebarContainer } from "./landingPage.styles";

const LandingPage = () => {
	const navigateToRedirects = useNavigate();
	const location = useLocation();

	const { data: userDetails, isLoading, refetch } = useGetUserDetailsQuery();

	useEffect(() => {
		if (refetch === undefined) return;
		refetch();
	}, [refetch]);

	useEffect(() => {
		if (userDetails?.user === undefined) return;

		if (location.pathname === "/") return navigateToRedirects("/projects");
	}, [userDetails, location.pathname, navigateToRedirects]);

	if (location.pathname !== "/" && !isLoading) {
		return (
			<div data-testid="tandem-landing-page">
				<Container
					maxWidth={false}
					disableGutters
				>
					<Grid container>
						<Grid
							item
							xs={12}
						>
							<SidebarContainer>
								<aside>
									<Sidebar userDetails={userDetails} />
								</aside>
							</SidebarContainer>
						</Grid>
					</Grid>
					<Outlet />
					<LegalLinks />
				</Container>
			</div>
		);
	}
	return null;
};

export default LandingPage;
