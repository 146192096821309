import styles from "../replacements-page-styles.module.css";
import ReplacementsHeader from "components/Replacements/ReplacementsHeader";
import ReplacementReason from "components/Replacements/ReplacementReason";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetByIdQuery } from "features/api/orderApi.ts";

const ReplacementReasonPage = () => {
	const params = useParams();
	const { data } = useGetByIdQuery(params?.orderId ?? "");

	return (
		<div>
			<div
				className={styles.replacementsHeaderContainer}
				data-testid="replacementReasons-replacementsHeader"
			>
				<ReplacementsHeader orderDetail={data} />
			</div>
			<div
				className={styles.replacementReasonContainer}
				data-testid="replacementReasons-replacementReasonContainer"
			>
				<ReplacementReason orderDetail={data} />
			</div>
		</div>
	);
};

export default ReplacementReasonPage;
