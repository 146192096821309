import { createApi } from "@reduxjs/toolkit/query/react";
import endpoints, { baseURL } from "data/api/v1/endpoints.ts";
import { baseQueryWithRedirect } from "features/api/utils/apiUtils.ts";
import { BillToShipTosAccountViewModel } from "data/api/v1";
import { GetAccountsResponse, GetAccountsResponseData } from "types/api/accounts/getAccounts.ts";
import { BillToInfoResponse, BillToInfoResponseData, BillTosRequestParameters } from "types/api/accounts/billToInfo.ts";
import { models } from "types/api/viewModels.ts";

interface BillToShipToParams {
	accountId: string;
	billToId: string;
}
export const accountApiSlice = createApi({
	reducerPath: "accountApi",
	baseQuery: baseQueryWithRedirect(baseURL),
	endpoints: (builder) => ({
		getAccounts: builder.query<GetAccountsResponseData, string | null>({
			query: (productLineCode?: string | null) => endpoints.accounts.getAccounts(productLineCode),
			transformResponse: (response: GetAccountsResponse) => response?.data
		}),
		getAccountsById: builder.query<Array<models["CustomerAccountViewModel"]>, string>({
			query: (orderId: string) => endpoints.accounts.getAccountsByOrderId(orderId),
			transformResponse: (response: any) => response?.data
		}),
		getBillToShipTos: builder.query<BillToShipTosAccountViewModel[], object>({
			query: (args: BillToShipToParams) => {
				const { accountId, billToId } = args;
				return {
					url: endpoints.accounts.getBillToShipTos(accountId, billToId)
				};
			},
			transformResponse: (response: { data: BillToShipTosAccountViewModel[] }) => response?.data,
			transformErrorResponse: (errors: any) => ({
				data: {
					code: errors?.code
				}
			})
		}),
		getBillToInfo: builder.query<BillToInfoResponseData, object>({
			query: (args: BillTosRequestParameters) => {
				const { accountId, billToId } = args;
				return {
					url: endpoints.accounts.getBillToInfo(accountId, billToId)
				};
			},
			transformResponse: (response: BillToInfoResponse) => response?.data,
			transformErrorResponse: (errors: any) => ({
				data: {
					code: errors?.code
				}
			})
		})
	})
});

export const {
	useGetAccountsQuery,
	useLazyGetAccountsQuery,
	useGetAccountsByIdQuery,
	useGetBillToShipTosQuery,
	useGetBillToInfoQuery
} = accountApiSlice;
