import { SaveDraftRequestBody } from "types/api/orders/postDraftOrder.ts";

const encodeIfExists = (value?: string | null) => (value ? btoa(value.trim()) : undefined);

export const encodeDraftOrder = (draftOrder: SaveDraftRequestBody) => ({
	...draftOrder,
	draftName: encodeIfExists(draftOrder?.draftName),
	jobName: encodeIfExists(draftOrder?.jobName),
	poNumber: encodeIfExists(draftOrder?.poNumber),
	labelComments: btoa(draftOrder?.labelComments ?? ""),
	shipToAddress: {
		...draftOrder?.shipToAddress,
		name: encodeIfExists(draftOrder?.shipToAddress?.name),
		line1: encodeIfExists(draftOrder?.shipToAddress?.line1),
		line2: encodeIfExists(draftOrder?.shipToAddress?.line2),
		phoneNumber: encodeIfExists(draftOrder?.shipToAddress?.phoneNumber),
		email: encodeIfExists(draftOrder?.shipToAddress?.email)
	}
});
