import styled from "@emotion/styled";
import { TableHead, TableCell } from "@mui/material";

export const SaveDraftTableContainer = styled.div(({ theme }) => ({
	backgroundColor: theme.project.colors.backgroundColors.main,
	padding: 16
}));
export const SaveDraftExteriorTableHeader = styled.div(({ theme }) => ({
	marginBottom: 16,
	display: "flex",
	justifyContent: "space-between",
	"div:nth-of-type(1)": {
		flex: 5,
		marginRight: 8
	},
	"div:nth-of-type(2)": {
		flex: 1,
		marginRight: 4
	},
	"div:nth-of-type(3)": {
		flex: 2
	},
	[theme.breakpoints.down("lg")]: {
		display: "block",
		"div:nth-of-type(2)": {
			marginTop: 24
		},
		"div:nth-of-type(3)": {
			marginTop: 24,
			"div:nth-of-type(1)": {
				width: "100%"
			}
		}
	}
}));
export const SaveDraftFilters = styled.div(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	[theme.breakpoints.down("lg")]: {
		display: "block",
		"div:nth-of-type(1)": {
			width: "100%",
			"&.MuiButtonBase-root": {
				justifyContent: "left"
			}
		}
	}
}));

export const SaveDraftTableDisclaimer = styled.div(({ theme }) => ({
	...theme.project.typography.body.body2,
	marginBottom: 16,
	color: theme.project.colors.fontColors.main
}));

export const SaveDraftTableWrapper = styled.div(({ theme }) => ({
	border: theme.project.borders.presets.outline,
	borderWidth: "1px",
	[theme.breakpoints.down("md")]: {
		maxHeight: 600,
		overflow: "auto"
	}
}));

export const SaveDraftInteriorTableHeader = styled(TableHead)(({ theme }) => ({
	backgroundColor: theme.project.colors.backgroundColors.tertiary,
	"& .MuiTableCell-head": {
		padding: "8px 16px"
	}
}));

export const SaveDraftDateTableCell = styled(TableCell)({
	"&:hover": {
		cursor: "pointer"
	}
});

export const SaveDraftDraftTableCell = styled(TableCell)(({ theme }) => ({
	wordBreak: "break-word",
	[theme.breakpoints.down("md")]: {
		wordBreak: "normal"
	}
}));

export const SaveDraftTableSkeletonContainer = styled.div({
	display: "flex",
	flexDirection: "column",
	gap: 8
});

export const SaveDraftTableSkeletonRow = styled.div({
	display: "grid",
	gap: 24,
	gridTemplateColumns: "1fr 2fr 2fr 2fr 2fr 1fr",
	div: {
		display: "flex",
		width: "100%"
	},
	"& > div:nth-of-type(1)": {
		flexDirection: "column",
		gap: 8
	}
});
