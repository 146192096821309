export const ORDER_STATUS_TOOLTIPS = [
	{
		id: 1,
		status: "RECEIVED",
		description: "Order has been submitted to Cabinetworks Group"
	},
	{
		id: 2,
		status: "ACCEPTED",
		description: "Order has been validated and been accepted for production scheduling"
	},
	{
		id: 3,
		status: "SCHEDULED_FOR_PRODUCTION",
		description: "Order has been scheduled for production"
	},
	{
		id: 4,
		status: "IN_PRODUCTION",
		description: "Order currently being built on the production line"
	},
	{
		id: 5,
		status: "PRODUCTION_COMPLETE",
		description: "Production has been completed and order is packaged"
	},
	{
		id: 6,
		status: "HEADED_TO_CROSS_DOCK",
		description: "Order is en route to shipping carrier"
	},
	{
		id: 7,
		status: "DELIVERED",
		description: "Order has been delivered to the delivery address"
	},
	{
		id: 8,
		status: "CANCELLED",
		description: "Order has been canceled"
	},
	{
		id: 9,
		status: "EN_ROUTE_VIA_CARRIER",
		description: "Order is en route to the shipping address with carrier"
	},
	{
		id: 10,
		status: "INVOICED",
		description: "Invoice has been submitted for payment"
	},
	{
		id: 11,
		status: "SUBMITTED_WITH_ERRORS",
		description: "Order has been submitted with errors and could not be validated."
	}
];

export const ORDER_STATUS_REASON_TOOLTIPS = [
	{
		id: 1,
		status: "DELIVERY_DATE_CHANGED",
		description: "The ETA has been updated"
	},
	{
		id: 2,
		status: "PARTIALLY_SHIPPED",
		description: "Some items in your order have shipped separately"
	},
	{
		id: 3,
		status: "ITEMS_ON_BACKORDER",
		description:
			"Items in this order have been placed on backorder and may arrive separately from the original order placed"
	},
	{
		id: 4,
		status: "MISSING_INFORMATION",
		description: "Important information is missing on this order and cannot be scheduled for production"
	},
	{
		id: 5,
		status: "INCORRECT_PRICING",
		description: "Order was submitted with pricing discrepancies"
	},
	{
		id: 6,
		status: "PROCESSING_HOLD",
		description: "Placed on hold while the Cabinetworks Group team reviews the order"
	},
	{
		id: 7,
		status: "CUSTOMER_HOLD",
		description: "Placed on hold at the request of the customer"
	},
	{
		id: 8,
		status: "PRODUCT_VALIDATION_HOLD",
		description: "An item on the order doesn't match our system"
	}
];
