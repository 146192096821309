import styles from "pages/replacements-page-styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useMemo, useState, KeyboardEvent, MouseEvent } from "react";
import { Grid } from "@mui/material";
import ReplaceOriginalItemBox from "./ReplaceOriginalItemBox";
import {
	NoReplacementPartsText,
	PartsAvailableForReplacementText,
	ReplacementsFlyoutSelectPartText
} from "constants/text";
import { LineItemModificationViewModel, MeasurementsViewModel } from "data/api/v1";
import { ReplaceablePartsViewModel } from "data/api/v1/model/replaceable-parts-view-model";
import { useDispatch, useSelector } from "react-redux";
import { ReplacementCartItem, Part, cartActions } from "features/reducers/replacementOrder/cart.ts";
import { RootState } from "stores/application.store";
import PartsSearch from "./PartsSearch";
import FlyoutLineItemDetails from "./FlyoutLineItemDetails";
import ReplacePartBox from "./ReplacePartBox";
import FlyoutFooter from "./FlyoutFooter";
import { calculateMaxCabinetsToBeReplaced, createReplaceableParts } from "./ReplacementsUtil";
import { ReplacementsFlyoutScrollableWrapper } from "./ReplacementsFlyout.styles";
import useWindowSettings from "hooks/useWindowSettings";

interface ReplacementsFlyoutProps {
	id?: string | null;
	lineItemNumber?: number | null;
	itemNumber?: string | null;
	measurements?: MeasurementsViewModel | null;
	description?: string | null;
	lineItemId?: string | null;
	doorStyle?: string | null;
	finish?: string | null;
	modifications?: Array<LineItemModificationViewModel> | null;
	quantityOrdered?: number | null;
	sku?: string | null;
	construction?: string | null;
	hinge?: string | null;
	replaceableParts?: Array<ReplaceablePartsViewModel> | null;
	addToOrder: () => void;
	closeDrawer: (event: KeyboardEvent | MouseEvent) => void;
}

const ReplacementsFlyout = ({
	id,
	lineItemNumber,
	itemNumber,
	measurements,
	description,
	lineItemId,
	doorStyle,
	finish,
	modifications,
	quantityOrdered,
	sku,
	construction,
	hinge,
	replaceableParts,
	addToOrder,
	closeDrawer
}: ReplacementsFlyoutProps) => {
	const draft = useSelector((state: RootState) => state.cart.draft);
	const lineItem: ReplacementCartItem = draft ?? {
		id: id ?? "",
		lineItemNumber: lineItemNumber ?? 0,
		itemNumber: itemNumber ?? "",
		measurements,
		description: description ?? "",
		doorStyle: doorStyle ?? null,
		lineItemId: lineItemId ?? null,
		finish: finish ?? null,
		modifications: modifications ?? null,
		sku: sku ?? "",
		construction: construction ?? null,
		hinge: hinge ?? null,
		quantityOrdered: quantityOrdered ?? 0,
		wholeCabinetQuantity: 0,
		cabinetFileUploads: [],
		replaceableParts: replaceableParts ? createReplaceableParts(replaceableParts, lineItemId!) : [],
		cabinetDefectCode: "",
		cabinetReplacementReason: null,
		cabinetReplacementProblem: null
	};

	const searchOptions = useMemo(() => {
		return lineItem.replaceableParts.map(
			(replaceablePart: { partSku: string; description: string }) =>
				replaceablePart.partSku + " " + replaceablePart?.description
		);
	}, [lineItem.replaceableParts]);

	const [selectPartError, setSelectPartError] = useState(false);
	const [searchValue, setSearchValue] = useState<string>("");
	const dispatch = useDispatch();

	const maxCabinetsToBeReplaced = calculateMaxCabinetsToBeReplaced(lineItem);

	const totalParts = lineItem.replaceableParts.filter(
		(part: { partQuantity: number }) => part.partQuantity !== 0
	).length;

	const disableOriginalItemCheckbox = totalParts > 0 && maxCabinetsToBeReplaced === 0;

	const handleSearch = (_event: unknown, values: string) => {
		setSearchValue(values);
	};

	const handleSearchConditions = (parts: Part[]) => {
		const foundSearch = parts.find((part: Part) =>
			`${part.partSku.toUpperCase()} ${part.description.toUpperCase()}`.includes(searchValue.toUpperCase() ?? "")
		);
		return parts.filter((replaceablePart) => {
			if (searchValue) {
				return (
					`${replaceablePart.partSku.toUpperCase()} ${replaceablePart.description.toUpperCase()}`.includes(
						searchValue.toUpperCase() ?? ""
					) || !foundSearch
				);
			} else {
				return true;
			}
		});
	};

	const addToOrderHandler = () => {
		const totalParts = lineItem.replaceableParts.filter(
			(part: { partQuantity: number }) => part.partQuantity !== 0
		).length;

		if (totalParts === 0 && lineItem.wholeCabinetQuantity === 0) {
			setSelectPartError(true);
			return;
		}
		addToOrder();
		setSelectPartError(false);
		dispatch(cartActions.confirmEdit());
	};

	const handlePartError = (err: boolean) => {
		setSelectPartError(err);
	};

	const { isMobile } = useWindowSettings();

	return (
		<div
			data-testid="replacements-flyout-container"
			className={styles.replacementsFlyoutContainer}
		>
			<div
				data-testid="replacements-flyout-header"
				className={styles.replacementsFlyoutHeader}
			>
				<h1
					data-testid="replacements-flyout-header"
					className={styles.replacementPageHeaderTitle}
				>
					{ReplacementsFlyoutSelectPartText}
				</h1>
				<CloseIcon
					onClick={closeDrawer}
					className={styles.closeIcon}
					data-testid="replacements-flyout-close-button"
				/>
			</div>

			<ReplacementsFlyoutScrollableWrapper isMobile={isMobile}>
				<div
					data-testid="replacements-flyout-body"
					className={styles.replacementsFlyoutBody}
				>
					<FlyoutLineItemDetails lineItem={lineItem} />
					<Grid container>
						<Grid
							item
							data-testid="replacements-flyout-original-item-box"
						>
							<ReplaceOriginalItemBox
								maxCabinetsToBeReplaced={maxCabinetsToBeReplaced}
								lineItem={lineItem}
								disableOriginalItemCheckbox={disableOriginalItemCheckbox}
								handlePartError={handlePartError}
							/>
						</Grid>
					</Grid>
				</div>

				<div
					className={styles.partsAvailableForReplacementHeader}
					data-testid="replacements-flyout-parts-available-for-replacement-header"
				>
					<Grid container>
						<Grid item>
							{lineItem.replaceableParts?.length >= 1 ? (
								<p className="body1">
									<span className={styles.partsAvailableForReplacementText}>
										{lineItem.replaceableParts?.length}
									</span>

									{PartsAvailableForReplacementText}
								</p>
							) : (
								<p className="body1">{NoReplacementPartsText}</p>
							)}
						</Grid>
					</Grid>
				</div>

				{lineItem.replaceableParts?.length > 0 && (
					<div className={styles.partsSearch}>
						<PartsSearch
							searchOptions={searchOptions}
							searchValue={searchValue}
							handleSearch={handleSearch}
						/>
					</div>
				)}
				<div data-testid="replacements-flyout-parts-available-for-replacement-map">
					{handleSearchConditions(lineItem.replaceableParts).map(
						(part) =>
							part && (
								<ReplacePartBox
									key={part.id}
									part={part}
									lineItemToEdit={lineItem}
									handlePartError={handlePartError}
								/>
							)
					)}
				</div>
			</ReplacementsFlyoutScrollableWrapper>
			<div
				className={styles.replacementPartsFlyoutButtonContainer}
				data-testid="replacements-flyout-button-container"
			>
				<FlyoutFooter
					addToOrderHandler={addToOrderHandler}
					closeDrawer={closeDrawer}
					selectPartError={selectPartError}
				/>
			</div>
		</div>
	);
};

export default ReplacementsFlyout;
