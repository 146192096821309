import styled from "@emotion/styled";
import { Popper } from "@mui/material";

export const AutoCompletePopper = styled(Popper)(({ theme }) => ({
	div: {
		ul: {
			"& .MuiAutocomplete-option": {
				backgroundColor: theme.project.colors.backgroundColors.main + " !important"
			},
			"& .MuiAutocomplete-option.Mui-focused": {
				backgroundColor: theme.project.colors.allColors.hex.grayscale.gray100 + " !important"
			}
		}
	}
}));
