import { createSlice } from "@reduxjs/toolkit";
import { ShippingDetailsSchema } from "components/NewOrders/NewOrderShippingDetailsForm/schema.ts";
import { read } from "hooks/useSession.ts";

const shippingDetails = read("_shippingDetails");

const stateTemplate = {
	designer: undefined,
	poNumber: undefined,
	address: undefined,
	jobName: undefined,
	email: undefined,
	phoneNumber: undefined,
	deliveryInstructions: undefined,
	isCustomerPickup: undefined,
	requestedDeliveryDate: undefined
};
export const initialState: ShippingDetailsSchema = shippingDetails ?? stateTemplate;

const shippingDetailsOrderSlice = createSlice({
	name: "shippingDetails",
	initialState,
	reducers: {
		updateShippingDetails: (state, action) => {
			Object.assign(state, action.payload);
		},
		clearShippingDetails: () => {
			return {};
		}
	}
});

export const shippingDetailsActions = shippingDetailsOrderSlice.actions;
export default shippingDetailsOrderSlice.reducer;
