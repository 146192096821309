import { AutoCompleteContainer, SelectionDetails } from "../addGlobalAttributes.styles";
import { Controller, useFormContext } from "react-hook-form";
import AutoComplete from "../../../Common/Autocomplete/Autocomplete";
import { GlobalAttributeFields } from "../forms/schema";
import { SelectFinish, SelectShape, SelectSpecies } from "../../constants";
import { useLazyGetStyleDataQuery } from "features/api/globalsApi.ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { useEffect } from "react";
import { newOrderActions } from "features/reducers/newOrder/newOrder";
import { sortGlobalByDescription } from "components/NewOrders/utils/NewOrderUtils.tsx";
import { models } from "types/api/viewModels.ts";

interface StepTwoProps {
	isUpdate?: boolean;
}

const StepTwo = ({ isUpdate }: StepTwoProps) => {
	const { control, watch, clearErrors, setValue, resetField } = useFormContext<GlobalAttributeFields>();
	const dispatch = useDispatch();
	const selectedProductLine = watch("productLine");
	const selectedDoorStyle = watch("doorStyle");
	const selectedSpecies = watch("species");

	const [trigger, { data: doorStyleData, isLoading }] = useLazyGetStyleDataQuery();
	const updatedGlobalAttributes = useSelector((state: RootState) => state.newOrder.updatedGlobalAttributes);

	// Fetch the doorStyle

	useEffect(() => {
		if (selectedDoorStyle?.id) {
			trigger(selectedDoorStyle?.id);
		}
	}, [selectedDoorStyle?.id, trigger]);

	useEffect(() => {
		if (!isUpdate) {
			return;
		}

		const shapeValue = doorStyleData?.shapes?.find(
			(shape) => shape.id === updatedGlobalAttributes?.globals?.shape?.id
		);

		const speciesValue = doorStyleData?.species?.find(
			(species) => species.id === updatedGlobalAttributes?.globals?.species?.id
		);

		const finishValue = speciesValue?.finishes?.find(
			(finish) => finish.id === updatedGlobalAttributes?.globals?.finish?.id
		);

		if (shapeValue) {
			setValue("shape", shapeValue);
		}

		if (speciesValue) {
			setValue("species", speciesValue);
		}

		if (finishValue) {
			setValue("finish", finishValue);
		}
	}, [
		doorStyleData?.shapes,
		doorStyleData?.species,
		isUpdate,
		setValue,
		updatedGlobalAttributes?.globals?.finish?.id,
		updatedGlobalAttributes?.globals?.shape?.id,
		updatedGlobalAttributes?.globals?.species?.id
	]);

	const shapesOptions = doorStyleData?.shapes ?? [];
	const speciesOptions = doorStyleData?.species ?? [];
	const finishesOptions = selectedSpecies?.finishes ?? [];

	return (
		<AutoCompleteContainer>
			<SelectionDetails data-testid="global-attribute-modal-selection">
				{`${isUpdate ? updatedGlobalAttributes?.globals?.productLine?.description : selectedProductLine?.description}, ${selectedDoorStyle?.description}`}
			</SelectionDetails>
			{shapesOptions.length !== 0 && (
				<Controller
					name="shape"
					control={control}
					render={({ field: { onChange, value = null }, fieldState: { error } }) => (
						<AutoComplete
							required
							isLoading={isLoading}
							value={value}
							disableClearable
							label={SelectShape}
							getOptionLabel={(option) => option?.description}
							onChange={(_event, value) => {
								if (isUpdate) {
									const globalAttributesToBeUpdated: models["GlobalsViewModel"] = {
										...updatedGlobalAttributes?.globals,
										shape: {
											code: value.code,
											description: value.description,
											id: value.id
										}
									};
									dispatch(
										newOrderActions.updateGlobalAttributes({
											...updatedGlobalAttributes,
											globals: globalAttributesToBeUpdated
										})
									);
								}
								clearErrors("shape");
								onChange(value);
							}}
							options={sortGlobalByDescription(shapesOptions)}
							dataTestId="select-shape"
							isError={!!error}
							errorText={error?.message}
						/>
					)}
				/>
			)}
			{speciesOptions.length !== 0 && (
				<Controller
					name="species"
					control={control}
					render={({ field: { onChange, value = null }, fieldState: { error } }) => (
						<AutoComplete
							required
							isLoading={isLoading}
							value={value}
							disableClearable
							label={SelectSpecies}
							getOptionLabel={(option) => option?.description}
							onChange={(_event, value) => {
								if (isUpdate) {
									const globalAttributesToBeUpdated: models["GlobalsViewModel"] = {
										...updatedGlobalAttributes?.globals,
										species: {
											code: value.code,
											description: value.description,
											id: value.id
										},
										finish: {
											code: null,
											description: null,
											id: null
										}
									};
									dispatch(
										newOrderActions.updateGlobalAttributes({
											...updatedGlobalAttributes,
											globals: globalAttributesToBeUpdated
										})
									);
								}
								clearErrors("species");
								resetField("finish", { defaultValue: null as any });
								onChange(value);
							}}
							options={sortGlobalByDescription(speciesOptions)}
							dataTestId="select-wood-species"
							isError={!!error}
							errorText={error?.message}
						/>
					)}
				/>
			)}
			{(!selectedSpecies || finishesOptions.length !== 0) && (
				<Controller
					name="finish"
					control={control}
					render={({ field: { onChange, value = null }, fieldState: { error } }) => (
						<AutoComplete
							required
							value={value}
							isLoading={isLoading}
							disabled={!selectedSpecies}
							disableClearable
							label={SelectFinish}
							getOptionLabel={(option) => option?.description}
							onChange={(_event, value) => {
								if (isUpdate) {
									const globalAttributesToBeUpdated: models["GlobalsViewModel"] = {
										...updatedGlobalAttributes?.globals,
										finish: {
											code: value.code,
											description: value.description,
											id: value.id
										}
									};
									dispatch(
										newOrderActions.updateGlobalAttributes({
											...updatedGlobalAttributes,
											globals: globalAttributesToBeUpdated
										})
									);
								}
								clearErrors("finish");
								onChange(value);
							}}
							options={sortGlobalByDescription(finishesOptions)}
							dataTestId="select-finish"
							isError={!!error}
							errorText={error?.message}
						/>
					)}
				/>
			)}
		</AutoCompleteContainer>
	);
};

export default StepTwo;
