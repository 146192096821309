import {
	EditLineItemHeaderContainer,
	EditLineItemHeaderRow,
	EditAddModificationWrapper,
	EditAddModificationGlobalAttributesWrapper,
	EditConfigurationSelect
} from "./EditLineItemStyles.ts";
import { DimensionContainer, RequiredDimensionsValues } from "../RequiredDimensions/RequiredDimensionsStyles.ts";
import { EditLineItemHeader, ChangeGlobalAttributeGroup, EditProductLineLabel } from "./constants.ts";
import Header from "../../Common/LineItemGrid/Header/Header.tsx";
import {
	AddLineCancelButton,
	AddLineDepthLabel,
	AddLineHeightLabel,
	AddLineItemQuantityPlaceholder,
	AddLineItemStandardDimensionsHeader,
	AddLineSaveButton,
	AddLineWidthLabel,
	RequiredDimensionError,
	SelectRequiredDimensions
} from "../AddLineItem/constants.ts";
import AutoComplete from "../../Common/Autocomplete/Autocomplete.tsx";
import { MutableRefObject, SyntheticEvent, useRef, useState } from "react";
import {
	AddLineItemButtonWrapper,
	AddLineItemStandardContainer,
	AddLineItemStandardDimensions
} from "../AddLineItem/AddLineItemStyles.ts";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import AddModification from "../AddModification/AddModification.tsx";
import { models } from "../../../types/api/viewModels.ts";
import { ReplacementReasonAndProblemInputLabel } from "components/Replacements/SelectReplacementReasonAndProblemDropdowns.styles.ts";
import {
	calculateIntervals,
	ConfigurationGroup,
	getAttributes,
	getSubheaderAttributes
} from "components/NewOrders/utils/NewOrderUtils.tsx";
import styles from "pages/replacements-page-styles.module.css";
import { Controller, useForm } from "react-hook-form";
import { schema } from "./schema.ts";
import { yupResolver } from "@hookform/resolvers/yup";
import { v4 as uuidv4 } from "uuid";

interface EditLineItemProps {
	group: ConfigurationGroup;
	selectedEditItem: MutableRefObject<models["PendingLineItemViewModel"] | undefined>;
	description: string | null | undefined;
	editLineItemIndex: number | undefined;
	configIndex: number;
	isAddLineItem?: boolean;
	addEditVisibility(index?: number): void;
	handleMoveLineItem(
		item: models["PendingLineItemViewModel"],
		deleteItemIndex: number | undefined,
		deleteConfigIndex: number | undefined,
		destinationConfigIndex: number | undefined
	): void;
}

const EditLineItem = ({
	group,
	addEditVisibility,
	selectedEditItem,
	description,
	editLineItemIndex,
	configIndex,
	handleMoveLineItem,
	isAddLineItem
}: EditLineItemProps) => {
	const [modification, setModification] = useState<models["ModificationViewModel"][]>([]);
	const [childError, setChildError] = useState(false);
	const dimensions = useRef<Record<string, number | null>>({});
	const [requiredDimensionsValues, setRequiredDimensionsValues] = useState<models["PendingValueViewModel"][]>(
		selectedEditItem.current?.requiredDimensions ?? []
	);
	const { handleSubmit, control } = useForm({
		mode: "onChange",
		resolver: yupResolver(schema),
		reValidateMode: "onChange",
		defaultValues: {
			productSelectQuantity: selectedEditItem.current?.quantityOrdered,
			globalAttributeGroup: configIndex
		}
	});

	const quantityValues = Array.from({ length: 99 }, (_, i) => i + 1);
	const quantity = quantityValues.join().split(",");

	const handleMouseDownCapture = (e: SyntheticEvent) => {
		e.stopPropagation();
	};

	const standardDimensions =
		selectedEditItem.current?.standardDepth ||
		selectedEditItem.current?.standardHeight ||
		selectedEditItem.current?.standardWidth;

	const handleChildData = (modification: models["PendingLineItemViewModel"][]) => {
		setModification(modification);
	};

	const [saveModButtonClick, setSaveModButtonClick] = useState(false);

	const handleChildValidation = (error: boolean) => {
		setChildError(error);
	};

	const generateMenuItems = () => {
		return group.configurations
			.map((configuration, index) => (
				<MenuItem
					value={index}
					key={configuration.globals?.productLine?.id}
				>
					<EditConfigurationSelect>
						<span>{getAttributes(configuration)}</span>
						<span>{getSubheaderAttributes(configuration)}</span>
					</EditConfigurationSelect>
				</MenuItem>
			))
			.filter((item) => typeof item !== "undefined");
	};

	const handleSaveAction = (formValues: any) => {
		const initialModifications = selectedEditItem?.current?.modifications?.map((mod: any) => ({
			sku: mod.sku,
			lineItemNumber: "",
			quantityOrdered: 1,
			description: mod.description,
			id: mod.id,
			itemKey: mod.sku,
			uuid: uuidv4()
		}));

		if (!childError) {
			const editedLineItem: models["PendingLineItemViewModel"] = {
				...selectedEditItem?.current,
				quantityOrdered: formValues.productSelectQuantity,
				id: selectedEditItem?.current?.id,
				lineItemNumber: "1",
				modifications: modification.length > 0 ? modification : initialModifications,
				requiredDimensions: requiredDimensionsValues
			};

			handleMoveLineItem(editedLineItem, editLineItemIndex, configIndex, formValues.globalAttributeGroup);
			addEditVisibility();
		} else {
			setSaveModButtonClick(true);
		}
	};

	const handleDimensionChange = (
		id: string,
		value: number,
		description: string | null,
		increment: number,
		maxValue: number,
		minValue: number,
		index: number
	) => {
		dimensions.current[id] = value;

		const requiredDimensions: models["RequiredDimensionsViewModel"] & models["PendingValueViewModel"] = {
			description: description,
			value: value,
			increment: increment,
			maxValue: maxValue,
			minValue: minValue
		};

		setRequiredDimensionsValues((prev) => {
			const updatedValues = [...prev];
			updatedValues[index] = requiredDimensions;
			return updatedValues;
		});
	};

	return (
		<form
			onSubmit={(event) => {
				event.stopPropagation();
				handleSubmit(handleSaveAction)(event);
			}}
			noValidate
		>
			<EditLineItemHeaderContainer>
				<div data-testId="new-order-draft-table-line-header">{EditLineItemHeader}</div>
				<EditLineItemHeaderRow>
					<Header
						description={selectedEditItem?.current?.description ?? ""}
						key={`${selectedEditItem?.current?.sku}-${selectedEditItem?.current?.lineItemNumber}-header`}
						header={selectedEditItem.current?.sku ?? ""}
						lineNumber={selectedEditItem.current?.lineItemNumber ?? ""}
					/>

					<div>
						<span>{EditProductLineLabel}</span>
						<span data-testId="edit-line-item-description">{description}</span>
					</div>

					<Controller
						name="productSelectQuantity"
						control={control}
						render={({ field: { onChange, value = null } }) => (
							<AutoComplete
								value={value}
								required
								disableClearable
								onChange={(_event, newValue) => {
									onChange(newValue);
								}}
								onMouseDownCapture={handleMouseDownCapture}
								label={AddLineItemQuantityPlaceholder}
								dataTestId="edit-line-item-quantity"
								options={quantity}
								isError={false}
								errorText=""
							/>
						)}
					/>
				</EditLineItemHeaderRow>

				<EditAddModificationGlobalAttributesWrapper>
					<FormControl
						size="small"
						fullWidth
					>
						<ReplacementReasonAndProblemInputLabel>
							{ChangeGlobalAttributeGroup}
						</ReplacementReasonAndProblemInputLabel>
						<Controller
							name="globalAttributeGroup"
							control={control}
							render={({ field: { onChange, value = null } }) => (
								<Select
									label={ChangeGlobalAttributeGroup}
									data-testid="edit-item-select"
									onChange={onChange}
									value={value ?? ""}
									renderValue={(index: number) => (
										<div className={styles.replacementProblemDropdownOption}>
											<span>
												{group.configurations[index] &&
													getAttributes(group.configurations[index])}
											</span>
										</div>
									)}
								>
									{generateMenuItems()}
								</Select>
							)}
						/>
					</FormControl>
				</EditAddModificationGlobalAttributesWrapper>

				{standardDimensions && (
					<AddLineItemStandardContainer>
						<div data-testId="edit-line-item-standard-dimensions-header">
							{AddLineItemStandardDimensionsHeader}
						</div>

						<AddLineItemStandardDimensions isAddLineItem={isAddLineItem}>
							{selectedEditItem.current?.standardWidth && (
								<div data-testId="edit-line-item-standard-dimensions-width">
									<span>{AddLineWidthLabel}</span>
									<span>{`${selectedEditItem.current?.standardWidth}"`}</span>
								</div>
							)}

							{selectedEditItem.current?.standardDepth && (
								<div data-testId="edit-line-item-standard-dimensions-depth">
									<span>{AddLineDepthLabel}</span>
									<span>{`${selectedEditItem.current?.standardDepth}"`}</span>
								</div>
							)}

							{selectedEditItem.current?.standardHeight && (
								<div data-testId="edit-line-item-standard-dimensions-height">
									<span>{AddLineHeightLabel}</span>
									<span>{`${selectedEditItem.current?.standardHeight}"`}</span>
								</div>
							)}
						</AddLineItemStandardDimensions>
					</AddLineItemStandardContainer>
				)}

				{selectedEditItem.current?.modifications?.length !== 0 && (
					<EditAddModificationWrapper>
						<AddModification
							multipleRanges={false}
							saveButtonClick={saveModButtonClick}
							onValidation={handleChildValidation}
							onModificationChange={handleChildData}
							foundSearch={undefined}
							selectedEditItem={selectedEditItem}
							configuration={group}
						/>
					</EditAddModificationWrapper>
				)}

				{selectedEditItem.current?.requiredDimensions?.length !== 0 && (
					<>
						<h6 data-testid="edit-line-item-dimension-header">{SelectRequiredDimensions}</h6>

						<DimensionContainer>
							{selectedEditItem?.current?.requiredDimensions?.map((dimension: any, dimensionIndex) => {
								return (
									dimension && (
										<div>
											<AutoComplete
												options={calculateIntervals(
													dimension.minValue,
													dimension.maxValue,
													dimension.increment
												)}
												defaultValue={dimension.value}
												onMouseDownCapture={(e: SyntheticEvent) => {
													if (
														e.target instanceof HTMLElement &&
														e.target.nodeName === "INPUT"
													) {
														e.stopPropagation();
													}
												}}
												onChange={(_, value) =>
													handleDimensionChange(
														dimension.id,
														value,
														dimension.description,
														dimension.increment,
														dimension.maxValue,
														dimension.minValue,
														dimensionIndex
													)
												}
												isLoading={false}
												label={dimension.description ?? ""}
												dataTestId="add-line-item-dimensions"
												getOptionLabel={(option) => String(option) + '"'}
												required
												isError={false}
												errorText={RequiredDimensionError}
												disableClearable
											/>

											<RequiredDimensionsValues data-testid="add-line-item-dimension-mix-max">
												{`Min: ${dimension.minValue}", Max: ${dimension.maxValue}"`}
											</RequiredDimensionsValues>
										</div>
									)
								);
							})}
						</DimensionContainer>
					</>
				)}

				<AddLineItemButtonWrapper isAddLineItem={isAddLineItem}>
					<div>
						<Button
							data-testId="add-line-item-cancel-button"
							variant="text"
							onClick={() => addEditVisibility()}
						>
							{AddLineCancelButton}
						</Button>
					</div>

					<div>
						<Button
							data-testId="add-line-item-save-button"
							variant="contained"
							type="submit"
						>
							{AddLineSaveButton}
						</Button>
					</div>
				</AddLineItemButtonWrapper>
			</EditLineItemHeaderContainer>
		</form>
	);
};
export default EditLineItem;
