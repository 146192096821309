import { addBusinessDays, format, isAfter, isBefore, startOfDay } from "date-fns";

export function formatDateToMMDDYYYY(date: Date): string {
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	const year = String(date.getFullYear());

	return `${month}/${day}/${year}`;
}

export function formatDateToYYYYMMDD(date: Date) {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");

	return `${year}-${month}-${day}`;
}

export function getPriorDateByDaysOffset(initialDate: Date, daysOffset: number = 90): Date {
	const millisecondsInADay = 86400000;
	const millisecondsInOffsetDays = daysOffset * millisecondsInADay;
	const priorDayTimestamp = initialDate.getTime() - millisecondsInOffsetDays;

	return new Date(priorDayTimestamp);
}

export function getLaterDateByDaysOffset(initialDate: Date, daysOffset: number = 90): Date {
	const millisecondsInADay = 86400000;
	const millisecondsInOffsetDays = daysOffset * millisecondsInADay;
	const priorDayTimestamp = initialDate.getTime() + millisecondsInOffsetDays;

	return new Date(priorDayTimestamp);
}

export function isAfterDate(date1: Date, date2: Date): boolean {
	return date1.getTime() > date2.getTime();
}

export function isBeforeDate(date1: Date, date2: Date): boolean {
	return date1.getTime() < date2.getTime();
}

export function areEqual(date1: Date, date2: Date): boolean {
	return date1.getTime() === date2.getTime();
}

export const dateFormatTwoDigitDays = (orderDate: string) => {
	if (orderDate) {
		return format(orderDate, "MM/dd/yyyy");
	}
	return null;
};

export const dateFormat = (orderDate: string) => {
	if (orderDate) {
		return new Date(orderDate).toLocaleDateString(undefined);
	}
	return null;
};

export const isValidDate = (date: Date) => {
	if (isNaN(date.getTime())) {
		return false;
	}
	const currentDate = new Date();
	const twentyYearsAgo = new Date();
	twentyYearsAgo.setFullYear(currentDate.getFullYear() - 20);
	return date > twentyYearsAgo;
};

export const formatDateWithTimeZone = (date: Date) => {
	const options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		hour12: true,
		timeZoneName: "short"
	};

	const formatter = new Intl.DateTimeFormat("en-US", options);

	const newDate = formatter
		.format(date)
		.replace(/\//g, "-")
		.replace(",", "")
		.replace("EST", "Eastern")
		.replace("CST", "Central")
		.replace("MSL", "Mountain")
		.replace("PST", "Pacific");

	return newDate;
};

export const isDateWithinBusinessDayRange = (targetDate: Date, start: number, end: number): boolean => {
	const today = startOfDay(new Date());

	const normalizedTargetDate = startOfDay(targetDate);

	const rangeStartDate = addBusinessDays(today, start);

	const rangeEndDate = addBusinessDays(today, end);

	const isAfterRangeStart = !isBefore(normalizedTargetDate, rangeStartDate);

	const isBeforeRangeEnd = !isAfter(normalizedTargetDate, rangeEndDate);

	return isAfterRangeStart && isBeforeRangeEnd;
};
