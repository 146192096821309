import styled from "@emotion/styled";
import { LoadingSkeletonProps } from "../Common/LineItemGrid/LineItemGridStyles";

export const ErrorBannerStyles = styled.div<LoadingSkeletonProps>(({ isLoading, theme }) => ({
	display: isLoading ? "none" : "flex",
	padding: "1rem",
	backgroundColor: "var(--error-background)",
	margin: "1rem 0",
	color: "var(--red-700)",
	h1: {
		...theme.project.typography.body.body1,
		fontWeight: 500,
		marginTop: "0.25rem",
		marginBottom: "0.25rem"
	},
	svg: {
		marginRight: "0.5rem"
	},
	"p, li": {
		...theme.project.typography.body.body2,
		fontFamily: "Gibson Regular, san-serif",
		fontSize: "var(--font-size-3)",
		marginTop: "0.25rem",
		marginLeft: "2rem"
	}
}));

export const ReviewNewOrderErrorBannerStyles = styled(ErrorBannerStyles)(({ theme }) => ({
	div: {
		display: "flex",
		flexDirection: "row",
		h6: {
			margin: "8px 0 4px",
			fontSize: 16
		},
		"div:first-of-type": {
			svg: {
				width: 22,
				height: 22,
				margin: "7px 12px 7px 0"
			}
		},
		"div:nth-of-type(2)": {
			flexDirection: "column",
			letterSpacing: ".17px",
			ul: {
				margin: 0,
				padding: 0,
				lineHeight: "20px",
				li: {
					marginTop: 0,
					marginLeft: 24,
					lineHeight: "20px"
				}
			},
			span: {
				fontSize: 14
			},
			"span:nth-of-type(2)": {
				marginTop: 20
			},
			"span:last-of-type": {
				margin: "4px 0 8px",
				fontSize: 12,
				color: theme.palette.error.light,
				lineHeight: "20px",
				letterSpacing: ".4px"
			}
		}
	},
	padding: "6px 16px"
}));
