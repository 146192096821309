import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { msalInstance } from "auth/authConfig.tsx";

const acquireAccessToken = async () => {
	// This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
	const activeAccount = msalInstance.getActiveAccount();
	const accounts = msalInstance.getAllAccounts();

	// Redirect to log in page when invalid account data is used
	if (!activeAccount && accounts.length === 0) {
		await msalInstance.loginRedirect();
		return;
	}

	const request = {
		scopes: [import.meta.env.VITE_APP_USE_B2C_ACCOUNT_ACCESS_TOKEN_REQUEST_SCOPES],
		account: activeAccount || accounts[0]
	};

	try {
		const authResult = await msalInstance.acquireTokenSilent(request);
		return authResult.accessToken;
	} catch (err) {
		const urlParams = new URLSearchParams(window.location.search);
		const reloaded = urlParams.get("reloaded");

		console.error(err);

		if (err instanceof Error && err.stack?.includes("BrowserAuthError")) {
			window.location.href = "/unauthorized";
			return;
		}

		await msalInstance.loginRedirect().catch((logoutErr) => {
			console.error(logoutErr);

			// If a user gets here and we've already reloaded, we can safely let the ErrorBoundary handle it
			if (reloaded === "true") {
				console.error("Auth Error: Retry failed.");
				window.location.href = "/unauthorized";
			} else {
				window.location.href = "/?reloaded=true";
			}
		});
	}

	return "";
};

const baseQuery = (baseUrl: string) =>
	fetchBaseQuery({
		baseUrl: baseUrl,
		prepareHeaders: async (headers) => {
			const accessToken = await acquireAccessToken();
			headers.set("Authorization", `Bearer ${accessToken}`);

			return headers;
		}
	});

export type APIErrorResponse = {
	errors?: Array<{
		code: number;
		message: string;
	}>;
	status?: string;
};

export const isApiErrorResponse = (response: any): response is APIErrorResponse => {
	return "errors" in response && Array.isArray(response.errors);
};

export type UntransformedResponse<T> = { data: T; status: number };

export const baseQueryWithRedirect = (baseUrl: string) => async (args: any, api: any, extraOptions: any) => {
	const result = await baseQuery(baseUrl)(args, api, extraOptions);

	if (result.error && "status" in result.error) {
		const errorResp = result?.error?.data as APIErrorResponse;

		const primaryStatus = Number(result.error.status);
		const secondaryStatus = errorResp?.errors && errorResp.errors?.length > 0 ? errorResp.errors[0]?.code : 0;

		if (primaryStatus === 401) window.location.href = "/unauthorized";
		else if (primaryStatus === 403 && secondaryStatus === 4031) window.location.href = "/unapproved/pending";
		else if (primaryStatus === 403 && secondaryStatus === 4032) window.location.href = "/unapproved/denied";
		else if (isNaN(primaryStatus) || primaryStatus === 500) window.location.href = "/unavailable";
		else if (isNaN(primaryStatus) || primaryStatus >= 503) window.location.href = "/unavailable";
		else if ((isNaN(primaryStatus) || primaryStatus === 502) && !extraOptions.handle502Error)
			window.location.href = "/unavailable";
	}
	return result;
};
