import { number, object, ObjectSchema, string } from "yup";
import { ProductSelectFormError } from "./constants.ts";

export interface FormSchema {
	productSelect?: string | null;
	productSelectQuantity: number | undefined;
	editModificationSelect: string | undefined;
}
export const addLineItemSchemaObject = {
	productSelect: string().required(ProductSelectFormError),
	productSelectQuantity: number(),
	editModificationSelect: string()
} as const;

export const schema: ObjectSchema<FormSchema> = object(addLineItemSchemaObject);
