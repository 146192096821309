import { useEffect } from "react";
import useSession from "./useSession";
import { useGetByIdQuery } from "features/api/orderApi.ts";

export const useInitOrderDetail = (orderId: string | undefined) => {
	const [previousOrderId, setPreviousOrderId] = useSession("_cabinet-previous-id", "");

	const { data, isLoading, error } = useGetByIdQuery(orderId ?? "", { skip: !orderId });

	useEffect(() => {
		if (orderId !== undefined) setPreviousOrderId(orderId);
	}, [orderId, setPreviousOrderId]);

	return {
		orderDetail: data,
		isLoading: orderId ? isLoading : false,
		orderId,
		previousOrderId,
		error: orderId ? error : undefined
	};
};

export default useInitOrderDetail;
