import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	windowDimension: 0,
	isMobile: false
};

const windowSlice = createSlice({
	name: "window",
	initialState,
	reducers: {
		setWindowDimension: (state, action) => {
			state.windowDimension = action.payload;
			state.isMobile = action.payload <= 1200;
		}
	}
});

export const actions = windowSlice.actions;
export default windowSlice.reducer;
