import { UnknownAction } from "@reduxjs/toolkit";
import { UnavailableDataPlaceholderText } from "constants/text";
import { OrderDetailViewModel, OrderType } from "data/api/v1";
import { submittingNewOrderActions } from "features/reducers/newOrder/submittingNewOrder.ts";
import { Dispatch } from "react";
import { arrayGroupBy } from "utils/array";
import { v4 as uuidv4 } from "uuid";
import { models } from "types/api/viewModels.ts";
import { PostOrdersRequestBody } from "types/api/orders/postOrders.ts";

export const GetBackorderCount = (orderDetail: OrderDetailViewModel | null) => {
	const lineItemsOnBackorder = orderDetail?.lineItems?.filter(
		(order) =>
			order?.partInfo?.partsQuantityBackordered !== undefined && order?.partInfo?.partsQuantityBackordered > 0
	);
	const lineItemsCabinetOnBackorder = orderDetail?.lineItems?.filter(
		(order) =>
			order.quantityBackordered !== undefined &&
			order.quantityBackordered !== null &&
			order.quantityBackordered > 0
	);
	const hasCabinetBackorder = lineItemsCabinetOnBackorder?.map((e) => e?.quantityBackordered)?.length;
	const hasPartBackorder = lineItemsOnBackorder?.map((e) => e?.partInfo?.partsQuantityBackordered)?.length;
	return Number(
		hasPartBackorder !== undefined && hasCabinetBackorder !== undefined && hasPartBackorder + hasCabinetBackorder
	);
};

export const PartsQuantityBackorderedLength = (orderDetail: OrderDetailViewModel) => {
	const lineItemsOnBackorder = orderDetail?.lineItems?.filter(
		(order) =>
			order?.partInfo?.partsQuantityBackordered !== undefined && order?.partInfo?.partsQuantityBackordered > 0
	);
	return lineItemsOnBackorder?.map((e) => e?.partInfo?.partsQuantityBackordered)?.length;
};

export const BackorderedCabinetCount = (orderDetail: OrderDetailViewModel | null) => {
	const lineItemsCabinetOnBackorder = orderDetail?.lineItems?.filter(
		(order) =>
			order.quantityBackordered !== undefined &&
			order.quantityBackordered !== null &&
			order.quantityBackordered > 0
	);

	return lineItemsCabinetOnBackorder?.map((e) => e?.quantityBackordered)?.length;
};

export const GroupAllTrackingNumbers = (orderDetail: OrderDetailViewModel): string[] => {
	const lineItemsMap = orderDetail?.lineItems?.map ? orderDetail.lineItems.map((e) => e) : [];

	const groupAllByTrackingNumber = arrayGroupBy(
		lineItemsMap,
		(product: { trackingNumber: string | null; truckNumber: string | null }) => {
			return product.trackingNumber?.length && !product.truckNumber
				? product.trackingNumber
				: !product.trackingNumber;
		}
	);

	return [...(groupAllByTrackingNumber as any)].map((e) => (e[0]?.length > 1 ? e : []));
};

export const GroupAllTruckNumbers = (orderDetail: OrderDetailViewModel | null) => {
	const lineItemsMap = orderDetail?.lineItems?.map ? orderDetail.lineItems.map((e) => e) : [];
	const groupAllByTruckNumber = arrayGroupBy(
		lineItemsMap,
		(product: { trackingNumber: string | null; truckNumber: string | null }) => {
			return product.truckNumber?.length && !product.trackingNumber ? product.truckNumber : !product.truckNumber;
		}
	);

	return [...(groupAllByTruckNumber as any)].map((e) => (e[0]?.length > 1 ? e : []));
};

export const GroupTrackingAndTruckingNumbers = (orderDetail: OrderDetailViewModel | null) => {
	const lineItemsMap = orderDetail?.lineItems?.map ? orderDetail.lineItems.map((e) => e) : [];

	const trackingAndTrucking = arrayGroupBy(
		lineItemsMap,
		(product: { trackingNumber: string | null; truckNumber: string | null }) => {
			return product.trackingNumber && product.truckNumber;
		}
	);

	return [...(trackingAndTrucking as any)].map((e) => (e[0]?.length > 1 ? e : []));
};

export const GetNormalLineItems = (orderDetail: OrderDetailViewModel | null) => {
	const lineItemsMap = orderDetail?.lineItems?.map ? orderDetail.lineItems.map((e) => e) : [];

	return arrayGroupBy(lineItemsMap, (product: { trackingNumber: string | null; truckNumber: string | null }) => {
		return (
			(!product.trackingNumber && !product.truckNumber) ||
			product.trackingNumber === null ||
			product.truckNumber === null
		);
	});
};

export const updateOrderDetails = (
	configurations: models["PendingOrderConfigurationViewModel"][] | null | undefined,
	dispatch: Dispatch<UnknownAction>,
	draftOrder?: models["DraftOrderViewModel"],
	isAddOn?: boolean,
	originalOrderId?: string
) => {
	const tandemOrderId = uuidv4();

	const submittingOrder: PostOrdersRequestBody = {
		tandemOrderId: tandemOrderId,
		jobName: draftOrder?.jobName ?? null,
		poNumber: draftOrder?.poNumber ?? null,
		orderType: isAddOn ? OrderType.ADDON : OrderType.ORIGINAL,
		designerNumber: draftOrder?.designerNumber ?? null,
		labelComments: draftOrder?.labelComments ?? null,
		requestedDeliveryDate: draftOrder?.requestedDeliveryDate ?? null,
		originalOrderId: originalOrderId ?? null,
		shipToId: draftOrder?.shipToId ?? null,
		isCustomerPickup: draftOrder?.isCustomerPickup ?? false,
		shipToAddress: draftOrder?.shipToAddress ?? {},
		configurations
	};
	const draft: models["DraftOrderViewModel"] = {
		...submittingOrder,
		draftOrderId: draftOrder?.draftOrderId,
		draftName: draftOrder?.draftName,
		designerName: draftOrder?.designerName ?? UnavailableDataPlaceholderText,
		type: draftOrder?.type
	};

	dispatch(submittingNewOrderActions.updateDraftOrder(draft));
	dispatch(submittingNewOrderActions.submittingNewOrder(submittingOrder));
};
