import styled from "@emotion/styled";

export const StyledOrderDetailsShipmentHeaderContainer = styled.div(({ theme }) => ({
	padding: "0 16px",
	display: "grid",
	marginTop: 8,
	gridTemplateColumns: "repeat(5, 1fr)",
	[theme.breakpoints.down("lg")]: {
		display: "block"
	},
	span: {
		"&:first-child": {
			...theme.project.typography.subtitle.subtitle2
		},
		"&:nth-child(2)": {
			...theme.project.typography.body.body2
		}
	}
}));
