import { formatBytes, getFileType } from "utils/file";
import { PictureAsPdf, UploadViewWrapper } from "components/UploadView/uploadView.styles";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { ReplacementFile } from "features/reducers/replacementOrder/cart.ts";

export interface Props {
	index: number;
	file: ReplacementFile;
	onImageRemove: (index: number) => void;
}

const UploadView = ({ index, file, onImageRemove }: Props) => {
	return (
		<UploadViewWrapper
			key={index}
			data-testid="upload-file-view"
		>
			<div>
				{getFileType(file.name) === "pdf" ? (
					<PictureAsPdf />
				) : (
					<img
						src={file.fileUrl}
						alt="uploaded file"
					/>
				)}
				<div>
					<p>{file.name}</p>
					<p>{formatBytes(file.size)}</p>
				</div>
			</div>
			<button
				onClick={() => onImageRemove(index)}
				data-testid="upload-file-view-remove-button"
			>
				<CloseOutlined />
			</button>
		</UploadViewWrapper>
	);
};
export default UploadView;
