export const arrayGroupBy = (array: any, getKey: any) => {
	const map = new Map();
	array.forEach((eachItem: any) => {
		const key = getKey(eachItem);
		const groupedCollection = map.get(key);
		if (!groupedCollection) {
			map.set(key, [eachItem]);
		} else {
			groupedCollection.push(eachItem);
		}
	});
	return map;
};

export function arraysEqual(a: string[], b: string[]): boolean {
	if (a.length !== b.length) {
		return false;
	}

	let sortedA = [...a].sort((a, b) => a.localeCompare(b));
	let sortedB = [...b].sort((a, b) => a.localeCompare(b));

	for (let i = 0; i < sortedA.length; i++) {
		if (sortedA[i] !== sortedB[i]) {
			return false;
		}
	}

	return true;
}

export const stringArraySortAlphabetically = (arr: string[] | undefined): string[] | undefined => {
	return arr?.sort((a, b) => {
		const isANumber = /^\d/.exec(a);
		const isBNumber = /^\d/.exec(b);
		if (isANumber && isBNumber) {
			const numA = parseInt(isANumber[0] || "0");
			const numB = parseInt(isBNumber[0] || "0");
			return numA - numB;
		}
		if (isANumber) return 1;
		if (isBNumber) return -1;
		return a.localeCompare(b);
	});
};
