import { Skeleton, Tab, Tabs } from "@mui/material";
import {
	SaveDraftExteriorTableHeader,
	SaveDraftTableDisclaimer,
	SaveDraftTableSkeletonContainer,
	SaveDraftTableSkeletonRow
} from "./SaveDraftTable.styles";
import { SaveDraftDraftTab, SaveDraftTableDisclaimerText, SaveDraftTemplateTab } from "../../constants";

const SaveDraftTableSkeleton = () => {
	return (
		<SaveDraftTableSkeletonContainer data-testid="new-order-save-draft-table-skeleton">
			<SaveDraftExteriorTableHeader>
				<Tabs aria-label="Save draft table tabs">
					<Tab
						data-testid="new-order-save-draft-draft-tab"
						label={SaveDraftDraftTab}
					/>
					<Tab
						data-testid="new-order-save-draft-template-tab"
						label={SaveDraftTemplateTab}
					/>
				</Tabs>
			</SaveDraftExteriorTableHeader>

			<SaveDraftTableDisclaimer>{SaveDraftTableDisclaimerText}</SaveDraftTableDisclaimer>
			<Skeleton height={43} />
			{[...Array(10)].map((index) => (
				<SaveDraftTableSkeletonRow key={index}>
					<Skeleton height={43} />
					<Skeleton height={43} />
					<Skeleton height={43} />
					<Skeleton height={43} />
					<Skeleton height={43} />
					<div>
						<Skeleton height={17} />
						<Skeleton height={17} />
					</div>
				</SaveDraftTableSkeletonRow>
			))}
		</SaveDraftTableSkeletonContainer>
	);
};

export default SaveDraftTableSkeleton;
