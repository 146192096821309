import { Button, Grid } from "@mui/material";
import { useState } from "react";
import SelectReplacementReason from "./SelectReplacementReason";
import styles from "../../pages/replacements-page-styles.module.css";
import {
	RequiredFieldsText,
	BackText,
	ContinueText,
	PleaseDescribeTheProblemReplacementText,
	ReplacementReasonText
} from "constants/text";
import { OrderDetailViewModel } from "data/api/v1";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { useNavigate } from "react-router-dom";
import { ReplacementCartItem } from "features/reducers/replacementOrder/cart.ts";
import ReplacementReasonsHeaderContainer from "./ReplacementReasonsHeaderContainer";

export interface ReplacementReasonProp {
	orderDetail: OrderDetailViewModel | null;
}

const ReplacementReason = ({ orderDetail }: ReplacementReasonProp) => {
	const navigateTo = useNavigate();
	const cartItems = useSelector((state: RootState) => state.cart.replacements);
	const [reasonSelectionError, setReasonSelectionError] = useState(false);

	const sortedItems: ReplacementCartItem[] = useSelector((state: RootState) =>
		[...state.cart.replacements].sort((a, b) => a.lineItemNumber - b.lineItemNumber)
	);

	const verifyErrorHandling = () => {
		const hasError = cartItems.some((item: ReplacementCartItem) => {
			const partsToBeReplaced = item.replaceableParts.filter(
				(part: { partQuantity: number }) => part.partQuantity !== 0
			);

			let partWithError = false;
			let cabinetWithError = false;

			if (partsToBeReplaced.length > 0) {
				partWithError = partsToBeReplaced.some(
					(data) => !data.partReplacementProblem || !data.partReplacementReason
				);
			}

			if (item.wholeCabinetQuantity > 0) {
				cabinetWithError = !item.cabinetReplacementProblem || !item.cabinetReplacementReason;
			}

			return partWithError || cabinetWithError;
		});

		setReasonSelectionError(hasError);
		return !hasError;
	};
	const handleSubmitReplacementReasons = () => {
		if (verifyErrorHandling()) {
			navigateTo(`/replacements/review/${orderDetail?.orderId}`);
		}
	};
	return (
		<div>
			<Grid
				item
				className={styles.replacementReasonHeader}
			>
				<h1 data-testid="replacementReason-header">{ReplacementReasonText}</h1>
			</Grid>
			<Grid
				data-testid="replacementReason-tellUsWhyContainer"
				className={styles.tellUsWhyContainer}
			>
				<div className={styles.tellUsWhySubheaders}>
					<Grid
						item
						xs={12}
					>
						<h2
							style={{ marginBottom: 0 }}
							data-testid="replacementReason-subheader"
						>
							{" "}
							{PleaseDescribeTheProblemReplacementText}{" "}
						</h2>
					</Grid>
					<Grid
						item
						data-testid="replacementReason-allFieldsRequired"
					>
						<span className="body1">{RequiredFieldsText}</span>
					</Grid>
				</div>
				{sortedItems.map(
					(item: ReplacementCartItem) =>
						item && (
							<div
								key={item.id}
								className={styles.selectReplacementReasonContainer}
							>
								<ReplacementReasonsHeaderContainer item={item} />
								{item.wholeCabinetQuantity > 0 && (
									<Grid
										container
										data-testid="replacementReason-selectReplacementReasonComponent"
									>
										<Grid
											item
											xs={12}
										>
											<SelectReplacementReason
												item={item}
												reasonSelectionError={reasonSelectionError}
												setReasonSelectionError={setReasonSelectionError}
											/>
										</Grid>
									</Grid>
								)}
								<>
									{item.replaceableParts?.map(
										(part) =>
											part.partQuantity > 0 && (
												<SelectReplacementReason
													key={part.id}
													part={part}
													item={item}
													reasonSelectionError={reasonSelectionError}
													setReasonSelectionError={setReasonSelectionError}
												/>
											)
									)}
								</>
							</div>
						)
				)}
			</Grid>
			<Grid
				container
				alignItems="center"
				justifyContent="flex-end"
				className={styles.replacementReasonButtonContainer}
			>
				<Grid
					item
					order={{ xs: 2, sm: 1 }}
					xs={12}
					sm={2}
					md={1}
					alignItems="center"
					className={styles.backButton}
				>
					<Button
						variant="text"
						sx={{ textTransform: "uppercase", fontFamily: "Gibson Medium, sans-serif" }}
						data-testid="replacementReason-backButton"
						href={`/replacements/${orderDetail?.orderId}`}
					>
						{BackText}
					</Button>
				</Grid>
				<Grid
					item
					order={{ xs: 1, sm: 2 }}
					xs={12}
					sm={4}
					md={3}
				>
					<Button
						onClick={handleSubmitReplacementReasons}
						fullWidth
						variant="contained"
						data-testid="replacementReason-continueButton"
						data-id="replacementReason-continueButton"
					>
						{ContinueText}
					</Button>
				</Grid>
			</Grid>
		</div>
	);
};

export default ReplacementReason;
