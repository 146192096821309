import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { APIErrorResponse } from "features/api/utils/apiUtils.ts";
import { ErrorBannerStyles } from "./ErrorBanner.styles.ts";
import { NewOrderPleaseSubmitRequestText, SubmitTandemRequestText } from "../../../constants/text.ts";
import OpenInNew from "@mui/icons-material/OpenInNew";
import Link from "@mui/material/Link";

export interface ErrorBannerProps {
	apiErrorResponse?: APIErrorResponse;
	bannerType: string;
	bannerHeader: string;
	bannerSubheader: string;
	bannerBullets?: string[];
	bannerUnderBulletText?: string;
	configurationErrorMessage?: string | null;
	isLoading?: boolean;
}
const ErrorBanner = ({
	apiErrorResponse,
	isLoading,
	bannerHeader,
	bannerSubheader,
	bannerType,
	bannerBullets,
	bannerUnderBulletText,
	configurationErrorMessage
}: ErrorBannerProps) => {
	return (
		<ErrorBannerStyles isLoading={isLoading}>
			<ErrorOutline />
			<div>
				<h1>{bannerHeader}</h1>
				<div>{bannerSubheader}</div>
				<ul>{bannerBullets?.map((bullet) => <li key={bullet}>{bullet}</li>)}</ul>
				<ul>
					{bannerType === "NewOrder400ErrorBanner" &&
						apiErrorResponse?.errors?.map((response: any, index: number) => (
							<li key={index}>{response.message}</li>
						))}
				</ul>

				{bannerUnderBulletText && <div>{bannerUnderBulletText}</div>}
				{bannerType === "NewOrder502ErrorBanner" && (
					<div>
						{NewOrderPleaseSubmitRequestText}&nbsp;
						<Link
							href="https://tandem.cabinetworksgroup.com/s/tandem-support"
							target="_blank"
							rel="noreferrer"
						>
							{SubmitTandemRequestText}
						</Link>
						.
						<Link>
							<OpenInNew />
						</Link>
					</div>
				)}
				{apiErrorResponse?.errors?.map((response: any, index: number) => (
					<div key={index}>
						({response.code} {response.message})
					</div>
				))}
				{configurationErrorMessage && bannerType === "NewOrderConfigurationErrorBanner" && (
					<div>({configurationErrorMessage})</div>
				)}
			</div>
		</ErrorBannerStyles>
	);
};

export default ErrorBanner;
