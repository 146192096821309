import styled from "@emotion/styled";

export const ConfirmationThanksContainer = styled.div(({ theme }) => ({
	backgroundColor: theme.project.colors.backgroundColors.main,
	marginBottom: 16,
	"> div:nth-of-type(2)": {
		padding: "0 24px 24px 24px",
		marginTop: 80,
		"div:first-of-type": {
			...theme.project.typography.body.body1,
			marginBottom: 16,
			color: theme.project.colors.fontColors.secondary
		}
	},
	[theme.breakpoints.down("md")]: {
		"> div:nth-of-type(2)": {
			marginTop: 0
		}
	}
}));

export const ConfirmationThanksHeader = styled.div(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "9fr 3fr",
	"div:nth-of-type(1)": {
		color: theme.project.colors.fontColors.main,
		fontSize: 24
	},
	"div:nth-of-type(2)": {
		display: "flex",
		...theme.project.typography.subtitle.subtitle2,
		color: theme.project.colors.fontColors.secondary,
		textTransform: "uppercase",
		justifyContent: "flex-end",
		svg: {
			marginTop: "-5px",
			marginLeft: 5
		}
	},
	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "1fr",
		"div:nth-of-type(2)": {
			justifyContent: "flex-start",
			marginTop: 16
		}
	}
}));
