import styled from "@emotion/styled";
import { Button } from "@mui/material";

interface AddLineItemStylesProps {
	isAddLineItem?: boolean;
}

export const AddItemButton = styled(Button)({
	display: "flex",
	gap: "8px",
	fontWeight: 500,
	marginBottom: 16,
	marginLeft: 16,
	padding: "6px 16px",
	verticalAlign: "middle",
	letterSpacing: ".4px",
	lineHeight: "24px",
	textTransform: "uppercase"
});

export const AddLineItemContainer = styled.div(({ theme }) => ({
	border: "1px solid",
	borderColor: theme.project.colors.primaryColors.main,
	display: "flex",
	flexDirection: "column",
	gap: 16,
	margin: "4px 16px 24px 16px",
	padding: 16,
	div: {
		"&:first-child": {
			...theme.project.typography.heading.h6
		},
		"&:nth-child(2)": {
			...theme.project.typography.caption
		}
	},
	h6: {
		margin: 0
	}
}));

export const AddLineItemInputFormControl = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	gap: 16,
	div: {
		"&:first-child": {
			flexDirection: "column",
			flex: "130%",
			".MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input": {
				fontSize: 16
			}
		}
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column"
	}
}));

export const AddLineItemButtonWrapper = styled.div<AddLineItemStylesProps>(({ isAddLineItem, theme }) => ({
	...theme.project.typography.caption,
	display: "flex",
	justifyContent: "flex-end",
	gap: 20,
	div: {
		display: "flex",
		flexDirection: "column",
		gap: 8,
		"&:nth-of-type(1)": {
			button: {
				padding: "10px 22px"
			}
		},
		"&:nth-of-type(2)": {
			button: {
				padding: "8px 22px",
				width: isAddLineItem ? "auto" : "340px"
			},
			textAlign: "center"
		},
		"&:nth-of-type(3)": {
			button: {
				padding: "9px 22px" // padding adjusted to account for not having a border
			},
			textAlign: "center"
		},
		[theme.breakpoints.down("md")]: {
			div: {
				display: "none"
			}
		}
	},
	[theme.breakpoints.down("md")]: {
		flexWrap: "wrap",
		div: {
			"&:nth-of-type(1)": {
				order: 3,
				flex: "0 0 100%"
			},
			"&:nth-of-type(2)": {
				order: 2,
				flex: "0 0 100%",
				button: {
					marginTop: 16,
					width: "auto"
				}
			},
			"&:nth-of-type(3)": {
				order: 1,
				flex: "0 0 100%"
			}
		}
	}
}));

export const AddLineItemStandardContainer = styled.div(({ theme }) => ({
	div: {
		"&:first-child": {
			...theme.project.typography.subtitle.subtitle1
		}
	},
	display: "flex",
	flexDirection: "row",
	gap: 16,
	[theme.breakpoints.down("md")]: {
		display: "block"
	}
}));

export const AddLineItemStandardDimensions = styled.div<AddLineItemStylesProps>(({ isAddLineItem, theme }) => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "baseline",
	marginTop: isAddLineItem ? 0 : "-4px",
	gap: 16,
	div: {
		span: {
			"&:first-child": {
				...theme.project.typography.subtitle.subtitle2,
				textTransform: "none",
				marginRight: 8
			},
			"&:nth-child(2)": {
				...theme.project.typography.body.body2
			}
		}
	}
}));

export const AddLineItemRequiredDimensions = styled.div(({ theme }) => ({
	display: "flex",
	div: {
		...theme.project.typography.subtitle.subtitle2,
		marginRight: 12,
		"&:nth-child(3)": {
			...theme.project.typography.body.body2
		}
	},
	[theme.breakpoints.down("md")]: {
		display: "block"
	}
}));
