import { StyledFormHelperText } from "components/Form/HelperText/helperText.styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { FieldError } from "constants/text";
import { CharacterLimit } from "utils/form";
import { ReactNode } from "react";

interface HelperTextProps {
	characterLimit?: CharacterLimit & { isLimitError: boolean | undefined };
	isError: boolean;
	name?: string;
	text: string | ReactNode | undefined;
}
const HelperText = ({ characterLimit, isError, name, text }: HelperTextProps) => (
	<StyledFormHelperText
		id={name ? `${name}-helper-text` : undefined}
		data-testid={name ? `${name}-helper-text` : undefined}
		isLimitError={characterLimit?.isLimitError}
	>
		<span>
			{isError && (
				<ErrorOutlineIcon
					data-testid="helper-text-error-icon"
					aria-label={FieldError}
				/>
			)}
			{text}
		</span>
		{characterLimit && (
			<span>
				({String(characterLimit.currentLength)} / {characterLimit.limit})
			</span>
		)}
	</StyledFormHelperText>
);

export default HelperText;
