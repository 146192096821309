import { ConfirmationThanksContainer } from "./ConfirmationThanks.styles";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import { submittingNewOrderActions } from "features/reducers/newOrder/submittingNewOrder.ts";
import { useDispatch } from "react-redux";
import { shippingDetailsActions } from "features/reducers/newOrder/shippingDetails.ts";
import NewOrdersHeader from "components/NewOrders/NewOrdersHeader/NewOrdersHeader";

interface ConfirmationThanksProps {
	subheader: string;
	button: string;
}

const ConfirmationThanks = ({ subheader, button }: ConfirmationThanksProps) => {
	const dispatch = useDispatch();
	const navigateTo = useNavigate();
	const returnToOrders = () => {
		dispatch(newOrderActions.clearNewOrder());
		dispatch(submittingNewOrderActions.clearOrderToBeSubmitted());
		dispatch(shippingDetailsActions.clearShippingDetails());
		navigateTo("/");
	};

	return (
		<ConfirmationThanksContainer>
			<NewOrdersHeader
				includeDetails={false}
				includeStepper={false}
				isConfirmation
				isPDFExport
			/>

			<div>
				<div data-testid="new-order-confirmation-thank-you-subheader">{subheader}</div>

				<Button
					variant="contained"
					onClick={returnToOrders}
					data-testid="new-order-confirmation-details-button"
				>
					{button}
				</Button>
			</div>
		</ConfirmationThanksContainer>
	);
};

export default ConfirmationThanks;
