import { DialogTitle } from "../Dialog";
import {
	CSVUploadIsUpdating,
	HeaderTitleStyles,
	NewOrderModalDisclaimer,
	UploadCSVDragDropButton,
	UploadCSVDragDropUploadFile,
	UploadErrorContent,
	UploadErrorHeader,
	UploadErrorWaringText,
	UploadErrorWarning,
	UploadModalCSVFileAttachmentViewBytes,
	UploadModalCSVFileInfoContainer,
	UploadModalCSVFileInfoContainerButton
} from "./NewOrders.styles";
import {
	NewOrderContinueButton,
	NewOrderFileIncorrectTypeText,
	NewOrderImportFileError,
	NewOrderIncomingText,
	NewOrderModalDisclaimerText,
	NewOrderModalDragFile,
	NewOrderModalImportCSV,
	NewOrderModalLoadingText,
	NewOrderModalSupportedFiles,
	NewOrderSelectedFiles,
	NewOrderUnsupportedFile
} from "./constants";
import { Content } from "pages/Replacements/components/AddNewAddressModal/addNewAddressModal.styles";
import UploadFile from "@mui/icons-material/UploadFile";
import { Button, LinearProgress, Typography } from "@mui/material";
import { BrowseFiles, FileDuplicateText, SupportedFilesHeader } from "constants/text";
import Dragdrop from "../Dragdrop/Dragdrop";
import { formatBytes } from "utils/file";
import { useEffect, useState } from "react";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import { useUploadCSVMutation } from "features/api/newOrderApi.ts";
import styles from "pages/order-status-page-styles.module.css";
import { useDispatch } from "react-redux";
import { NewOrderModalTracking } from "data/api/v1";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useLazyGetAccountsQuery } from "features/api/accountApi.ts";

export type ErrorableFileState = {
	error: string;
	file: File;
};

interface CsvUploadProps {
	fileUploads?: File[];
	handleClose: () => void;
}

export const processFileUploadErrorStates = (
	file: File,
	maxMB: number,
	fileUploads: File[] = []
): string | undefined => {
	if (fileUploads.length > 0 && fileUploads.find((selectedFile: File) => selectedFile.name === file.name)) {
		return FileDuplicateText;
	}

	if (file.type !== "text/csv") {
		return NewOrderFileIncorrectTypeText;
	}

	return undefined;
};

const CSVUpload = ({ handleClose, fileUploads }: CsvUploadProps) => {
	const [error, setError] = useState<string | undefined>();
	const [csvFile, setCSVFile] = useState<File[]>([]);
	const [uploadNewOrderCSV, { isLoading: isUpdating, error: uploadError, reset }] = useUploadCSVMutation();
	const [getAccountByProductLine] = useLazyGetAccountsQuery();
	const dispatch = useDispatch();

	const uploadStatus = uploadError && "status" in uploadError ? uploadError.status === 400 : false;

	useEffect(() => {
		setError(undefined);
		setCSVFile([]);
	}, []);

	const updateFiles = (selectedFiles: Array<File>) => {
		reset();
		setCSVFile([]);
		setCSVFile(selectedFiles);
	};

	const submitCSV = async () => {
		for (const csvFileToPost of csvFile) {
			const formData = new FormData();
			formData.append("file", csvFileToPost);
			try {
				const importedCSVData = await uploadNewOrderCSV(formData).unwrap();
				const fetchedProductLineAccounts: string[] = [];

				dispatch(newOrderActions.addParsedCSV(importedCSVData));
				for (let configuration of importedCSVData?.configurations ?? []) {
					const productLineCode = configuration?.globals?.productLine?.code;
					if (productLineCode && !fetchedProductLineAccounts.includes(productLineCode)) {
						const accountInfo = await getAccountByProductLine(productLineCode).unwrap();
						dispatch(newOrderActions.addProductLineAccountInfo([productLineCode, accountInfo]));
						fetchedProductLineAccounts.push(productLineCode);
					}
				}

				dispatch(newOrderActions.setModalState(NewOrderModalTracking.SELECT_BILLTO_ACCOUNT));
			} catch (error: any) {
				console.error(error);
			}
		}
	};

	const onClose = () => {
		handleClose();
	};

	return (
		<div>
			<HeaderTitleStyles>
				<DialogTitle
					data-testid="new-order-csv-upload-title"
					title={NewOrderModalImportCSV}
					handleClose={onClose}
				/>
			</HeaderTitleStyles>

			<Content>
				<NewOrderModalDisclaimer data-testid="new-order-csv-upload-disclaimer">
					{!isUpdating ? <p>{NewOrderModalDisclaimerText}</p> : ""}
				</NewOrderModalDisclaimer>

				{isUpdating ? (
					<CSVUploadIsUpdating>
						<img
							src="/assets/ImportingCSVAnimation.png"
							alt="csv-upload-gif"
							className={styles.noServiceSVG}
							data-testid="new-order-csv-upload-loading-image"
						/>

						<DialogTitle
							data-testid="new-order-csv-upload-loading-disclaimer"
							title={NewOrderModalLoadingText}
						/>

						{csvFile.map((file) => (
							<Typography
								data-testid="new-order-csv-upload-file-text"
								variant="body1"
								key={file.name}
							>
								{file.name}
								<UploadModalCSVFileAttachmentViewBytes data-testid="new-order-csv-upload-file-size">
									{formatBytes(file.size)}
								</UploadModalCSVFileAttachmentViewBytes>
							</Typography>
						))}

						<div>
							<LinearProgress
								color="primary"
								variant="indeterminate"
							/>
						</div>
					</CSVUploadIsUpdating>
				) : (
					<Dragdrop
						accept="csv"
						onFileUpload={updateFiles}
					>
						<UploadCSVDragDropUploadFile>
							<UploadFile />
						</UploadCSVDragDropUploadFile>

						<div>
							<p data-testid="new-order-csv-upload-drag-text">{NewOrderModalDragFile}</p>

							<UploadCSVDragDropButton>
								<Button
									variant="outlined"
									data-testid="new-order-csv-upload-browse-button"
								>
									{BrowseFiles}
								</Button>
							</UploadCSVDragDropButton>

							<p data-testid="new-order-csv-upload-supported-file-text">
								{SupportedFilesHeader}{" "}
								<span style={{ fontWeight: 500 }}>{NewOrderModalSupportedFiles}</span>
							</p>
						</div>
					</Dragdrop>
				)}

				{csvFile.length > 0 && !uploadStatus && !isUpdating && (
					<div>
						<Typography
							variant="subtitle1"
							sx={{ textTransform: "uppercase", letterSpacing: "1px", marginTop: 1 }}
							data-testid="new-order-csv-upload-selected-file-text"
						>
							{NewOrderSelectedFiles}
						</Typography>
						{csvFile.map((file) => (
							<UploadModalCSVFileInfoContainer key={file.name}>
								<p data-testid="new-order-csv-upload-selected-file-name">
									{file.name}
									<UploadModalCSVFileAttachmentViewBytes data-testid="new-order-csv-upload-selected-file-size">
										{formatBytes(file.size)}
									</UploadModalCSVFileAttachmentViewBytes>
								</p>
							</UploadModalCSVFileInfoContainer>
						))}

						<UploadModalCSVFileInfoContainerButton>
							<Button
								data-testid="new-order-csv-upload-selected-file-continue-button"
								variant="contained"
								onClick={submitCSV}
							>
								{NewOrderContinueButton}
							</Button>
						</UploadModalCSVFileInfoContainerButton>
					</div>
				)}

				{(uploadStatus || csvFile.find((file) => file.size === 0)) && (
					<div>
						<UploadErrorHeader>
							<Typography data-testid="new-order-csv-upload-error-file-text">
								{uploadStatus ? NewOrderSelectedFiles : NewOrderIncomingText}
							</Typography>
						</UploadErrorHeader>

						<UploadErrorContent>
							{csvFile.map((file) => (
								<Typography
									data-testid="new-order-csv-upload-error-file-text"
									variant="body1"
									color="var(--red-700)"
									key={file.name}
								>
									{file.name}
									<UploadModalCSVFileAttachmentViewBytes data-testid="new-order-csv-upload-error-file-size">
										{formatBytes(file.size)}
									</UploadModalCSVFileAttachmentViewBytes>
								</Typography>
							))}

							<div>
								<LinearProgress
									color="error"
									variant="determinate"
									value={error ? 5 : 100}
								/>
							</div>

							<UploadErrorWarning>
								<ErrorOutlineIcon
									data-testid="new-order-csv-upload-error-unsupported-file-icon"
									aria-label="Warning"
								/>
								<UploadErrorWaringText data-testid="new-order-csv-upload-error-unsupported-file-text">
									{uploadStatus ? NewOrderImportFileError : NewOrderUnsupportedFile}
								</UploadErrorWaringText>
							</UploadErrorWarning>
						</UploadErrorContent>
					</div>
				)}
			</Content>
		</div>
	);
};

export default CSVUpload;
