import styled from "@emotion/styled";
import Dialog, { dialogClasses } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { paperClasses } from "@mui/material/Paper";
import { autocompleteClasses } from "@mui/material";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
	fontWeight: 300,
	form: {
		display: "flex",
		flex: 1,
		flexDirection: "column"
	},
	[`& > .${dialogClasses.container} > .${paperClasses.root}`]: {
		boxSizing: "border-box",
		minHeight: 470,
		[theme.breakpoints.down("md")]: {
			minHeight: 570
		}
	},
	[`.${paperClasses.root}`]: {
		color: "var(--text-secondary)",
		minWidth: 375,
		paddingBottom: 24,
		[theme.breakpoints.up("md")]: {
			width: 600
		}
	}
}));

export const RequiredFieldsText = styled.div({
	fontSize: "0.875rem",
	fontWeight: 400
});

export const Content = styled(DialogContent)(({ hidden }) => ({
	...(!hidden && { display: "flex" }),
	color: "var(--text-secondary)",
	flexDirection: "column",
	gap: 16
}));

export const BottomSection = styled(Content)({
	paddingTop: 0,
	"div:nth-of-type(1)": {
		"& > div:nth-of-type(1)": {
			fontSize: "0.875rem",
			fontWeight: 500,
			lineHeight: "var(--line-height-8)"
		},
		"& > div:nth-of-type(2)": {
			fontWeight: 400,
			lineHeight: "var(--line-height-5)"
		},
		"& > div:nth-of-type(3)": {
			fontSize: "0.75rem",
			fontWeight: 400,
			letterSpacing: "var(--letter-spacing-8)",
			lineHeight: "var(--line-height-10)"
		}
	}
});

export const VerifyAddressContent = styled(Content)({
	paddingTop: 0
});

export const Divider = styled.div({
	borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
	marginTop: 8,
	width: "100%"
});

export const FieldGroup = styled.div({
	display: "flex",
	flexDirection: "column"
});

export const SplitRow = styled.div({
	display: "grid",
	gap: 16,
	gridTemplateColumns: "repeat(3, 1fr)"
});

export const ListboxContainer = styled.div({
	padding: "0 12px"
});

export const ListboxHeader = styled.div({
	borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
	color: "var(--grey-700)",
	fontSize: "0.75rem",
	letterSpacing: "var(--letter-spacing-8)",
	padding: "8px 0"
});

export const AutocompleteOption = styled.li({
	[`&.${autocompleteClasses.option}`]: {
		display: "block"
	},
	"div:nth-of-type(2)": {
		fontSize: "0.875rem"
	}
});
