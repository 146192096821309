import OrderListSkeleton from "../Skeletons/OrderListSkeleton";
import styles from "containers/Orders/order-styles.module.css";

import { Box } from "@mui/material";
import NoOrdersErrorBanner from "../NoOrdersErrorBanner/NoOrderErrorBanner";
import { exportOrdersText, OrderLimitMessage } from "constants/text";
import VirtualizedOrderList from "./VirtualizedOrderList";
import { sortBy } from "utils/order";
import ExportSnackbar from "../ExportFile/ExportSnackbar";
import useMyOrdersPDFGenerator from "hooks/useMyOrdersPDFGenerator";
import OrderSummaryAPIFailure from "components/OrderSummaryAPIFailure/OrderSummaryAPIFailure";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExportButtons from "../ExportFile/ExportButtons";
import { generateMyOrdersExcel } from "./helpers/MyOrdersExportsHelpers";
import useSnackbarEffect from "hooks/useSnackbarEffect";

const OrderList = ({
	orders,
	orderStatus,
	isLoading,
	searchFilters,
	searchDates,
	totalStatuses,
	currentSearch,
	isPartialDataset
}: Props) => {
	const { pdfData, generateMyOrdersPDF } = useMyOrdersPDFGenerator(
		orders,
		searchFilters,
		searchDates,
		totalStatuses,
		currentSearch
	);
	const { snackbarOpen } = useSnackbarEffect(pdfData.loading);
	const largePDF = orders.length > 800;

	if (orderStatus !== 200) {
		return (
			<div className={styles.orderSummaryErrorContainer}>
				<OrderSummaryAPIFailure statusCode={orderStatus} />
			</div>
		);
	}

	if (isLoading) {
		return (
			<>
				{Array(3)
					.fill(1)
					.map((_value, index) => (
						<OrderListSkeleton key={index} />
					))}
			</>
		);
	}

	return (
		<div
			data-testid="order-list"
			style={{ margin: "0 1.25rem" }}
		>
			{!isLoading && orders.length > 0 && (
				<Box
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					height="2rem"
				>
					<div
						data-testid="order-list-order-count"
						className={styles.cardTotalOrders}
					>
						Showing {orders.length} results
					</div>
					<ExportButtons
						largePDF={largePDF}
						buttonHeader={exportOrdersText}
						generatePDF={generateMyOrdersPDF}
						generateExcel={() => generateMyOrdersExcel(orders)}
					/>
				</Box>
			)}
			<ExportSnackbar
				open={snackbarOpen}
				fileLoading={pdfData.loading}
				largePDF={largePDF}
			/>

			{!isLoading && isPartialDataset && (
				<div
					data-testid="order-list-order-count-threshold"
					className={styles.fiveKOrders}
				>
					<ErrorOutlineIcon
						data-testid="order-list-order-count-threshold-icon"
						aria-label="Warning"
					/>
					<span>{OrderLimitMessage}</span>
				</div>
			)}
			{!isLoading && orderStatus !== undefined && orderStatus === 200 && orders.length === 0 && (
				<NoOrdersErrorBanner data-testid="order-list-no-order-banner" />
			)}
			{orders.length > 0 && <VirtualizedOrderList items={sortBy(orders)} />}
		</div>
	);
};

interface Props {
	orders: any;
	orderStatus: any;
	isLoading: boolean;
	searchFilters: any;
	searchDates: any;
	totalStatuses: any;
	currentSearch: any;
	isPartialDataset: boolean;
}

export default OrderList;
