import { FC } from "react";
import {
	HeaderWrapper,
	LineItemContentDescription,
	LineItemHeaderValue
} from "components/Common/LineItemGrid/Header/header.styles";

interface HeaderProps {
	description: string;
	header: string;
	lineNumber?: string | null;
}

const Header: FC<HeaderProps> = ({ description, header, lineNumber }) => {
	return (
		<HeaderWrapper data-testid="line-item-grid">
			<div data-testid="line-item-grid-line-number">{lineNumber}</div>
			<LineItemHeaderValue>
				<div data-testid="line-item-grid-header">{header}</div>
				<LineItemContentDescription data-testid="line-item-grid-description">
					{description}
				</LineItemContentDescription>
			</LineItemHeaderValue>
		</HeaderWrapper>
	);
};

export default Header;
