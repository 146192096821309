import styled from "@emotion/styled";
import { LoadingSkeletonProps } from "../LineItemGrid/LineItemGridStyles.ts";

export const ErrorBannerStyles = styled.div<LoadingSkeletonProps>(({ isLoading, theme }) => ({
	...theme.project.typography.body.body2,
	display: isLoading ? "none" : "flex",
	flexDirection: "row",
	padding: 16,
	backgroundColor: theme.project.colors.backgroundColors.error,
	margin: "16px 0",
	color: theme.project.colors.semanticColors.error,
	div: {
		"> :first-of-type": {
			display: "flex"
		},
		":nth-of-type(2)": {
			marginTop: "8px",
			display: "flex",
			a: {
				...theme.project.typography.subtitle.subtitle2,
				display: "flex",
				color: theme.project.colors.semanticColors.error,
				svg: {
					margin: 0,
					width: 16,
					height: 16
				}
			}
		}
	},
	h1: {
		...theme.project.typography.body.body1,
		fontWeight: 500,
		margin: 0
	},
	svg: {
		marginRight: 8
	},
	ul: {
		display: "flex",
		flexDirection: "column",
		margin: 0
	}
}));
