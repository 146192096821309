import { type FC, type MouseEvent } from "react";
import { SaveDraftInteriorTableHeader } from "components/NewOrders/SaveDraft/SaveDraftTable/SaveDraftTable.styles.ts";
import { Box, TableCell, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

export type Order = "asc" | "desc";

export interface HeadCell {
	disablePadding: boolean;
	id: string;
	label: string;
	numeric: boolean;
	sortable: boolean;
}

interface SortableTableHeadProps {
	onRequestSort: (event: MouseEvent<unknown>, property: any) => void;
	order: Order;
	orderBy: string;
	headCells: readonly HeadCell[];
}

const SortableTableHead: FC<SortableTableHeadProps> = (props) => {
	const { headCells, order, orderBy, onRequestSort } = props;
	const createSortHandler = (property: any) => (event: MouseEvent<unknown>) => {
		onRequestSort(event, property);
	};

	return (
		<SaveDraftInteriorTableHeader>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={headCell.sortable && orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={headCell.sortable ? createSortHandler(headCell.id) : undefined}
							hideSortIcon={!headCell.sortable}
							sx={{ flexDirection: "row-reverse" }}
						>
							{headCell.id === "updatedAt" ? (
								<Tooltip
									data-testid="new-order-save-draft-table-date-tooltip"
									title="Date Last Saved"
									placement="top"
								>
									<div>{headCell.label}</div>
								</Tooltip>
							) : (
								headCell.label
							)}
							{orderBy === headCell.id ? (
								<Box
									component="span"
									sx={visuallyHidden}
								>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</SaveDraftInteriorTableHeader>
	);
};

export default SortableTableHead;
