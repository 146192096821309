import styled from "@emotion/styled";
import { LinkButton } from "../../Common/Link";
import { Button } from "@mui/material";

interface AddModificationStylesProps {
	multipleRanges: boolean;
}

export const AddModificationButton = styled(Button)({
	display: "flex",
	gap: "8px",
	fontWeight: 500,
	textTransform: "uppercase",
	marginLeft: "-8px"
});

export const AddModificationWrapper = styled.div({
	div: {
		"&:first-child": {
			marginBottom: 8
		}
	}
});

export const AddModificationTypeWrapper = styled.div<AddModificationStylesProps>(({ multipleRanges, theme }) => ({
	display: "flex",
	flexDirection: "row",
	gap: 16,
	div: {
		"&:first-child": {
			flex: multipleRanges ? "120%" : "130%"
		},
		"&:nth-child(2)": {
			flex: multipleRanges ? "40%" : "30%"
		},
		"&:nth-child(3)": {
			flex: multipleRanges ? "40%" : "10%"
		}
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		div: {
			"&:first-child": {
				marginBottom: 0
			},
			"&:nth-child(2)": {
				marginBottom: 8
			},
			"&:nth-child(3)": {
				marginBottom: 8
			}
		}
	}
}));

export const AddModificationRemoveButton = styled(LinkButton)(({ theme }) => ({
	color: theme.project.colors.fontColors.main,
	marginTop: "-30px",
	textDecorationColor: theme.project.colors.fontColors.main,
	[theme.breakpoints.down("md")]: {
		marginTop: "-10px",
		marginBottom: 8
	}
}));

export const AddModificationValues = styled.div(({ theme }) => ({
	color: "#000000",
	opacity: 0.6,
	[theme.breakpoints.up("md")]: {
		marginTop: "-15px"
	},
	fontSize: 12
}));
