import styled from "@emotion/styled";
export const EditLineItemHeaderContainer = styled.div(({ theme }) => ({
	...theme.project.typography.heading.h6,
	border: "1px solid",
	borderColor: theme.project.colors.primaryColors.main,
	margin: "16px 0",
	padding: 16,
	h6: {
		margin: "8px 0 16px 0"
	}
}));

export const EditLineItemHeaderRow = styled.div(({ theme }) => ({
	marginTop: 16,
	marginBottom: 24,
	display: "grid",
	gridTemplateColumns: "5fr 1fr 1fr 2fr 1fr",
	gap: 24,
	div: {
		lineHeight: 1,
		"&:nth-child(2)": {
			span: {
				"&:first-child": {
					...theme.project.typography.subtitle.subtitle2,
					display: "block"
				},
				"&:nth-child(2)": {
					...theme.project.typography.body.body2
				}
			}
		}
	},
	[theme.breakpoints.down("md")]: {
		display: "block",
		div: {
			lineHeight: "normal",
			"&:nth-child(2)": {
				marginBottom: 10
			}
		}
	}
}));

export const EditAddModificationGlobalAttributesWrapper = styled.div({
	marginTop: 16,
	marginBottom: 16
});

export const EditAddModificationWrapper = styled.div({
	marginTop: 16
});

export const EditConfigurationSelect = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	textWrap: "initial",
	span: {
		"&:nth-child(2)": {
			...theme.project.typography.body.body2
		}
	}
}));
