import { Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material";
import styles from "pages/replacements-page-styles.module.css";
import { ChangeEvent, useState } from "react";
import { MaxQuantitySelectedText, ReplaceOriginalItem } from "constants/text";
import { useDispatch } from "react-redux";
import { cartActions, ReplacementCartItem } from "features/reducers/replacementOrder/cart.ts";
import TandemTooltip from "../Tooltip/TandemTooltip";
import { OriginalItemTextContainer } from "components/Replacements/replaceOriginalItemBox.styles";

export interface ReplaceOriginalItemBoxProps {
	maxCabinetsToBeReplaced: number;
	lineItem: ReplacementCartItem;
	disableOriginalItemCheckbox: boolean;
	handlePartError: (check: boolean) => void;
}

const ReplaceOriginalItemBox = ({
	maxCabinetsToBeReplaced,
	lineItem,
	disableOriginalItemCheckbox,
	handlePartError
}: ReplaceOriginalItemBoxProps) => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const lineItemToEdit = { ...lineItem };

	const handleWholeCabinetSelectedChanged = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			handlePartError(false);
			lineItemToEdit.wholeCabinetQuantity = 1;
		} else {
			lineItemToEdit.wholeCabinetQuantity = 0;
		}

		dispatch(cartActions.updateDraft(lineItemToEdit));
	};

	const handleWholeCabinetQuantityChanged = (event: SelectChangeEvent) => {
		lineItemToEdit.wholeCabinetQuantity = Number(event.target.value);

		if (lineItemToEdit.wholeCabinetQuantity === lineItemToEdit.quantityOrdered) {
			lineItemToEdit.replaceableParts = lineItemToEdit.replaceableParts.map((part) => ({
				...part,
				partQuantity: 0
			}));
		}

		dispatch(cartActions.updateDraft(lineItemToEdit));
	};

	const onClose = () => {
		setOpen(!disableOriginalItemCheckbox);
	};

	const onOpen = () => {
		setOpen(disableOriginalItemCheckbox);
	};

	return (
		<div className={styles.replaceOriginalItemContainer}>
			<Tooltip
				open={open}
				onClose={onClose}
				onOpen={onOpen}
				title={MaxQuantitySelectedText}
				PopperProps={{
					modifiers: [
						{
							name: "offset",
							options: {
								offset: [0, -20]
							}
						}
					]
				}}
			>
				<Grid
					container
					columns={12}
				>
					<Grid
						item
						xs={12}
					>
						<p className="overline">{ReplaceOriginalItem}</p>
					</Grid>
					<Grid
						item
						xs={1}
					>
						<Checkbox
							size="small"
							checked={lineItemToEdit?.wholeCabinetQuantity > 0}
							data-testid="replace-original-item-checkbox"
							sx={{ float: "right" }}
							inputProps={{ id: "replace-original-item" }}
							disabled={disableOriginalItemCheckbox}
							indeterminate={disableOriginalItemCheckbox}
							onChange={handleWholeCabinetSelectedChanged}
						/>
					</Grid>
					<Grid
						item
						className={styles.originalItemPartSkuAndDescription}
						xs={9}
					>
						<OriginalItemTextContainer>
							<TandemTooltip
								title={lineItemToEdit.sku}
								testId="sku"
							/>
							<TandemTooltip
								title={lineItemToEdit.description}
								testId="description"
							/>
						</OriginalItemTextContainer>
					</Grid>
					<Grid
						item
						xs={1}
					>
						<FormControl>
							<InputLabel
								shrink
								htmlFor="original-item-select-quantity"
							>
								Qty
							</InputLabel>
							<Select
								fullWidth
								size="small"
								label="Qty"
								inputProps={{
									id: "original-item-select-quantity",
									"data-testid": "select-quantity-for-original-item"
								}}
								value={String(
									lineItemToEdit?.wholeCabinetQuantity !== 0
										? lineItemToEdit?.wholeCabinetQuantity
										: 1
								)}
								data-testid="select-quantity-for-original-item-outer"
								onChange={handleWholeCabinetQuantityChanged}
								disabled={lineItemToEdit?.wholeCabinetQuantity === 0}
								sx={{ color: "var(--text-primary)" }}
							>
								{Array.from(Array(maxCabinetsToBeReplaced)).map((_, index) => (
									<MenuItem
										key={index + 1}
										value={index + 1}
										data-testid={`original-item-quantity-option-${index + 1}`}
									>
										{index + 1}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Tooltip>
		</div>
	);
};

export default ReplaceOriginalItemBox;
