import styled from "@emotion/styled";

export const AddressWrapper = styled.div(({ theme }) => ({
	display: "grid",
	gap: "1rem",
	gridTemplateColumns: "repeat(2, 1fr)",
	padding: "1rem",
	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "1fr"
	},
	div: {
		alignItems: "flex-start",
		margin: 0
	}
}));

export const CommentWrapper = styled.div({
	padding: "0 1rem 1rem 1rem",
	p: {
		margin: 0
	}
});

export const OrderDetailsSummaryContainer = styled.div(({ theme }) => ({
	margin: "1rem",
	backgroundColor: theme.project.colors.backgroundColors.main,
	boxShadow: `0 3px 1px -2px ${theme.palette.grey[400]}`
}));

export const OrderDetailsColumnsContainer = styled.div(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "repeat(3, 2fr) 3fr repeat(3, 1fr)",
	paddingLeft: "1rem",
	paddingTop: "1rem",
	gap: 8,
	div: {
		"> :first-of-type": {
			...theme.project.typography.subtitle.subtitle2,
			color: theme.project.colors.fontColors.main,
			alignItems: "center"
		},
		"> :last-of-type": {
			...theme.project.typography.body.body2,
			color: theme.project.colors.fontColors.secondary,
			alignItems: "center"
		}
	},
	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "1fr",
		div: {
			display: "flex"
		}
	}
}));
