import styled from "@emotion/styled";
import LineItemGrid from "components/Common/LineItemGrid/LineItemGrid";
import { LoadingSkeletonProps } from "../../Common/LineItemGrid/LineItemGridStyles";
import ContentLoadingSkeleton from "pages/OrderPages/components/LoadingSkeleton/ContentLoadingSkeleton.tsx";
import { paperClasses } from "@mui/material/Paper";
import { CommonSkeletonStyles } from "pages/OrderPages/components/LoadingSkeleton/loadingSkeleton.styles.ts";

export const BuildLineItemGrid = styled(LineItemGrid)<LoadingSkeletonProps>(({ isLoading }) => ({
	display: isLoading ? "none" : "grid",
	"& > div:first-of-type > div:first-of-type": {
		gridColumn: "span 3"
	}
}));

export const LineItemCount = styled.div(({ theme }) => ({
	...theme.project.typography.overline,
	color: theme.project.colors.fontColors.main,
	whiteSpace: "nowrap"
}));

export const LineItemLoadingSkeleton = styled(ContentLoadingSkeleton)({
	margin: 0,
	"& > div:first-of-type > div:first-of-type": {
		padding: 0
	},
	[`.${paperClasses.root}`]: {
		padding: 16
	}
});

export const LabelSkeletonContainer = styled(CommonSkeletonStyles)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: 16,
	padding: "16px 0",
	[theme.breakpoints.up("md")]: {
		"& > span:nth-of-type(1)": {
			height: "42px !important"
		},
		"& > span:nth-of-type(n + 2)": {
			display: "none"
		}
	}
}));

export const NewOrderSkeletonContainer = styled.div(({ theme }) => ({
	[theme.breakpoints.down("md")]: {
		padding: "0 20px"
	}
}));

export const ConfigAttributeTools = styled.div({
	alignSelf: "flex-start",
	display: "flex",
	minWidth: "fit-content"
});

export const AddItemButtonWrapper = styled.div(({ theme }) => ({
	alignItems: "center",
	display: "flex",
	flex: 1,
	justifyContent: "space-between",
	"& > div:first-of-type": {
		marginBottom: 16,
		padding: "6px 16px"
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		gap: 16,
		button: {
			margin: 0
		}
	}
}));

export const AddItemWrapper = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	"& > div:last-of-type": {
		padding: "0 16px 16px 0",
		textAlign: "right",
		[theme.breakpoints.down("md")]: {
			textAlign: "center"
		}
	}
}));
