import { createSlice } from "@reduxjs/toolkit";
import { read } from "hooks/useSession.ts";
import { ReplacementFormTracking } from "data/api/v1";
import { BillToViewModel } from "data/api/v1/model/bill-to-view-model.ts";
import { ShipToViewModel } from "data/api/v1/model/ship-to-view-model.ts";

const submittingReplacementOrder = read("_submittingReplacementOrder");

export interface ShippingDetails {
	shipToId: string | null;
	address: {
		name?: string | null;
		line1?: string | null;
		line2?: string | null;
		line3?: string | null;
		city?: string | null;
		state?: string | null;
		zip?: string | null;
		county?: string | null;
		phoneNumber?: string | null;
		email?: string | null;
	};
	isActive: boolean;
	isSelected: boolean;
}

export interface OrderDetails {
	jobName?: string;
	poNumber?: string;
	shippingMethod?: boolean;
	shippingDetails?: ShipToViewModel;
	billingDetails?: BillToViewModel;
	labelComments?: string | null;
	isCustomerPickup?: boolean;
}

interface InitialState {
	newAddresses: ShippingDetails[];
	order: OrderDetails | undefined;
	editState: ReplacementFormTracking;
}

export const initialState: InitialState = submittingReplacementOrder
	? { ...submittingReplacementOrder }
	: {
			newAddresses: [] as ShippingDetails[],
			order: undefined,
			editState: ReplacementFormTracking.NONE
		};

const submittingReplacementOrderSlice = createSlice({
	name: "submittingReplacementOrder",
	initialState,
	reducers: {
		addNewAddress: (state, action) => {
			const addressMatchCallback = (shipTo: ShipToViewModel | null) => {
				return (
					shipTo?.address?.line1 === action.payload?.line1 &&
					shipTo?.address?.line2 === action.payload?.line2 &&
					shipTo?.address?.line3 === action.payload?.line3 &&
					shipTo?.address?.city === action.payload?.city &&
					shipTo?.address?.zip === action.payload?.zip
				);
			};

			if (action.payload && !state.newAddresses?.some(addressMatchCallback)) {
				state.newAddresses?.push(action.payload);
			}

			return state;
		},
		updateOrderDetails: (state, action) => {
			state.order = {
				...state.order,
				jobName: action.payload.jobName,
				poNumber: action.payload.poNumber
			};
		},
		resetOrder: (state) => {
			state.order = undefined;
			state.newAddresses = [];
			state.editState = ReplacementFormTracking.NONE;
		},
		updateShippingOrderDetails: (state, action) => {
			state.order = {
				...state.order,
				shippingDetails: action.payload.shippingDetails,
				labelComments: action.payload.labelComments,
				isCustomerPickup: action.payload.isCustomerPickup
			};
		},
		setSelectedBillToAddress: (state, action) => {
			if (action.payload) {
				state.order = {
					...state.order,
					billingDetails: action.payload
				};
			}
		},
		setEditState: (state, action) => {
			state.editState = action.payload;
		}
	}
});

export const submittingReplacementOrderActions = submittingReplacementOrderSlice.actions;
export default submittingReplacementOrderSlice.reducer;
