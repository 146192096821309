import StepLabel from "@mui/material/StepLabel";
import { useLocation, useParams } from "react-router-dom";
import { ReplacementReason, ReplacementReviewText, ReplacementsFlyoutSelectPartText } from "constants/text";
import {
	NewOrderBuildOrderLabel,
	NewOrderShippingDetailsLabel,
	NewOrderViewPriceEstimateTitle,
	ReviewAndPlaceOrder
} from "../../NewOrders/constants";
import { StyledStep, StyledStepper } from "./Stepper.styles";

const ProgressStepper = () => {
	const location = useLocation();
	const params = useParams();
	const newOrders = "/new-order/";
	const addOns = "/add-ons/";
	const newOrderSteps = [
		NewOrderBuildOrderLabel,
		NewOrderViewPriceEstimateTitle,
		NewOrderShippingDetailsLabel,
		ReviewAndPlaceOrder
	];
	const replacementSteps = [ReplacementsFlyoutSelectPartText, ReplacementReason, ReplacementReviewText];
	const activeSteps =
		location.pathname.includes(newOrders) || location.pathname.includes(addOns) ? newOrderSteps : replacementSteps;

	const handleNewOrderSteps = (location: string) => {
		if (location.includes("build-order")) {
			return 0;
		}
		if (location.includes("view-price-estimates")) {
			return 1;
		}
		if (location.includes("shipping-details")) {
			return 2;
		}
		if (location.includes("review-order")) {
			return 3;
		}
	};

	const handleReplacementSteps = (location: string) => {
		if (location.includes(`/replacements/${params.orderId}`)) {
			return 0;
		}
		if (location.includes(`/replacements/reason/${params.orderId}`)) {
			return 1;
		}
		if (location.includes(`/replacements/review/${params.orderId}`)) {
			return 2;
		}
	};

	const handleActiveStep = (location: string) => {
		if (location.includes(newOrders) || location.includes(addOns)) {
			return handleNewOrderSteps(location);
		}

		if (location.includes("/replacements/") && params?.orderId) {
			return handleReplacementSteps(location);
		}
	};

	const active = handleActiveStep(location.pathname);

	return (
		<StyledStepper activeStep={active}>
			{activeSteps.map((label, index) => {
				return (
					<StyledStep
						data-testid={`progress-step-${index}`}
						key={label}
					>
						<StepLabel>{label}</StepLabel>
					</StyledStep>
				);
			})}
		</StyledStepper>
	);
};

export default ProgressStepper;
