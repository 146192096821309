import { NewOrdersContentContainer, NewOrdersLayoutContainer } from "components/NewOrders/NewOrders.styles";
import ViewPriceEstimatesContent from "components/NewOrders/ViewPriceEstimates/ViewPriceEstimatesContent";
import NewOrdersHeader from "components/NewOrders/NewOrdersHeader/NewOrdersHeader";
import { RefObject, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { AddOnsProps } from "../BuildOrderPage/BuildOrderPage";

const ViewPriceEstimatesPage = ({ isAddOn }: AddOnsProps) => {
	const [contentOffset, setContentOffset] = useState(0);
	const calcHeightOfHeaderForContentTopOffset = (headerContainer: RefObject<HTMLDivElement>) => {
		setContentOffset(headerContainer.current?.clientHeight ?? 0);
	};
	const draft = useSelector((state: RootState) => state.submittingNewOrder.draftOrder);

	return (
		<NewOrdersLayoutContainer>
			<NewOrdersHeader
				getContainerElement={calcHeightOfHeaderForContentTopOffset}
				isPDFExport
				draftOrder={draft}
				isAddOn={isAddOn}
			/>
			<NewOrdersContentContainer marginTopOffset={contentOffset}>
				<ViewPriceEstimatesContent isAddOn={isAddOn} />
			</NewOrdersContentContainer>
		</NewOrdersLayoutContainer>
	);
};

export default ViewPriceEstimatesPage;
