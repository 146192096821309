import { type FC } from "react";
import { Wrapper } from "components/Common/LineItemGrid/Modification/modification.styles";
import { AddLineItemRequiredDimensionsLabel } from "../../../NewOrders/AddLineItem/constants.ts";
import { AddLineItemRequiredDimensions } from "../../../NewOrders/AddLineItem/AddLineItemStyles.ts";
import { models } from "../../../../types/api/viewModels.ts";

export interface ModificationProps {
	description?: string | null;
	extendedPrice?: number;
	lineNumber: string | null | undefined;
	listPrice?: number;
	title?: string | null;
	dimensionDescription?: string | null;
	dimensionValue?: number;
	values?: models["PendingValueViewModel"][] | null | undefined;
}

const Modification: FC<ModificationProps> = (props) => {
	const { description, extendedPrice, lineNumber, listPrice, title, dimensionDescription, dimensionValue, values } =
		props;
	const rowCount = typeof extendedPrice !== "undefined" && typeof listPrice !== "undefined" ? 2 : 1;

	return (
		<Wrapper rowCount={rowCount}>
			<div>
				<div data-testid="line-item-grid-modifications-line-number">{lineNumber}</div>

				<div>
					{title && (
						<div data-testid="line-item-grid-modifications-line-title">
							{title} - {description}
						</div>
					)}
					{values?.map((value) => (
						<div key={value.value}>
							<div data-testid="line-item-grid-modifications-line-description">
								{value.description} <span>{`${value.value}"`}</span>
							</div>
						</div>
					))}
					{dimensionDescription && (
						<AddLineItemRequiredDimensions>
							<div data-testid="line-item-grid-modifications-line-required-dimension-label">
								{AddLineItemRequiredDimensionsLabel}
							</div>
							<div data-testid="line-item-grid-modifications-line-required-dimension-description">
								{dimensionDescription}
							</div>
							<div data-testid="line-item-grid-modifications-line-required-dimension-value">
								{`${dimensionValue}"`}
							</div>
						</AddLineItemRequiredDimensions>
					)}
				</div>
			</div>
			{/* Confirmed with Sarah that we do not want to display extended and list pricing on modifications */}
			{/* Jen asked that we comment this code out should we want to restore it later */}
			{/* <div> */}
			{/*	<div data-testid="line-item-grid-modifications-line-list-price"> */}
			{/*		{listPrice?.toLocaleString("en-US", { */}
			{/*			style: "currency", */}
			{/*			currency: "USD" */}
			{/*		})} */}
			{/*	</div> */}
			{/*	<div data-testid="line-item-grid-modifications-line-extended-price"> */}
			{/*		{extendedPrice?.toLocaleString("en-US", { */}
			{/*			style: "currency", */}
			{/*			currency: "USD" */}
			{/*		})} */}
			{/*	</div> */}
			{/* </div> */}
		</Wrapper>
	);
};

export default Modification;
