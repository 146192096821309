import {
	NewOrderContactAccountManagerSubheader,
	NewOrderCouldNotGeneratePriceEstimateHeader,
	NewOrderEstimateErrorGenerateAgainBullet,
	NewOrderEstimateErrorReUploadBullet,
	NewOrderEstimateErrorReviewGuidelinesBullet,
	NewOrderEstimateErrorSaveAsADraftBullet,
	NewOrderEstimateErrorSubHeader,
	NewOrderEstimateErrorUpdateLineItemsBullet,
	NewOrderPleaseContactCare,
	SelectAccountAndBillTo
} from "./text.ts";

export const NewOrderErrorBanners = [
	{
		id: 1,
		bannerType: "NewOrderLineItemErrorBanner",
		bannerHeader: NewOrderCouldNotGeneratePriceEstimateHeader,
		bannerSubheader: NewOrderEstimateErrorSubHeader,
		bannerBullets: [
			NewOrderEstimateErrorGenerateAgainBullet,
			NewOrderEstimateErrorUpdateLineItemsBullet,
			NewOrderEstimateErrorReviewGuidelinesBullet,
			NewOrderEstimateErrorReUploadBullet
		],
		bannerUnderBulletText: NewOrderPleaseContactCare
	},
	{
		id: 2,
		bannerType: "NewOrderConfigurationErrorBanner",
		bannerHeader: NewOrderCouldNotGeneratePriceEstimateHeader,
		bannerSubheader: NewOrderContactAccountManagerSubheader,
		bannerBullets: [],
		bannerUnderBulletText: ""
	},
	{
		id: 3,
		bannerType: "NewOrder502ErrorBanner",
		bannerHeader: NewOrderCouldNotGeneratePriceEstimateHeader,
		bannerSubheader: NewOrderEstimateErrorSubHeader,
		bannerBullets: [NewOrderEstimateErrorGenerateAgainBullet, NewOrderEstimateErrorSaveAsADraftBullet],
		bannerUnderBulletText: ""
	},
	{
		id: 4,
		bannerType: "NewOrder400ErrorBanner",
		bannerHeader: NewOrderCouldNotGeneratePriceEstimateHeader,
		bannerSubheader: NewOrderEstimateErrorSubHeader,
		bannerBullets: [],
		bannerUnderBulletText: NewOrderPleaseContactCare
	},
	{
		id: 5,
		bannerType: "NewOrderBillToErrorBanner",
		bannerHeader: NewOrderCouldNotGeneratePriceEstimateHeader,
		bannerSubheader: NewOrderEstimateErrorSubHeader,
		bannerBullets: [SelectAccountAndBillTo],
		bannerUnderBulletText: ""
	}
];
