import { Chip, Menu, MenuItem } from "@mui/material";
import { useRef, useState } from "react";
import { exportCSVText, exportPDFText } from "constants/text";
import Download from "@mui/icons-material/Download";
import styles from "./export-file.module.css";
import LargePDFModal from "./LargePDFModal";

interface ExportButtonsProps {
	largePDF: boolean;
	buttonHeader: string;
	generatePDF: () => void;
	generateExcel?: () => void;
	isOnlyPDF?: boolean;
}

const ExportButtons = ({
	largePDF,
	buttonHeader,
	generatePDF,
	generateExcel,
	isOnlyPDF = false
}: ExportButtonsProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const anchorRef = useRef(null);
	const [showExportPDFTooLarge, setShowExportPDFTooLarge] = useState(false);

	const handleMenuToggle = () => {
		if (isOnlyPDF) {
			generatePDF();
		} else {
			setIsOpen((prev) => !prev);
		}
	};

	const pdfClose = () => {
		setShowExportPDFTooLarge((prevState) => !prevState);
	};

	const pdfOptionSelected = () => {
		setIsOpen(false);

		if (largePDF) {
			setShowExportPDFTooLarge((prevState) => !prevState);
		} else {
			generatePDF();
		}
	};

	const excelOptionSelected = () => {
		setIsOpen(false);

		if (showExportPDFTooLarge) {
			pdfClose();
		}

		if (generateExcel) {
			generateExcel();
		}
	};

	return (
		<div>
			<Chip
				ref={anchorRef}
				clickable
				onClick={handleMenuToggle}
				sx={{
					"& .MuiChip-label": {
						fontSize: "13px",
						fontFamily: "Gibson Medium",
						letterSpacing: "var(--letter-spacing-10)",
						padding: 0,
						textAlign: "center",
						lineHeight: "1.5rem",
						marginRight: ".5rem",
						marginTop: "1px"
					},
					color: "var(--text-secondary)",
					height: "30px",
					background: "none",
					borderRadius: "0px",
					padding: "4px 5px",
					backgroundColor: isOpen ? "var(--export-button)" : "none"
				}}
				label={buttonHeader}
				onDelete={handleMenuToggle}
				deleteIcon={
					<Download
						style={{
							color: "var(--text-secondary)",
							width: "18px",
							margin: 0
						}}
					/>
				}
				data-testid="order-list-export-orders"
			/>
			<Menu
				open={isOpen}
				anchorEl={anchorRef.current}
				onClose={handleMenuToggle}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
				data-test-id="export-menu"
			>
				<MenuItem
					className={styles.exportFileItem}
					onClick={pdfOptionSelected}
					data-testid="order-list-export-pdf"
				>
					{exportPDFText}
				</MenuItem>
				<MenuItem
					className={styles.exportFileItem}
					onClick={excelOptionSelected}
					data-testid="order-list-export-excel"
				>
					{exportCSVText}
				</MenuItem>
			</Menu>
			<LargePDFModal
				handleClose={pdfClose}
				open={showExportPDFTooLarge}
				popupAnchor={anchorRef.current}
				onExportAsExcel={excelOptionSelected}
			/>
		</div>
	);
};

export default ExportButtons;
