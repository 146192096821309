import { type FC, type PropsWithChildren } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { schema } from "components/NewOrders/NewOrderShippingDetailsForm/schema";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";

const NewOrderFormProvider: FC<PropsWithChildren> = ({ children }) => {
	const shippingDetails = useSelector((state: RootState) => state.shippingDetails);

	const methods = useForm({
		resolver: yupResolver(schema),
		context: {
			isPhoneRequired: true
		},
		defaultValues: {
			designer: shippingDetails?.designer ?? undefined,
			poNumber: shippingDetails?.poNumber ?? undefined,
			jobName: shippingDetails?.jobName ?? undefined,
			email: shippingDetails?.email ?? shippingDetails?.address?.address?.email ?? undefined,
			phoneNumber: shippingDetails?.phoneNumber ?? shippingDetails?.address?.address?.phoneNumber ?? undefined,
			deliveryInstructions: shippingDetails?.deliveryInstructions ?? undefined,
			address:
				shippingDetails?.address && Object.keys(shippingDetails.address?.address ?? {}).length > 0
					? shippingDetails.address
					: undefined,
			requestedDeliveryDate: shippingDetails?.requestedDeliveryDate ?? undefined,
			isCustomerPickup: shippingDetails?.isCustomerPickup ?? undefined
		}
	});

	return <FormProvider {...methods}>{children}</FormProvider>;
};

export default NewOrderFormProvider;
