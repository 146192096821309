import styles from "pages/replacements-page-styles.module.css";
import { Grid } from "@mui/material";
import { ReplacementReasonText, ReplacementReviewText } from "constants/text";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import SelectedReplacementReason from "./SelectedReplacementReason";
import { ReplacementCartItem } from "features/reducers/replacementOrder/cart.ts";

const ReplacementReview = () => {
	const cartItems = useSelector((state: RootState) => state.cart.replacements);

	return (
		<div>
			<Grid
				container
				className={styles.replacementReasonHeader}
			>
				<Grid item>
					<h1
						className={styles.replacementReasonHeader}
						data-testid="reviewReplacementReason-header"
					>
						{ReplacementReviewText}
					</h1>
				</Grid>
			</Grid>

			<Grid
				container
				className={styles.tellUsWhyContainer}
			>
				<h2 data-testid="reviewReplacementReason-subheader">{ReplacementReasonText}</h2>
				{cartItems.map((item: ReplacementCartItem) => (
					<Grid
						item
						xs={12}
						key={item.sku}
					>
						<SelectedReplacementReason item={item} />
					</Grid>
				))}
			</Grid>
		</div>
	);
};

export default ReplacementReview;
