import {
	ReplacementPost400ErrorDescription,
	ReplacementPost400ErrorHeader,
	ReplacementPost400ErrorHeader4000Errors,
	NewOrderCheckYourCSVBullet,
	NewOrderCouldNotGeneratePriceEstimateHeader,
	NewOrderEstimateErrorSubHeader
} from "constants/text";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { Grid } from "@mui/material";
import { useMemo } from "react";
import { APIErrorResponse } from "features/api/utils/apiUtils.ts";
import { ErrorBannerStyles } from "./ErrorBannerStyles";

export interface ErrorBannerProps {
	apiErrorResponse?: APIErrorResponse["errors"];
	className?: string;
	newOrderLineItemErrors?: any;
	errorMessage?: string;
	title?: string;
	isLoading?: boolean;
}

const ErrorBanner = ({
	apiErrorResponse,
	className,
	errorMessage,
	isLoading,
	newOrderLineItemErrors,
	title
}: ErrorBannerProps) => {
	const isUserError = useMemo(
		() =>
			apiErrorResponse
				?.filter((error: any) => error.code === 4000 || error.code === 400)
				.map((error: any) => error),
		[apiErrorResponse]
	);

	return (
		<ErrorBannerStyles
			className={className}
			isLoading={isLoading}
		>
			<Grid container>
				<Grid container>
					<Grid item>
						<ErrorOutline />
					</Grid>
					<Grid
						item
						xs={10}
						sm={11}
					>
						<h1 data-testid="api-error-banner-header">
							{title}
							{isUserError && isUserError?.length > 0 && ReplacementPost400ErrorHeader4000Errors}
							{newOrderLineItemErrors && NewOrderCouldNotGeneratePriceEstimateHeader}
							{!isUserError && !newOrderLineItemErrors && !title && ReplacementPost400ErrorHeader}
						</h1>
						{newOrderLineItemErrors && <span>{NewOrderEstimateErrorSubHeader}</span>}
					</Grid>
				</Grid>

				<Grid container>
					<Grid
						item
						xs={11}
					>
						{isUserError && isUserError?.length > 0 && (
							<>
								{isUserError?.map((error: any) => (
									<li
										data-testid="api-error-banner-message"
										key={error.code}
									>
										{error.message}
									</li>
								))}
							</>
						)}
						{newOrderLineItemErrors && <li>{NewOrderCheckYourCSVBullet}</li>}
						{!isUserError && !newOrderLineItemErrors && !errorMessage && (
							<p>{ReplacementPost400ErrorDescription}</p>
						)}
						{errorMessage && <p>{errorMessage}</p>}
					</Grid>
				</Grid>
			</Grid>
		</ErrorBannerStyles>
	);
};

export default ErrorBanner;
