import AddIcon from "@mui/icons-material/Add";
import { ProductViewModel } from "data/api/v1";
import { MutableRefObject, SyntheticEvent, useEffect, useMemo, useState } from "react";
import {
	AddModificationButton,
	AddModificationRemoveButton,
	AddModificationTypeWrapper,
	AddModificationValues,
	AddModificationWrapper
} from "./AddModificationStyles.ts";
import AutoComplete from "../../Common/Autocomplete/Autocomplete.tsx";
import { useGetModificationsQuery } from "features/api/newOrderApi";
import {
	AddModificationButtonText,
	AddModificationHeader,
	AddModificationIncrementError,
	AddModificationPlaceholder,
	AddModificationRemoveText
} from "./constants.ts";
import { calculateIntervals } from "components/NewOrders/utils/NewOrderUtils.tsx";
import { models } from "types/api/viewModels.ts";
import { v4 as uuidv4 } from "uuid";

interface AddModificationProps {
	onModificationChange: (modification: models["PendingLineItemViewModel"][]) => void;
	foundSearch: ProductViewModel | undefined;
	onValidation: (error: boolean) => void;
	saveButtonClick: boolean;
	configuration: models["PendingOrderConfigurationViewModel"];
	multipleRanges: boolean;
	selectedEditItem: MutableRefObject<models["PendingLineItemViewModel"] | undefined>;
}

type DisplayModification = Omit<models["PendingModificationViewModel"], "values"> & {
	values: ((models["PendingValueViewModel"] & models["ModificationRangeViewModel"]) | null)[];
	uuid?: string;
};

type NewRange = models["PendingValueViewModel"] &
	models["ModificationRangeViewModel"] & {
		rangeIndex?: number;
	};

const AddModification = ({
	onModificationChange,
	foundSearch,
	onValidation,
	saveButtonClick,
	configuration,
	multipleRanges,
	selectedEditItem
}: AddModificationProps) => {
	const [incrementError, setIncrementError] = useState<boolean>(false);
	const [incrementValue, setIncrementValue] = useState(0);
	const [selectedMod, setSelectedMod] = useState<Record<string, models["ModificationViewModel"] | undefined | null>>(
		{}
	);

	const [modificationList, setModificationList] = useState<DisplayModification[]>([]);

	const { data: modifications, isLoading } = useGetModificationsQuery({
		productId: foundSearch?.id ?? selectedEditItem?.current?.id ?? "",
		productLineId: configuration?.globals?.productLine?.id ?? ""
	});

	useEffect(() => {
		if (selectedEditItem?.current?.modifications) {
			const initialModifications = selectedEditItem.current.modifications.map((mod) => ({
				sku: mod.sku,
				lineItemNumber: "",
				quantityOrdered: 1,
				description: mod.description,
				id: mod.id,
				itemKey: mod.sku,
				values: [],
				uuid: uuidv4()
			}));
			setModificationList(initialModifications);
		}
	}, [selectedEditItem]);

	const handleModificationChange = (_event: SyntheticEvent, value: string, index: number) => {
		const findSelectedMod = modifications?.find((item) => `${item.sku} - ${item.description}`.includes(value));

		setSelectedMod((prev) => ({
			...prev,
			[findSelectedMod?.id ?? ""]: findSelectedMod
		}));

		const newModification: DisplayModification = {
			...findSelectedMod,
			lineItemNumber: "",
			quantityOrdered: 1,
			values: findSelectedMod?.ranges?.map(() => null) ?? [],
			itemKey: findSelectedMod?.sku,
			uuid: modificationList[index]?.uuid ?? uuidv4()
		};

		setModificationList((prev) => {
			const newModificationList = prev.map((mod, modIndex) => {
				if (modIndex === index) {
					return newModification;
				}
				return mod;
			});

			onModificationChange(
				newModificationList.map((mod) => {
					const newMod = { ...mod };
					delete newMod.uuid;
					return newMod;
				})
			);
			return newModificationList;
		});
	};

	const handleRemoveModification = (index: number) => {
		const list = [...modificationList];
		list.splice(index, 1);
		setModificationList(list);
		onModificationChange(list);
	};

	const modificationsData = useMemo(() => {
		if (!isLoading) {
			return modifications?.map((item) => {
				return `${item.sku} - ${item.description}`;
			});
		}
	}, [isLoading, modifications]);

	const handleIncrementChange = (
		index: number,
		value: number,
		description: string,
		increment: number,
		minValue: number,
		maxValue: number,
		rangeIndex: number
	) => {
		setIncrementValue(value);

		const newRange: NewRange = {
			description: description,
			value: value,
			increment: increment,
			minValue: minValue,
			maxValue: maxValue,
			rangeIndex: rangeIndex
		};

		const newModification = {
			...modificationList[index],
			values:
				modificationList?.[index]?.values.map((range, valueIndex) =>
					valueIndex === rangeIndex ? newRange : range
				) ?? []
		};

		setModificationList((prev) => {
			const newModificationList = prev.map((mod, modIndex) => (modIndex === index ? newModification : mod));
			onModificationChange(newModificationList);
			return newModificationList;
		});
	};

	const rangeCount = document.querySelectorAll("#add-line-item-modification-range-select");

	useEffect(() => {
		const hasIncrementError =
			incrementValue === 0 && document.querySelectorAll("#add-line-item-modification-range-select").length > 0;
		setIncrementError(hasIncrementError);

		const hasModTypeError = modificationList.some((mod) => !mod.id || mod.id.trim() === "");
		onValidation(hasModTypeError || hasIncrementError);
	}, [modificationList, incrementValue, onValidation]);

	const addServiceList = () => {
		setModificationList((prev) => [
			...prev,
			{
				sku: "",
				lineItemNumber: "",
				quantityOrdered: 1,
				description: "",
				id: "",
				itemKey: "",
				values: [],
				uuid: uuidv4()
			}
		]);
	};

	return (
		<AddModificationWrapper>
			{modificationList.length === 0 && (
				<div>
					<AddModificationButton
						variant="text"
						data-testid="add-line-item-modification-button"
						onClick={addServiceList}
					>
						<AddIcon />
						{AddModificationButtonText}
					</AddModificationButton>
				</div>
			)}

			{modificationList.map((singleMod, index) => (
				<div key={singleMod.uuid}>
					{index === 0 && <div>{AddModificationHeader}</div>}

					<AddModificationTypeWrapper
						key={selectedEditItem?.current?.id}
						multipleRanges={rangeCount.length > 1}
					>
						<AutoComplete
							options={modificationsData ?? []}
							onChange={(event, value) => handleModificationChange(event, value, index)}
							required
							isError={saveButtonClick && (!singleMod.id || singleMod.id.trim() === "")}
							errorText={AddModificationIncrementError}
							label={AddModificationPlaceholder}
							dataTestId="add-line-item-modification-select"
							disableClearable
							value={
								selectedEditItem?.current?.modifications
									? singleMod.sku &&
										singleMod.description &&
										`${singleMod.sku} - ${singleMod.description}`
									: undefined
							}
						/>

						{(selectedEditItem?.current?.modifications
							? singleMod?.values
							: selectedMod[singleMod?.id ?? ""]?.ranges
						)?.map((item: any, valueIndex) => (
							<div key={item.description}>
								<AutoComplete
									options={calculateIntervals(item.minValue, item.maxValue, item.increment)}
									onChange={(_, value) =>
										handleIncrementChange(
											index,
											value,
											item.description,
											item.increment,
											item.minValue,
											item.maxValue,
											valueIndex
										)
									}
									isError={saveButtonClick && incrementError}
									errorText={AddModificationIncrementError}
									label={item.description ? `Select ${item.description}` : "Select a value"}
									dataTestId="add-line-item-modification-range-select"
									disableClearable
									defaultValue={selectedEditItem?.current?.modifications ? item.value : undefined}
									required
								/>

								{incrementValue !== 0 && (
									<AddModificationValues data-testid="add-line-item-modification-range-value">
										{`Min: ${item.minValue}", Max: ${item.maxValue}"`}
									</AddModificationValues>
								)}
							</div>
						))}

						<AddModificationRemoveButton
							data-testid="add-line-item-modification-remove-button"
							onClick={() => handleRemoveModification(index)}
						>
							{AddModificationRemoveText}
						</AddModificationRemoveButton>
					</AddModificationTypeWrapper>

					{modificationList.length - 1 === index && (
						<div>
							<AddModificationButton
								variant="text"
								data-testid="add-line-item-modification-button"
								onClick={addServiceList}
							>
								<AddIcon />
								{AddModificationButtonText}
							</AddModificationButton>
						</div>
					)}
				</div>
			))}
		</AddModificationWrapper>
	);
};

export default AddModification;
