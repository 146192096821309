import styled from "@emotion/styled";

export const ReviewOrderAccountBillToCardContainer = styled.div<{ isAddOn?: boolean }>(({ theme, isAddOn }) => ({
	display: "grid",
	columnGap: "16px",
	padding: isAddOn ? "0px 16px" : "16px 0",
	color: theme.project.colors.fontColors.main,
	gridTemplateColumns: "1fr 1fr",
	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "1fr",
		rowGap: "16px"
	}
}));

export const ReviewOrderListLineItemContainer = styled.div(({ theme }) => ({
	margin: "8px 0"
}));
