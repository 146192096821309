import { Divider, Link, ListItem } from "@mui/material";
import styles from "pages/replacements-page-styles.module.css";
import { QuantityText, RemoveText } from "constants/text";
import { cartActions, ReplacementCartItem } from "features/reducers/replacementOrder/cart.ts";
import { useDispatch } from "react-redux";
import { submittingReplacementOrderActions } from "features/reducers/replacementOrder/submittingReplacementOrder.ts";
import { ReplacementFormTracking } from "data/api/v1";

interface IndividualShoppingCartItemProps {
	description?: string;
	item?: ReplacementCartItem;
	partId?: string;
	partSku?: string;
	partQuantity?: number;
	sku?: string;
}
const IndividualShoppingCartItem = ({
	description,
	item,
	partId,
	partSku,
	partQuantity,
	sku
}: IndividualShoppingCartItemProps) => {
	const dispatch = useDispatch();

	const handleItemRemoval = () => {
		if (partId) {
			dispatch(cartActions.removeFromCart({ sku, partId }));
		} else {
			dispatch(cartActions.removeFromCart({ sku }));
		}
		dispatch(submittingReplacementOrderActions.setEditState(ReplacementFormTracking.NONE));
	};

	return (
		<div>
			<ListItem
				sx={{
					padding: "5px 10px 5px 10px",
					display: "inline-flex",
					justifyContent: "space-between",
					alignItems: "center",
					wordBreak: "break-word"
				}}
			>
				<div
					data-testid="shopping-cart-sku"
					className={`${styles["textBreak"]} body1`}
				>
					{partSku ?? sku}
				</div>
				<Link
					className="body1"
					onClick={handleItemRemoval}
				>
					<span
						data-testid="shopping-cart-remove"
						className={styles.removeItem}
					>
						{RemoveText}
					</span>
				</Link>
			</ListItem>

			<ListItem
				sx={{
					padding: "0 10px 0 10px",
					display: "inline-flex",
					justifyContent: "space-between",
					alignItems: "flex-start"
				}}
			>
				<div
					data-testid="shopping-cart-description"
					className={`${styles.description} ${"body2"}`}
				>
					{description}
				</div>

				{item?.wholeCabinetQuantity && (
					<div>
						<span className={styles.quantity}>{QuantityText}</span>
						<span className={styles.quantityNumber}>{item.wholeCabinetQuantity}</span>
					</div>
				)}

				{partQuantity && (
					<div>
						<span className={styles.quantity}>{QuantityText}</span>
						<span className={styles.quantityNumber}>{partQuantity}</span>
					</div>
				)}
			</ListItem>
			<Divider />
		</div>
	);
};

export default IndividualShoppingCartItem;
