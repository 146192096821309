import { Theme } from "@mui/material/styles";
import { autocompleteClasses, radioClasses } from "@mui/material";

const rootButtonStyles = {
	alignItems: "flex-end",
	color: "var(--white-50)",
	display: "flex",
	padding: "6px 1rem",
	borderRadius: 0,
	fontSize: 14,
	fontWeight: 500,
	letterSpacing: "0.46px"
};

const textButtonStyles = {
	color: "var(--cwg-blue-900)",
	fontFamily: '"Gibson Regular", sans-serif',
	letterSpacing: "0.1px",
	alignItems: "center",
	lineHeight: "157%"
};

export const commonMuiTheme: Partial<Theme> = {
	components: {
		MuiAutocomplete: {
			styleOverrides: {
				root: {
					[`&.${autocompleteClasses.option}`]: {
						fontFamily: '"Gibson Regular", sans-serif'
					}
				}
			}
		},
		MuiButton: {
			variants: [
				{
					props: { variant: "outlined" },
					style: {
						...rootButtonStyles,
						color: "var(--cwg-blue-900)"
					}
				},
				{
					props: { variant: "contained" },
					style: {
						...rootButtonStyles,
						"&:hover": {
							backgroundColor: "var(--cwg-blue-700)"
						},
						backgroundColor: "var(--cwg-blue-900)"
					}
				},
				{
					props: { variant: "text" },
					style: {
						...textButtonStyles,
						textTransform: "capitalize"
					}
				}
			],
			styleOverrides: {}
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					[`&.${radioClasses.checked}`]: {
						color: "var(--cwg-blue-900)"
					}
				}
			}
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					fontFamily: '"Gibson Regular", sans-serif',
					marginLeft: 0,
					marginRight: 0
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontFamily: '"Gibson Regular", sans-serif'
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					fontFamily: '"Gibson Regular", sans-serif'
				}
			}
		}
	},
	palette: {
		text: {
			// These will be moved to the theme-specific MUI overrides and come from the fontColors object
			primary: "#565759",
			secondary: "rgba(0, 0, 0, 0.6)"
		}
	} as Theme["palette"],
	typography: {
		fontFamily: '"Gibson", sans-serif'
	} as Theme["typography"]
};
