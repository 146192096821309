import { useEffect, useState } from "react";

const useSnackbarEffect = (pdfLoading: boolean) => {
	const [snackbarOpen, setSnackbarOpen] = useState(false);

	useEffect(() => {
		if (pdfLoading) {
			setSnackbarOpen(true);
		} else if (!pdfLoading && snackbarOpen) {
			const timer = setTimeout(() => {
				setSnackbarOpen(false);
			}, 5000);

			return () => clearTimeout(timer);
		}
	}, [pdfLoading, snackbarOpen]);

	return { snackbarOpen };
};

export default useSnackbarEffect;
