import {
	NewOrderBuildGridDoorStyleColumnHeader,
	NewOrderBuildGridFinishColumnHeader,
	NewOrderBuildGridQuantityColumnHeader,
	NewOrderViewPriceEstimateExtendedPriceColumnHeader,
	NewOrderViewPriceEstimateListPriceColumnHeader
} from "components/NewOrders/constants";
import { BuildNewOrderLineItemsHeader, BuildNewOrderLineItemsHeaderWrapper } from "../NewOrders.styles";
import { EstimatesLineItemGrid, PriceEstimateConfigurations } from "./viewPriceEstimates.styles";
import Header from "components/Common/LineItemGrid/Header/Header";
import Modification from "../../Common/LineItemGrid/Modification/Modification";
import { models } from "types/api/viewModels.ts";
import { getAttributes, getSubheaderAttributes } from "components/NewOrders/utils/NewOrderUtils.tsx";

interface ViewPriceEstimatesLineItemsProps {
	priceEstimateConfiguration: models["ValidatedOrderConfigurationViewModel"];
	searchValue: string;
}

const ViewPriceEstimatesLineItems = ({ priceEstimateConfiguration, searchValue }: ViewPriceEstimatesLineItemsProps) => {
	return (
		<>
			<BuildNewOrderLineItemsHeaderWrapper>
				<BuildNewOrderLineItemsHeader>
					<div data-testid="new-order-build-label">
						<div>{getAttributes(priceEstimateConfiguration)}</div>
						<div>{getSubheaderAttributes(priceEstimateConfiguration)}</div>
					</div>
				</BuildNewOrderLineItemsHeader>
			</BuildNewOrderLineItemsHeaderWrapper>
			<PriceEstimateConfigurations>
				{priceEstimateConfiguration?.lineItems?.map((item) => {
					const foundSearch = `${item.sku} - ${item.description}`
						.toUpperCase()
						.includes(searchValue.toUpperCase());
					if (foundSearch) {
						return (
							<EstimatesLineItemGrid
								key={`${item.sku}-${item.lineItemNumber}`}
								mainRowContent={[
									<Header
										description={item.description ?? "--"}
										key={`${item.sku}-${item.lineItemNumber}-header`}
										header={item.sku ?? "--"}
										lineNumber={item.lineItemNumber}
									/>,
									<div key={`${item.sku}-${item.lineItemNumber}-door-style`}>
										<div>{NewOrderBuildGridDoorStyleColumnHeader}</div>
										<div data-testid="view-price-estimate-line-item-doorStyle">
											{priceEstimateConfiguration?.globals?.style?.description}
										</div>
									</div>,
									<div key={`${item.sku}-${item.lineItemNumber}-finish`}>
										<div>{NewOrderBuildGridFinishColumnHeader}</div>
										<div data-testid="view-price-estimate-line-item-finish">
											{priceEstimateConfiguration?.globals?.finish?.description}
										</div>
									</div>,
									<div key={`${item.sku}-${item.lineItemNumber}-qty`}>
										<div>{NewOrderBuildGridQuantityColumnHeader}</div>
										<div data-testid="view-price-estimate-line-item-quantity">
											{item.quantityOrdered}
										</div>
									</div>,
									<div key={`${item.sku}-${item.lineItemNumber}-list-price`}>
										<div>{NewOrderViewPriceEstimateListPriceColumnHeader}</div>
										<div data-testid="view-price-estimate-line-item-listPrice">
											{item?.listPrice?.toLocaleString("en-US", {
												style: "currency",
												currency: "USD"
											})}
										</div>
									</div>,
									<div key={`${item.sku}-${item.lineItemNumber}-extended-price`}>
										<div>{NewOrderViewPriceEstimateExtendedPriceColumnHeader}</div>
										<div data-testid="view-price-estimate-line-item-extendedPrice">
											{item?.extendedPrice?.toLocaleString("en-US", {
												style: "currency",
												currency: "USD"
											})}
										</div>
									</div>
								]}
								modificationRows={item.modifications?.map((modification) => (
									<Modification
										description={modification.description ?? "--"}
										key={modification.sku}
										extendedPrice={item.extendedPrice}
										lineNumber={modification.lineItemNumber}
										listPrice={item.listPrice}
										title={modification.sku ?? "--"}
										values={modification.values}
									/>
								))}
								dimensionRows={item.requiredDimensions?.map((dimension: any, index: number) => (
									<Modification
										dimensionDescription={dimension.description}
										key={dimension.id}
										dimensionValue={dimension.value}
										lineNumber={`${item.lineItemNumber}.${index + 1}`}
									/>
								))}
							/>
						);
					}
					return undefined;
				})}
			</PriceEstimateConfigurations>
		</>
	);
};

export default ViewPriceEstimatesLineItems;
