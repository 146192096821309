import styles from "../replacements-page-styles.module.css";
import { useParams } from "react-router-dom";
import { useGetByIdQuery } from "features/api/orderApi.ts";
import ReplacementConfirmationThanks from "../../components/Replacements/ReplacementConfirmationThanks";
import ReplacementConfirmationOrderShippingDetails from "../../components/Replacements/ReplacementConfirmationOrderShippingDetails";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { cartActions } from "features/reducers/replacementOrder/cart.ts";
import { submittingReplacementOrderActions } from "features/reducers/replacementOrder/submittingReplacementOrder.ts";
import { useGetAccountsByIdQuery } from "features/api/accountApi.ts";

const ReplacementConfirmationPage = () => {
	const dispatch = useDispatch();
	const [isPageMounted, setIsPageMounted] = useState<boolean>(false);
	const pageStatus = useRef({
		ready: false
	});

	if (isPageMounted) {
		pageStatus.current.ready = true;
	}

	useEffect(() => {
		if (!isPageMounted) {
			setIsPageMounted(true);
		}

		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			if (!pageStatus.current.ready) return;

			dispatch(submittingReplacementOrderActions.resetOrder());
			dispatch(cartActions.resetCart());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const params = useParams();
	const { data } = useGetByIdQuery(params?.orderId || "");
	const { data: accounts } = useGetAccountsByIdQuery(params?.orderId || "", { skip: !params?.orderId });

	return (
		<div>
			<div className={styles.replacementsHeaderContainer}>
				<ReplacementConfirmationThanks
					orderDetail={data}
					accounts={accounts?.[0]}
				/>
			</div>
			<div className={styles.replacementReasonContainer}>
				<ReplacementConfirmationOrderShippingDetails
					orderDetail={data}
					accounts={accounts?.[0]}
				/>
			</div>
		</div>
	);
};

export default ReplacementConfirmationPage;
