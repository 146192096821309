import { FormControl, FormHelperText, Grid, MenuItem, Select, SelectChangeEvent, Button } from "@mui/material";
import styles from "pages/replacements-page-styles.module.css";
import {
	DescribeYourProblemText,
	MaxFileSize,
	MaxFileSizeHeader,
	PerImage,
	PleaseDescribeYourProblemErrorText,
	PleaseSelectReplacementReasonErrorText,
	SelectReplacementReasonText,
	SelectThreeImagesText,
	SupportedFiles,
	SupportedFilesHeader,
	AttachFile,
	AttachedFiles
} from "constants/text";
import { REASONS_FOR_REPLACEMENT, REPLACEMENT_REASON_CODES } from "constants/replacementReasonCodes";
import { ReactElement, SetStateAction, useState } from "react";
import { cartActions, ReplacementCartItem, Part } from "features/reducers/replacementOrder/cart.ts";
import { useDispatch } from "react-redux";
import useWindowSettings from "hooks/useWindowSettings";
import ReplacementUploadModal, { ErrorableFileState } from "components/ReplacementUploadModal/ReplacementUploadModal";
import UploadView from "components/UploadView/UploadView";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import UploadFile from "@mui/icons-material/UploadFile";
import { isValidFile, sanitizeBlobName } from "utils/file";
import {
	ErrorTextContainer,
	ReplacementReasonAndProblemInputLabel
} from "./SelectReplacementReasonAndProblemDropdowns.styles";

interface Props {
	item: ReplacementCartItem;
	part?: Part;
	reasonSelectionError: boolean;
	setReasonSelectionError: (event: SetStateAction<boolean>) => void;
	maxFiles?: number;
}

const SelectReplacementReasonAndProblemDropdowns = ({
	item,
	part,
	reasonSelectionError,
	setReasonSelectionError,
	maxFiles = 3
}: Props) => {
	const dispatch = useDispatch();
	const { isMobile } = useWindowSettings() as { isMobile: boolean };
	const [open, setOpen] = useState(false);
	const replacementReason = part ? part.partReplacementReason : item.cabinetReplacementReason;
	const replacementProblem = part ? part.partReplacementProblem : item.cabinetReplacementProblem;
	const fileUploads = part ? part.partFileUploads : item.cabinetFileUploads;
	const partToEdit = part ? { ...part } : null;

	const replaceableParts = item.replaceableParts.map((replaceablePart) => {
		if (replaceablePart.id === part?.id) {
			return partToEdit as Part;
		}
		return { ...replaceablePart } as Part;
	});

	const itemToEdit = { ...item, replaceableParts };

	const handleOpen = () => setOpen(true);
	const handleClose = (files: ErrorableFileState[], item: ReplacementCartItem, part: Part | null) => {
		const validFiles = files.filter(({ file }) => isValidFile(file, fileUploads));
		if (validFiles.length > 0) {
			if (part) {
				part.partFileUploads = part.partFileUploads.concat(
					validFiles.map(({ file }) => ({
						name: sanitizeBlobName(file.name),
						size: file.size,
						fileUrl: URL.createObjectURL(file),
						id: part.id,
						originalLineItemId: part.lineItemId!
					}))
				);
			} else {
				item.cabinetFileUploads = item.cabinetFileUploads.concat(
					validFiles.map(({ file }) => ({
						name: sanitizeBlobName(file.name),
						size: file.size,
						fileUrl: URL.createObjectURL(file),
						id: item.id,
						originalLineItemId: item.lineItemId!
					}))
				);
			}

			dispatch(cartActions.updateItem(item));
		}
		setOpen(false);
	};

	const getDefectCode = (replacementProblem: string) => {
		return REPLACEMENT_REASON_CODES.filter((item) => item.reasonDescription === replacementProblem).map(
			(item) => item.reasonCode
		)[0];
	};

	const handleSelectedReplacementReason = (event: SelectChangeEvent) => {
		if (partToEdit) {
			partToEdit.partReplacementReason = event.target.value;
			partToEdit.partReplacementProblem = null;
			partToEdit.partFileUploads = [];
		} else {
			itemToEdit.cabinetReplacementReason = event.target.value;
			itemToEdit.cabinetReplacementProblem = null;
			itemToEdit.cabinetFileUploads = [];
		}

		dispatch(cartActions.updateItem(itemToEdit));
		setReasonSelectionError(false);
	};
	const handleSelectedReplacementProblem = (event: SelectChangeEvent) => {
		if (partToEdit) {
			partToEdit.partReplacementProblem = event.target.value;
			partToEdit.partFileUploads = [];
			partToEdit.partDefectCode = getDefectCode(partToEdit.partReplacementProblem) ?? "";
		} else {
			itemToEdit.cabinetReplacementProblem = event.target.value;
			itemToEdit.cabinetFileUploads = [];
			itemToEdit.cabinetDefectCode = getDefectCode(itemToEdit.cabinetReplacementProblem) ?? "";
		}

		dispatch(cartActions.updateItem(itemToEdit));
		setReasonSelectionError(false);
	};

	const handleDeleteUploadedItem = (index: number, item: ReplacementCartItem, part: Part | null) => {
		if (part) {
			const fileArray = [...part.partFileUploads.slice(0, index), ...part.partFileUploads.slice(index + 1)];

			part.partFileUploads = fileArray;
		} else {
			const fileArray = [...item.cabinetFileUploads.slice(0, index), ...item.cabinetFileUploads.slice(index + 1)];
			item.cabinetFileUploads = fileArray;
		}

		dispatch(cartActions.updateItem(item));
	};

	return (
		<Grid
			container
			spacing={2}
			wrap="nowrap"
			direction="column"
			className={styles.selectReplacementReasonDropdown}
		>
			<Grid item>
				<FormControl
					size="small"
					required
					fullWidth
				>
					<ReplacementReasonAndProblemInputLabel>
						{SelectReplacementReasonText}
					</ReplacementReasonAndProblemInputLabel>
					<Select
						error={reasonSelectionError && !replacementReason}
						data-testid="replacementReason-selectReplacementReasonDropdown"
						onChange={handleSelectedReplacementReason}
						value={replacementReason ?? ""}
						label={SelectReplacementReasonText}
						sx={{
							fontFamily: "Gibson Regular, sans-serif",
							color: "var(--text-primary)"
						}}
					>
						{REASONS_FOR_REPLACEMENT.map((reason) => (
							<MenuItem
								key={reason.id}
								data-testid={`replacementReason-selectReplacementReasonDropdown-${reason.replacementReason}`}
								value={reason.replacementReason}
								sx={{
									fontFamily: "Gibson Regular, sans-serif",
									color: "var(--text-primary)"
								}}
							>
								{reason.replacementReason}
							</MenuItem>
						))}
					</Select>
					{reasonSelectionError && !replacementReason && (
						<ErrorTextContainer>
							<ErrorOutline
								color="error"
								fontSize="small"
							/>
							<FormHelperText
								sx={{ fontFamily: "Gibson Regular, sans-serif" }}
								error={true}
							>
								{PleaseSelectReplacementReasonErrorText}
							</FormHelperText>
						</ErrorTextContainer>
					)}
				</FormControl>
			</Grid>
			{replacementReason && (
				<Grid item>
					<FormControl
						size="small"
						required
						fullWidth
					>
						<ReplacementReasonAndProblemInputLabel>
							{DescribeYourProblemText}
						</ReplacementReasonAndProblemInputLabel>
						<Select
							error={reasonSelectionError && !replacementProblem}
							data-testid="replacementReason-selectReplacementProblemDropdown"
							value={replacementProblem ?? ""}
							renderValue={(replacementProblem) => (
								<div className={styles.replacementProblemDropdownOption}>
									<span>{replacementProblem}</span>
								</div>
							)}
							onChange={handleSelectedReplacementProblem}
							label={DescribeYourProblemText}
							sx={{
								fontFamily: "Gibson Regular, sans-serif",
								color: "var(--text-primary)"
							}}
						>
							{REPLACEMENT_REASON_CODES.reduce((accumulator: ReactElement[], reason) => {
								const isPart = Boolean(item) && Boolean(part);
								if (
									reason.replacementReason === partToEdit?.partReplacementReason ||
									(!isPart && reason.replacementReason === itemToEdit?.cabinetReplacementReason)
								) {
									accumulator.push(
										<MenuItem
											key={reason.id}
											data-testid={`replacementReason-selectReplacementProblemDropdown-${reason.reasonDescription}`}
											value={reason.reasonDescription}
											sx={{
												fontFamily: "Gibson Regular, sans-serif",
												color: "var(--text-primary)"
											}}
										>
											<div className={styles.replacementProblemDropdownOption}>
												<span>{reason.reasonDescription}</span>
												<span className="body2">{reason.reasonSecondaryText}</span>
											</div>
										</MenuItem>
									);
								}
								return accumulator;
							}, [])}
						</Select>
						{reasonSelectionError && !replacementProblem && (
							<ErrorTextContainer>
								<ErrorOutline
									color="error"
									fontSize="small"
								/>
								<FormHelperText
									sx={{ fontFamily: "Gibson Regular, sans-serif" }}
									error={true}
								>
									{PleaseDescribeYourProblemErrorText}
								</FormHelperText>
							</ErrorTextContainer>
						)}
					</FormControl>
				</Grid>
			)}

			{replacementProblem && (
				<Grid
					item
					className={styles.replacementReasonUploadFiles}
				>
					<p className={styles.selectThreeImagesText}>{SelectThreeImagesText}</p>
					{fileUploads.length < maxFiles && (
						<Button
							variant="outlined"
							onClick={handleOpen}
							fullWidth
							sx={{ marginTop: ".5rem", marginLeft: 0 }}
						>
							<UploadFile sx={{ width: "1.25rem", height: "1.25rem", marginRight: ".5rem" }} />
							<p className={styles.attachFileText}>{AttachFile}</p>
						</Button>
					)}
					<p className={styles.imageUploadModalInfoText}>
						{SupportedFilesHeader} <span>{SupportedFiles}</span>
					</p>
					<p className={styles.imageUploadModalInfoText}>
						{MaxFileSizeHeader} <span>{MaxFileSize}</span> {PerImage}
					</p>
					{!!fileUploads.length && fileUploads?.length > 0 && (
						<div className={styles.imageUploadViewContainer}>
							<p>{AttachedFiles}</p>
							{fileUploads?.map((fileUrl, index) => (
								<UploadView
									key={fileUrl.id}
									index={index}
									file={fileUrl}
									onImageRemove={() => handleDeleteUploadedItem(index, itemToEdit, partToEdit)}
								/>
							))}
						</div>
					)}

					<ReplacementUploadModal
						handleClose={handleClose}
						item={itemToEdit}
						{...(partToEdit !== null ? { part: partToEdit } : {})}
						styles={styles}
						isMobile={isMobile}
						open={open}
						maxFiles={maxFiles - fileUploads.length || 0}
						fileUploads={fileUploads}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default SelectReplacementReasonAndProblemDropdowns;
