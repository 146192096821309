import {
	GridChildOneStyles,
	ImportCSVTextContainer,
	SupportedFilesTextSize,
	UnsetSecondaryCopy
} from "../NewOrders.styles";
import { Button } from "@mui/material";
import {
	CreateYourItemText,
	DownloadLatestText,
	HereText,
	ImportCSVText,
	ImportFileText,
	SupportedFilesText
} from "constants/text";
import Link from "@mui/material/Link";
import UploadIcon from "@mui/icons-material/Upload";
import { salesforceBaseURL } from "constants/endpoints";
import { useDispatch } from "react-redux";
import { NewOrderModalTracking } from "data/api/v1";
import { newOrderActions } from "features/reducers/newOrder/newOrder";

interface ImportCSVProps {
	isAddOn?: boolean;
}

const ImportCSV = ({ isAddOn = false }: ImportCSVProps) => {
	const dispatch = useDispatch();
	const handleImportFile = () => {
		dispatch(newOrderActions.setModalState(NewOrderModalTracking.CSV_UPLOAD));
	};
	return (
		<GridChildOneStyles isAddOn={isAddOn}>
			<ImportCSVTextContainer>{ImportCSVText}</ImportCSVTextContainer>
			<UnsetSecondaryCopy>{CreateYourItemText}</UnsetSecondaryCopy>
			<SupportedFilesTextSize>{SupportedFilesText}</SupportedFilesTextSize>
			<Button
				onClick={handleImportFile}
				fullWidth
				variant={isAddOn ? "outlined" : "contained"}
				startIcon={<UploadIcon sx={{ marginBottom: "3px" }} />}
			>
				{ImportFileText}
			</Button>

			<UnsetSecondaryCopy>
				{DownloadLatestText}{" "}
				<Link
					href={`${salesforceBaseURL}/s/design-software`}
					data-testid="newOrder-download-latest-catalogs"
				>
					{HereText}
				</Link>
			</UnsetSecondaryCopy>
		</GridChildOneStyles>
	);
};

export default ImportCSV;
