import styled from "@emotion/styled";
import Dialog, { dialogClasses } from "@mui/material/Dialog";
import { paperClasses } from "@mui/material/Paper";
import { dialogContentClasses, mobileStepperClasses } from "@mui/material";

export const Content = styled.div({
	display: "flex",
	flexDirection: "column",
	height: "100%"
});

export const StyledDialog = styled(Dialog)(({ theme }) => ({
	[`& > .${dialogClasses.container} > .${paperClasses.root}`]: {
		height: 420,
		minWidth: 600,
		paddingBottom: "16px",
		form: {
			display: "flex",
			flexDirection: "column",
			flex: 1
		},
		h2: {
			paddingBottom: 8
		},
		[`.${dialogContentClasses.root}`]: {
			padding: "16px 24px"
		},
		[`.${mobileStepperClasses.root}`]: {
			padding: "8px 24px"
		},
		[theme.breakpoints.down("md")]: {
			height: 500,
			minWidth: 375,
			h2: {
				alignItems: "flex-start",
				"button:first-of-type": {
					padding: "4px 0px"
				}
			}
		}
	}
}));
