import { createContext } from "react";

interface SidebarContextType {
	isSidebarOpen: boolean;
	openNav: () => void;
	closeNav: () => void;
}

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

export default SidebarContext;
